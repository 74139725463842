import React from "react";
import { MesoSupport } from '../../../../assets/Icons'

function MesoSupportpage() {
  return (
    <div>
      <>
        <img src={MesoSupport} width="100%"/>
      </>
    </div>
  );
}

export default MesoSupportpage;
