import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function DedicatedServer() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Dedizierter Server</p>
        <p>
          Der Hosting-Anbieter stellt eine Hardware-Maschine bereit, die
          komplett dem jeweiligen Kunden zur Verfügung steht. Eine Erweiterung
          bzw. Aufrüstung ist nicht so einfach möglich wie bei einer virtuellen
          Maschine.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/sharedserver" className={styles.link}>
            <li>Shared Server</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default DedicatedServer;
