import { uid } from "uid";
import {
  Incident,
  IncidentResponse,
  PendingEventCard,
  PendingResponse,
  StatusTime,
} from "../api/api";
import { translateSecondsToInGameTime } from "./GeneralUtil";

/**
 * Util function to combine the current {@link Incident}s with new incoming ones
 * @param currentIncidents - the current incidents
 * @param newIncidents  - the new incidents
 * @returns     - the combined incidents
 */
export const combineCurrentWithNewImminentIncidents = (
  currentIncidents: Incident[],
  newIncidents: Incident[]
): Incident[] => {
  const filteredIncidents = newIncidents.filter(
    (incident) =>
      currentIncidents.find(
        (currentIncident) => currentIncident.gameId === incident.gameId
      ) === undefined
  );

  return [...currentIncidents, ...filteredIncidents];
};

/**
 * Util function to combine the current {@link PendingEventCard}s with new incoming ones
 * @param currentPendingEvent  - the current pending events
 * @param newEvents  - the new pending events
 * @returns   - the combined pending events
 */
export const combineCurrentPendingEventsWithNew = (
  currentPendingEvent: PendingEventCard[],
  newEvents: PendingEventCard[]
): PendingEventCard[] => {
  const filteredPending = newEvents.filter(
    (gameEvent) =>
      currentPendingEvent.find(
        (currentEvent) => currentEvent.card.gameId === gameEvent.card.gameId
      ) === undefined
  );
  return [...currentPendingEvent, ...filteredPending];
};

/**
 * Util function to combine the current {@link StatusEffect}s with new incoming ones
 * @param responseToQueue  - the response to queue
 * @param currentTime  - the current time
 * @param currentDate  - the current date
 * @returns  - the pending response
 */
export const prepareIncidentResponseToQueue = (
  responseToQueue: IncidentResponse,
  currentTime: number,
  currentDate: Date
): PendingResponse => {
  const timeToAdd: number = responseToQueue.costTime;
  const calculatedStatusTime: StatusTime = translateSecondsToInGameTime(
    {
      inGameDate: currentDate,
      inGameTime: currentTime,
    },
    timeToAdd,
    10
  );
  return {
    id: uid(),
    response: responseToQueue,
    time: calculatedStatusTime,
  };
};
