export interface RatingComment {
  author: string;
  rating: number;
  comment: string;
}
/**
 * Helper variable for comment
 */
export let comments: RatingComment[] = [
  {
    author: "Franziska",
    rating: 1,
    comment:
      "1 von 5 Sternen\nSchlechteste Bild Upload Seite.\nEs lädt nichts ...\nAlles braucht ewig, gerade zu Hauptzeiten am langsamsten!!\nAlso wer gerne während der Schule oder Arbeitszeit hier browsen will, bitte gerne, alles andere geht nicht! ",
  },
  {
    author: "Chris",
    rating: 2,
    comment:
      "Keine Ahnung was hier fabriziert wurde, aber nach der Arbeit lädt gar nichts mehr. Alles hängt. Kann diese Seite nicht empfehlen! Außer sie bekommen dieses Problem behoben.\n2 von 5 Sternen ",
  },
  {
    author: "Tina",
    rating: 3,
    comment:
      "Es braucht ewig, meine Bilder abends nach der Schule hochzuladen...\nDas finde ich ziemlich schrecklich sollte verbessert werden.\n3 von 5 Sternen",
  },
  {
    author: "Sabine",
    rating: 4,
    comment:
      "Würde 5 Sterne geben, aber ab und zu ist es etwas langsam. \nWas mich nicht sehr stört da ich eher Leuten folge, aber wenn ich durch meine Favoriten scrolle, laden diese manchmal nicht. Deswegen nur 4 Sterne.",
  },

  {
    author: "Theo",
    rating: 5,
    comment:
      "Super Seite, 5 von 5 Sternen.\nEs macht richtig Spaß, hier seine Bilder hoch zu laden!",
  },
];
