import React from "react";
import { MesoStandort } from './../../../../assets/Icons'

function MesoStandortpage() {
  return (
    <div>
      <>
        <img src={MesoStandort} width="100%"/>
      </>
    </div>
  );
}

export default MesoStandortpage;
