import React, { useEffect } from "react";
import styles from "./styles/Browser.module.css";
import { Link } from "react-router-dom";
import { Close } from "../../assets/Icons";
import { UserContext } from "../../context/state";
import { GameState, getGameState, IWebsite } from "../../api/api";
import { useState } from "react";

type Props = {
  children: React.ReactNode;
};

/**
 *
 * @param param0 all subpages
 * @returns view navigation in browser application
 */
const BrowserLayout: React.FunctionComponent<Props> = ({ children }) => {
  let context = React.useContext(UserContext);
  const [websites, setWebsites] = useState<IWebsite[]>([]);

  //call api in external api file to get emails depending on game state
  useEffect(() => {
    if (context.isAuth) {
      (async () => {
        let gameState: GameState | undefined = await getGameState();
        if (!gameState) return;
        setWebsites(gameState.websites);
      })();
    }
  }, [context.isAuth]);


  return (
    <div className={styles.programContainer}>
      <section className={styles.programHeader}>
        <h1 className={styles.programHeading}>Browser</h1>
        <Link to="/">
          <img src={Close} alt="Closebutton" width="70px" />
        </Link>
      </section>
      <section className={styles.programBody}>
        <div className={styles.programFilter}>
          <h1>Favoriten</h1>
          {websites.map((index) => (
            <Link
              to={`/browser/${index.identifier}`}
              style={{ textDecoration: "none" }}
              key={index.identifier}
              className={styles.filterButton}
            >
              {index.name}
            </Link>
          ))}
        </div>
        <div className={styles.programMainComponent}>{children}</div>
      </section>
    </div>
  );
};

export default BrowserLayout;
