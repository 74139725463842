import React, { useRef } from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function Verschluesselung() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Verschlüsselung</p>
        <p>
          Verschlüsselung im Allgemeinen bedeutet, wenn Daten mithilfe eines
          Schlüssels in einen Geheimtext umgewandelt werden. Somit können
          verschlüsselte Daten erst wieder gelesen werden, wenn der passende
          Schlüssel vorhanden ist.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
      </div>
    </div>
  );
}

export default Verschluesselung;
