import React, { useEffect, useState } from "react";
import styles from "../styles/EmailDetail.module.css";
import { useParams, Link } from "react-router-dom";
import { Close_Black } from "./../../../assets/Icons";
import {
  GeneralClientConfig,
  IEmail,
  revealStorySolution,
} from "../../../api/api";
import { UserContext } from "../../../context/state";
import { toast, TypeOptions } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import parse from "html-react-parser";

/**
 *
 * @returns detail view of selected mail
 */
function EmailDetail() {
  const params = useParams();
  const [mail, setMail] = useState<IEmail>();
  let context = React.useContext(UserContext);

  //Call notification when the player receives a new mail using react-toastify
  const addNotification = () => {
    toast("Lösung freigeschaltet", {
      type: "info" as TypeOptions,
      autoClose: GeneralClientConfig.toastLengthInMs,
    });
  };

  //call the context, search with the filter function for the array index with the matching ID and store it in the state mail
  useEffect(() => {
    let detailedMail = context.email.filter((mail) => mail._id === params.id);
    setMail(detailedMail[0]);

    //If Mail contains the Variable revealStorysolution, then call revealStorySolution-Api and pass the variable
    if (mail?.revealStorySolution) {
      (async () => {
        let revealSolution = await revealStorySolution(
          mail.revealStorySolution
        );

        //Checks if revealsolution is undefined. If not, then a notification is triggered.
        if (revealSolution !== undefined) {
          console.info("Schaltet Lösung frei", revealSolution);
          addNotification();
          context.fetchGameState();
        }
      })();
    } else {
      console.info("Schaltet keine Lösung frei");
    }
  }, [mail, context.email, params.id]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subject}>{mail?.Header}</div>
        <div className={styles.right}>
          <div className={styles.spamButton}>
            {mail?.IsSpam ? "Kein Spam" : "Spam"}
          </div>
          <Link to="/email">
            <img src={Close_Black} alt="Close" />
          </Link>
        </div>
      </div>

      <div className={styles.infos}>
        <div className={styles.sender}>
          <div>Von: {mail?.Sender}</div>
          <div>An: {mail?.Receiver}</div>
        </div>
        <span className={styles.date}>DATUM{mail?.Date}</span>
      </div>

      <div className={styles.content}>
        <p>
          {mail?.ContentFile !== undefined ? parse(mail?.ContentFile) : null}
        </p>
      </div>
    </div>
  );
}

export default EmailDetail;
