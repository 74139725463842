import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";
import { TwoFactorAuthentification } from "../../../../assets/Icons";

function ZweiFaktorAuthentifizierung() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Zwei-Faktor-Authentifizierung</p>
        <p>
          Die Zwei-Faktor-Authentifzierung bezeichnet den Identitätsnachweis
          eines Nutzers über eine zweite unterschiedliche Komponente. Für
          sicherheitskritische Anwendungen wird diese Authentifzierung
          empfohlen.
        </p>
        <p>
          Der Grund dafür ist, dass Nutzer ihre Konten oft mit zu schwachen
          Passwörtern schützen. Durch den zweiten Identitätsnachweis wird die
          Sicherheit verbessert.
        </p>
        <p>
          Der bekannteste Nachweis erfolgt über das Smartphone. Der Nutzer
          fordert automatisch nach der Eingabe seiner Login-Daten beispielsweise eine SMS mit einem Code an, der zusätzlich angegeben werden
          muss, um sich als berechtigter Nutzer ausweisen zu können.
          <p className={styles.animationContainer}>
            <img src={TwoFactorAuthentification} width="60%" height="auto" />
          </p>
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link
            to="/browser/wiki/thirdpartyauthentifizierung"
            className={styles.link}
          >
            <li>Third-Party-Authentifizierung</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default ZweiFaktorAuthentifizierung;
