import React, { useEffect, useState } from "react";
import styles from "../styles/Messages.module.css";
import { useParams } from "react-router-dom";
import { Previous, Next } from "../../../assets/Icons";
import {
  GeneralClientConfig,
  IActiveChat,
  IMessage,
  revealStorySolution,
} from "../../../api/api";
import { UserContext } from "../../../context/state";
import { getNextMessageAnswerCombination } from "./chatFilter";
import { toast, TypeOptions } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import parse from "html-react-parser";
import { useRef } from "react";

/**
 *
 * @returns matching chat messages are displayed
 */
function Messages() {
  const { chat, fetchGameState } = React.useContext(UserContext);
  //use params to get the id of the url
  const params = useParams();
  //always defines the bottom of the chat to scroll there
  const chatEnd = useRef<HTMLInputElement>(null);
  const [slide, setSlide] = useState(0);
  const [actualChat, setActualChat] = useState<IActiveChat>();
  //sets the filterArray according to which the messages are filtered
  const [filterArray, setFilterArray] = useState<Array<string>>([]);
  const [numberFilterArray, setNumberFilterArray] = useState<Array<number>>([]);
  //all answers of player
  const [answers, setAnswers] = useState<IMessage[]>([]);
  //all messages of npc and player
  const [messages, setMessages] = useState<IMessage[]>([]);
  //selected slide of answer
  let [currentIndex, setCurrentIndex] = useState(0);
  //use the length to get the correct number of points
  const [length, setLength] = useState(answers.length);
  //is isTyping true -> trigger type animation
  const [isTyping, setIsTyping] = useState(false);

  //Call notification when the player receives a new mail using react-toastify
  const addNotification = () => {
    toast("Lösung freigeschaltet", {
      type: "info" as TypeOptions,
      autoClose: GeneralClientConfig.toastLengthInMs,
    });
  };

  const [responseTimeout, setResponseTimeout] = useState<NodeJS.Timeout>();

  /**
   * On render extract chatId from url parameters and if its a different chat, set chat content to state
   */
  useEffect(() => {
    if (!params || params.id === actualChat?.chatId) return;
    //in case chat has been switched, clear timeout and set isTyping to false to prevent showing typing animation
    if (responseTimeout) {
      clearTimeout(responseTimeout);
      setResponseTimeout(undefined);
      setIsTyping(false);
    }
    const chatId: string | undefined = params.id;
    const loadedChats: IActiveChat[] = [...chat];
    const foundChat: IActiveChat | undefined = loadedChats.find(
      (chat) => chat.chatId === chatId
    );
    if (!foundChat) return;
    setActualChat(foundChat);
    setFilterArray([]);
    setAnswers([]);
    setMessages([]);
    setNumberFilterArray([]);
    //eslint-disable-next-line
  }, [params]);

  /**
   * Handles scrolling to last message
   */
  useEffect(() => {
    if (!chatEnd.current || isTyping) return;
    chatEnd.current.scrollIntoView({ behavior: "smooth" });
  }, [messages, chatEnd, isTyping]);

  useEffect(() => {
    if (actualChat && actualChat.chatId === params.id) {
      const { resMessages, resAnswers } = getNextMessageAnswerCombination(
        actualChat,
        filterArray,
        numberFilterArray
      );

      //checks if messages array is empty. if not, wait 2 seconds until the message is displayed
      if (messages.length === 0) {
        setMessages([...messages, ...resMessages]);
      } else {
        //NPC messages. Wait until the type animation is done
        setResponseTimeout(
          setTimeout(() => setMessages([...messages, ...resMessages]), 2000)
        );
        //set isTyping to true -> trigger type animation
        setIsTyping(true);
        setTimeout(() => setIsTyping(false), 2000);
      }

      setAnswers(resAnswers);
      //If Chat contains the Variable revealStorysolution, then call revealStorySolution-Api and pass the variable
      //ToDO: Maybe refactor to use a single function for both email and messages
      resMessages.forEach((message) => {
        if (message.revealStorySolution) {
          (async () => {
            if (!message.revealStorySolution) return;
            let revealSolution = await revealStorySolution(
              message.revealStorySolution
            );
            console.info("Schaltet Lösung frei", revealSolution);
            if (!revealSolution) return;
            addNotification();
            fetchGameState();
          })();
        }
      });
    }
    //eslint-disable-next-line
  }, [actualChat, filterArray, numberFilterArray]);

  useEffect(() => {
    setLength(answers.length);
  }, [answers]);

  //call showSlides() every time when currentIndex changes
  useEffect(() => {
    showSlides(currentIndex);
  }, [currentIndex]);

  //functionality of slide dots
  function currentSlide(n: number) {
    showSlides((currentIndex = n));
  }

  //show slide depending on currentindex
  function showSlides(n: number) {
    setSlide(n);
  }

  //add classnames to change style
  const classNames = (...names: Array<string | null>): string => {
    return names.filter((x) => x != null).join(" ");
  };

  /*Logic */
  const addAnswerToChat = (message: IMessage) => {
    setMessages([...messages, message]);
    if (message.AddsFilter) {
      setFilterArray([...message.AddsFilter, ...filterArray]);
    }
    if (message.numberFilter) {
      setNumberFilterArray([...numberFilterArray, message.numberFilter]);
    }
  };

  return (
    <div className={styles.messagesAndAnswerContainer}>
      <div className={styles.messagesContainer}>
        {messages.map((index, id) => {
          return (
            <div
              id={id === messages.length - 1 ? "ref" : "not ref"}
              className={
                index.Sender === "Me" ? styles.messagePlayer : styles.messageNPC
              }
              key={id}
              ref={id === messages.length - 1 ? chatEnd : null}
            >
              {parse(index.Content)}
            </div>
          );
        })}
        {isTyping ? (
          <div className={styles.typeBox}>
            <div className={styles.typeDot} />
            <div className={styles.typeDot} />
            <div className={styles.typeDot} />
          </div>
        ) : null}
      </div>
      {answers.length > 0 && (
        <div className={styles.answersContainer}>
          Wähle eine Möglichkeit aus
          <div className={styles.row}>
            <span
              onClick={
                currentIndex > 0
                  ? () => setCurrentIndex((prevState) => prevState - 1)
                  : undefined
              }
              className={styles.previous}
            >
              <img src={Previous} width="50px" alt="previous" />
            </span>
            {answers.map((index, id) => {
              if (isTyping) {
                return (
                  <div
                    key={id}
                    id={`answer_${id}`}
                    className={classNames(
                      styles.answerDisabled,
                      slide === id ? null : styles.answerHidden
                    )}
                  >
                    <em>Warte auf eine Antwort...</em>
                  </div>
                );
              } else {
                return (
                  <div
                    key={id}
                    id={`answer_${id}`}
                    className={classNames(
                      styles.answerPlayer,
                      slide === id ? null : styles.answerHidden
                    )}
                    onClick={() => addAnswerToChat(index)}
                  >
                    <p>{parse(index.Content)}</p>
                  </div>
                );
              }
            })}
            <span
              onClick={
                currentIndex < length - 1
                  ? () => setCurrentIndex((prevState) => prevState + 1)
                  : undefined
              }
              className={styles.next}
            >
              <img src={Next} width="50px" alt="next" />
            </span>
          </div>
          <div className={styles.dotContainer}>
            {answers.map((index, id) => (
              <span
                key={id}
                onClick={() => currentSlide(id)}
                className={classNames(
                  styles.dot,
                  slide === id ? styles.dotActive : null
                )}
              ></span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Messages;
