import React from "react";
import styles from "./styles/MBI.module.css";

function MBIServer() {
  return (
    <div className={styles.container}>
      <div className={styles.places}>
        <div>
          <strong className={styles.heading}>Support Infos</strong>
          <br /> Support wird bei uns groß geschrieben
          <ul>
            <li>24/7 telefonisch erreichbar</li>
            <li>24/7 Vor-Ort-Support</li>
            <li>Diverses Team an Experten</li>
            <li>Persönliche Ansprechpartner</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MBIServer;
