import React, { useCallback } from "react";
import styles from "./styles/DecisionDetailView.module.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/state";
import { selectSolution } from "../api/api";

interface Props {
  solution: {
    id: string;
    description: string;
    longDescription: string;
    solutionBonusFortification?: number;
    solutionCostMoney: number;
    solutionCostTime: number;
    solutionTrait?: string;
    successor?: Array<any>;
    title: string;
  }[];
}

const DecisionDetailView: React.FC<Props> = ({ solution }) => {
  const context = React.useContext(UserContext);
  const navigate = useNavigate();


  //navigate to desktop route
  const handleOnClick = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );

  const callSelectSolution = () => {
    if (context.isAuth) {
      (async () => {
        await selectSolution(solution[0].id);
        context.fetchGameState();
      })();

      //close dashboard and decisiondetailview
      context.openDecisionDetail();
      context.openDashboard();

      //redirect to desktop
      handleOnClick();
    }
  };

  return (
    <div>
      {context.decisionDetailOpened ? (
        <div className={styles.container}>
          <div className={styles.modalcontent}>
            <div className={styles.modalheader}>
              <span className={styles.heading}>{solution[0].title}</span>
            </div>
            <div className={styles.content}>
              {solution[0].longDescription}
              <p>
                <strong>Kosten:</strong> {solution[0].solutionCostMoney}€
              </p>
            </div>
            <hr />
            <div className={styles.footer}>
              <p className={styles.question}>
                Entscheidung mit dieser Lösung abschließen?
              </p>
              <div className={styles.buttonContainer}>
                <div
                  className={styles.button}
                  onClick={() => context.openDecisionDetail()}
                >
                  Zurück
                </div>
                <div
                  className={styles.button}
                  onClick={() => callSelectSolution()}
                >
                  Weiter
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DecisionDetailView;
