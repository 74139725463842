import React, { useState, useEffect } from "react";
import { UserContext } from "../context/state";

import styles from "./styles/IncidentsModal.module.css";
import {
  GameEvent,
  sendRestartGame,
  StatEffectType,
  StatusEffect,
} from "../api/api";
import { useNavigate } from "react-router";

export default function Modals(): JSX.Element {
  const { gameOver, gameState, setGameOver, fetchGameState } =
    React.useContext(UserContext);
  const navigate = useNavigate();
  const [defeatEffect, setDefeatEffect] =
    useState<StatusEffect<StatEffectType.DEFEAT>>();

  /**
   * Checks for imminent Game Over
   */
  useEffect(() => {
    if (
      !gameState.phaseTwoState.pendingEventCards ||
      gameState.phaseTwoState.pendingEventCards.length <= 0
    )
      return;
    const foundDefeat = gameState.phaseTwoState.pendingEventCards.find(
      (pendingEvent) => {
        if ("effects" in pendingEvent.card) {
          return pendingEvent.card.effects.find(
            (effect) => effect.type === StatEffectType.DEFEAT
          );
        }
        return false;
      }
    );
    if (foundDefeat) {
      setDefeatEffect((foundDefeat.card as GameEvent).effects[0]);
    }
  }, [gameState.phaseTwoState.pendingEventCards]);

  const sendGameOverToServer = async () => {
    await sendRestartGame();
    setGameOver(false);
    await fetchGameState();
    navigate("/");
  };

  return (
    <div className={styles.container}>
      {gameOver ? (
        <div className={styles.modal}>
          <div className={styles.modalcontent}>
            <div className={styles.content}>
              <div className={styles.animation}></div>
              <div className={styles.modalheader}>
                <h3>{defeatEffect?.title}</h3>
              </div>
              <div className={styles.center}>
                <div className={styles.line} />
              </div>
              {/*Show Description of incident*/}
              <div className={styles.description}>
                {defeatEffect?.firstParameter}
              </div>
              {/*Show options*/}
              <div className={styles.questionContainer}>
                <div className={styles.question}>Was tun Sie?</div>
              </div>

              <div className={styles.modalfooter}>
                <div className={styles.buttonContainer}>
                  <div
                    onClick={() => sendGameOverToServer()}
                    className={styles.button}
                  >
                    Neu starten
                    <div className={styles.option}>
                      <span className={styles.tooltiptext}>
                        Starte das Spiel bei Phase eins
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
