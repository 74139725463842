import React, { useMemo } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import styles from "../styles/Ressource.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Filler,
} from "chart.js";

import { ReactComponent as WrenchIcon } from "../../../assets/icons/Monitoring/wrench.svg";
import {
  createServerLineData,
  createStoragePieData,
  getServerLineChartOptions,
  getStoragePieChartOptions,
} from "../../../util/Monitoring/Monitoring.types";
import { TimeContext, UserContext } from "../../../context/state";
import { ServerUpgrade } from "../../../api/api";
import {
  formatDate,
  translateSecondsToInGameTime,
} from "../../../util/GeneralUtil";
import { uid } from "uid";

interface UpgradeButtonProps {
  upgradeText: string;
  onClick?: () => void;
  upgradeCosts: number;
  disabled?: boolean;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  upgradeText,
  onClick = () => {},
  upgradeCosts,
  disabled = false,
}) => {
  return (
    <div
      className={styles.upgradeWrapper}
      onClick={disabled ? () => {} : () => onClick()}
      style={
        disabled
          ? { cursor: "not-allowed", opacity: 0.5, transform: "scale(1)" }
          : {}
      }
    >
      <div className={styles.upgradeIcon}>
        <WrenchIcon />
      </div>
      <div className={styles.upgradeText}>
        <p>{upgradeText}</p>
        <p>{`(${upgradeCosts}€)`}</p>
      </div>
    </div>
  );
};
const ServerRessourcenPage: React.FC = () => {
  const { gameState, setLocalGameState, gameStateRef } =
    React.useContext(UserContext);

  const { currentTime } = React.useContext(TimeContext);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
  );

  /**
   * Helper function to process the selected upgrade for server
   * @param type - the selected upgrade
   */
  const processServerUpgrade = (type: ServerUpgrade): void => {
    let duration = 0;
    let budget = gameStateRef.current!.budget;

    switch (type) {
      case ServerUpgrade.RAM_SMALL:
        duration = gameState.gameConfigs.phaseTwo.ramConfig.upgradeDuration;
        budget -= gameState.gameConfigs.phaseTwo.ramConfig.upgradeCostSmall;
        break;
      case ServerUpgrade.RAM_BIG:
        duration = gameState.gameConfigs.phaseTwo.ramConfig.upgradeDuration;
        budget -= gameState.gameConfigs.phaseTwo.ramConfig.upgradeCostBig;
        break;

      case ServerUpgrade.STORAGE_SMALL:
        duration = gameState.gameConfigs.phaseTwo.storageConfig.upgradeDuration;
        budget -= gameState.gameConfigs.phaseTwo.storageConfig.upgradeCostSmall;
        break;
      case ServerUpgrade.STORAGE_BIG:
        duration = gameState.gameConfigs.phaseTwo.storageConfig.upgradeDuration;
        budget -= gameState.gameConfigs.phaseTwo.storageConfig.upgradeCostBig;
        break;
    }

    setLocalGameState({
      ...gameStateRef.current!,
      budget: budget,
      phaseTwoState: {
        ...gameStateRef.current!.phaseTwoState,
        pendingUpgrades: [
          ...gameStateRef.current!.phaseTwoState.pendingUpgrades,
          {
            id: uid(),
            time: {
              ...translateSecondsToInGameTime(
                {
                  inGameDate: gameState.currentDate,
                  inGameTime: currentTime,
                },
                duration,
                10
              ),
            },
            upgrade: type,
          },
        ],
      },
    });
  };

  //memoized server line data
  const preCreateServerLineData = useMemo(() => {
    return createServerLineData(
      gameState.phaseTwoState.currentServer.ramLoadHistory.flatMap((loadDate) =>
        formatDate(new Date(loadDate.date))
      ),
      gameState.phaseTwoState.currentServer.ramLoadHistory.flatMap(
        (loadDate) => loadDate.load
      )
    );
  }, [gameState.phaseTwoState.currentServer.ramLoadHistory]);

  //memoized server line options
  const preCreateServerLineOptions = useMemo(() => {
    return getServerLineChartOptions(
      gameState.phaseTwoState.currentServer.ramLoadHistory.flatMap((loadDate) =>
        formatDate(new Date(loadDate.date))
      )
    );
  }, [gameState.phaseTwoState.currentServer.ramLoadHistory]);

  //memoized storage pie data
  const preCreateStoragePieData = useMemo(() => {
    return createStoragePieData(
      gameState.phaseTwoState.currentServer.maxStorage,
      gameState.phaseTwoState.currentServer.storageUsed
    );
  }, [gameState.phaseTwoState.currentServer]);

  //Memoized pie options
  const preCreateStoragePieOptions = useMemo(() => {
    return getStoragePieChartOptions();
  }, []);

  return (
    <div className={styles.ressourceWrapper}>
      <div className={styles.ressourceHeaderContainer}>
        <h3 className={styles.ressourceHeader}>Hardware</h3>
      </div>
      <div className={styles.ressourceChartContainer}>
        <div className={styles.chartCard}>
          <div className={styles.chartCardHeaderContainer}>
            <h4 className={styles.chartCardHeader}>Serverauslastung</h4>
          </div>
          <div className={styles.chartContainer}>
            <Line
              className={styles.chartLine}
              options={preCreateServerLineOptions}
              data={preCreateServerLineData}
            />
          </div>
        </div>
        <div className={styles.chartCard}>
          <div className={styles.chartCardHeaderContainer}>
            <h4 className={styles.chartCardHeader}>Speicherbelegung</h4>
          </div>
          <div className={styles.chartContainer}>
            <Doughnut
              className={styles.chartPie}
              data={preCreateStoragePieData}
              options={preCreateStoragePieOptions}
            />
          </div>
          <div className={styles.storageLegendContainer}>
            <p className={styles.storageOccupied}>
              Belegt:
              {gameState.phaseTwoState.currentServer.storageUsed}GB
            </p>
            <p className={styles.storageFree}>
              Frei: {gameState.phaseTwoState.currentServer.maxStorage}
              GB
            </p>
          </div>
        </div>
      </div>
      <div className={styles.serverUpdatesContainer}>
        <div className={styles.upgradeHeaderContainer}>
          <h4 className={styles.upgradeHeader}>Upgrade</h4>
        </div>
        <div className={styles.upgradeButtonContainer}>
          <UpgradeButton
            upgradeText="Leistungsupgrade x 2"
            upgradeCosts={
              gameState.gameConfigs.phaseTwo.ramConfig.upgradeCostSmall
            }
            onClick={() => processServerUpgrade(ServerUpgrade.RAM_SMALL)}
            disabled={
              gameState.budget <
                gameState.gameConfigs.phaseTwo.ramConfig.upgradeCostSmall ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.RAM_SMALL
              ) ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.RAM_BIG
              )
            }
          />
          <UpgradeButton
            upgradeText="Leistungsupgrade x 4"
            upgradeCosts={
              gameState.gameConfigs.phaseTwo.ramConfig.upgradeCostBig
            }
            onClick={() => processServerUpgrade(ServerUpgrade.RAM_BIG)}
            disabled={
              gameState.budget <
                gameState.gameConfigs.phaseTwo.ramConfig.upgradeCostBig ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.RAM_SMALL
              ) ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.RAM_BIG
              )
            }
          />
          <UpgradeButton
            upgradeText="Speicherupgrade 100GB"
            upgradeCosts={
              gameState.gameConfigs.phaseTwo.storageConfig.upgradeCostSmall
            }
            onClick={() => processServerUpgrade(ServerUpgrade.STORAGE_SMALL)}
            disabled={
              gameState.budget <
                gameState.gameConfigs.phaseTwo.storageConfig.upgradeCostSmall ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.STORAGE_BIG
              ) ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.STORAGE_SMALL
              )
            }
          />
          <UpgradeButton
            upgradeText="Speicherupgrade 1000GB"
            upgradeCosts={
              gameState.gameConfigs.phaseTwo.storageConfig.upgradeCostBig
            }
            onClick={() => processServerUpgrade(ServerUpgrade.STORAGE_BIG)}
            disabled={
              gameState.budget <
                gameState.gameConfigs.phaseTwo.storageConfig.upgradeCostBig ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.STORAGE_BIG
              ) ||
              !!gameState.phaseTwoState.pendingUpgrades.find(
                (upgrade) => upgrade.upgrade === ServerUpgrade.STORAGE_SMALL
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ServerRessourcenPage;
