import React from "react";
import styles from "./styles/Credits.module.css";
import { Close_Black, HdM, ICS } from "../assets/Icons";
import { UserContext } from "../context/state";

/**
 *
 * @returns list of developer, designer, project manager, director
 */
function Credits() {
  const context = React.useContext(UserContext);

  return (
    <div>
      {context.creditsOpened ? (
        <div className={styles.container}>
          <div className={styles.modalcontent}>
            <div className={styles.close}>
              <img
                src={Close_Black}
                alt="Closebutton"
                width="40px"
                onClick={() => context.openCredits()}
              />
            </div>
            <div className={styles.modalheader}>
              <h1 className={styles.heading}>Credits</h1>
            </div>
            <div className={styles.content}>
              <h2>Programming</h2>
              <div>Emanuel Leube</div>
              <div>Johanna Kilgus</div>
              <div>Rafael Can Savastürk</div>
              <div>Felix Messner</div>
              <div>Martina Harms</div>
              <div>Katharina Lindner</div>
              <div>Matthias Koch</div>
              <h2>Graphics</h2>
              <div>Martina Harms</div>
              <div>Carolin Vollmar</div>
              <h2>Testing</h2>
              <div>Julia Grimm</div>
              <div>Martina Harms</div>
              <h2>Management</h2>
              <div>Carolin Zangl</div>
              <div>Fearn Cabell Daniel</div>
              <h2>Direktor</h2>
              <div>Prof. Dr. Dirk Heuzeroth</div>
              <div>Prof. Dr. Roland Schmitz</div>
              <h2>Co Direktor</h2>
              <div>Dr. Andreas Stiegler</div>
              <h2>Institute</h2>
              <img src={ICS} alt="Institute for Cyber Security" height="80px" />
              <div>Institute for Cyber Security</div>
              <img src={HdM} alt="Hochschule der Medien" height="80px" />
              <div>Hochschule der Medien</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Credits;
