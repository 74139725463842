import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function PrivateCloud() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Private Cloud</p>
        <p>
          Der Cloud-Anbieter stellt ein Netzwerk zur Verfügung, das nicht direkt
          über das Internet erreicht werden kann. Der gespeicherte Platz ist nur
          für eine Person reserviert. Der Speicher kann jederzeit problemlos
          erweitert werden.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/publiccloud" className={styles.link}>
            <li>Public Cloud</li>
          </Link>
          <Link to="/browser/wiki/hybridcloud" className={styles.link}>
            <li>Hybrid Cloud</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default PrivateCloud;
