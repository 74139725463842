import React from "react";
import styles from "./styles/Menu.module.css";
import { Close_Black, CreditsIcon, Entscheidung } from "../assets/Icons";
import { Achievements, Speichern } from "../assets/Icons";
import { UserContext } from "../context/state";
import { resetGame } from "../api/api";
import { useNavigate } from "react-router-dom";

function Menu() {
  const context = React.useContext(UserContext);
  let navigate = useNavigate();

  //resets all user data
  const endGame = () => {
    (async () => {
      let userData = await resetGame();
      //set isAuth to false
      context.setIsAuth(false);
      navigate("/");
    })();
  };

  return (
    <div>
      {context.menuOpened ? (
        <div className={styles.container}>
          <div className={styles.modalcontent}>
            <div className={styles.close}>
              <img
                src={Close_Black}
                alt="Schließen"
                width="40px"
                onClick={() => context.openMenu()}
              />
            </div>
            <div className={styles.modalheader}>
              <h1 className={styles.heading}>Spielmenü</h1>
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.containerLeft}>
                <button className={styles.button} disabled>
                  <img src={Speichern} width="8%" alt="Speichern" />
                  &nbsp;Speichern
                </button>
                <button className={styles.button} disabled>
                  <img src={Entscheidung} width="8%" alt="entscheidungen" />
                  &nbsp;Entscheidungen
                </button>
                <button
                  className={styles.button}
                  onClick={() => context.openAchievement()}
                >
                  <img src={Achievements} width="8%" alt="erfolge" />
                  &nbsp;Erfolge
                </button>
                <button
                  className={styles.button}
                  onClick={() => context.openCredits()}
                >
                  <img src={CreditsIcon} width="8%" alt="Credits" />
                  &nbsp;Credits
                </button>
              </div>
              <div className={styles.containerRight}>
                <button
                  className={styles.button}
                  onClick={() => context.openMenu()}
                >
                  Fortsetzen
                </button>
                <button className={styles.button} disabled>
                  Erkenntnisse
                </button>
                <button className={styles.button} disabled>
                  Sound: An
                </button>
                <button className={styles.button} onClick={() => endGame()}>
                  Spiel beenden
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Menu;
