import React, { RefObject } from "react";
import {
  createEmptyGameState,
  GameState,
  IActiveChat,
  IEmail,
  ImminentIncident,
} from "../api/api";

export type TimeState = {
  currentTime: number;
  setCurrentTime: (time: number) => void;
  timeRef: RefObject<number>;
  timeMod: number;
  setTimeMOd: (time: number) => void;
  timeModeRef: RefObject<number>;
};

//set all required states. These are to be used in subordinate components.
export type State = {
  dashboardOpened: boolean;
  openDashboard: () => void;
  incidentsModalOpened: boolean;
  openIncidentsModal: (opened: boolean) => void;
  menuOpened: boolean;
  openMenu: () => void;
  achievementsOpened: boolean;
  openTutorial: () => void;
  tutorialOpened: boolean;
  openAchievement: () => void;
  creditsOpened: boolean;
  openCredits: () => void;
  decisionDetailOpened: boolean;
  openDecisionDetail: () => void;
  email: IEmail[];
  setEmail: (p: IEmail[]) => void;
  isAuth: boolean;
  setIsAuth: (p: boolean) => void;
  chat: IActiveChat[];
  setChat: (p: IActiveChat[]) => void;
  gameState: GameState;
  fetchGameState(): Promise<void>;
  setLocalGameState(gameState: GameState): void;
  gameStateRef: RefObject<GameState>;
  gameOver: boolean;
  setGameOver: (p: boolean) => void;
  victory: boolean;
  setVictory: (p: boolean) => void;
  selectedIncident: ImminentIncident | undefined;
  setSelectedIncident: (p: ImminentIncident | undefined) => void;
};

//Create Context
export const UserContext = React.createContext<State>({
  dashboardOpened: false,
  openDashboard: () => {},
  incidentsModalOpened: false,
  openIncidentsModal: () => {},
  menuOpened: false,
  openMenu: () => {},
  tutorialOpened: false,
  openTutorial: () => {},
  achievementsOpened: false,
  openAchievement: () => {},
  creditsOpened: false,
  openCredits: () => {},
  decisionDetailOpened: false,
  openDecisionDetail: () => {},
  email: [],
  setEmail: () => {},
  chat: [],
  setChat: () => {},
  isAuth: false,
  setIsAuth: () => {},
  gameState: createEmptyGameState(),
  fetchGameState: () => Promise.resolve(),
  setLocalGameState: () => {},
  gameStateRef: React.createRef<GameState>(),
  gameOver: false,
  setGameOver: () => {},
  victory: false,
  setVictory: () => {},
  selectedIncident: undefined,
  setSelectedIncident: () => {},
});

export const TimeContext = React.createContext<TimeState>({
  currentTime: 0,
  setCurrentTime: () => {},
  timeRef: React.createRef<number>(),
  timeMod: 1,
  setTimeMOd: () => {},
  timeModeRef: React.createRef<number>(),
});
