import React from "react";
import styles from "../styles/Startpage.module.css";

function StartPage() {
  return (
    <div>
      <div className={styles.heading}>Willkommen auf der Startseite</div>

      <div className={styles.content}>
        Auf der linken Seite befinden sich alle relevanten Seiten.
      </div>
      <div className={styles.content}>
        Unter Wiki befinden sich weitere Seiten, die wie ein Lexikon
        funktionieren.
      </div>
      <div className={styles.content}>
        Im Laufe des Spiels werden weitere Webseiten freigeschaltet, das Spiel
        weist Sie darauf hin.
      </div>
    </div>
  );
}

export default StartPage;
