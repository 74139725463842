import React from "react";
import styles from "../styles/Anleitung.module.css";
import {
  ErweiterteTaskbar,
  TaskArea,
  Monitoring,
  Taskbar,
} from "../../../assets/Icons";
const AnleitungZwei: React.FC = () => {
  return (
    <div id={styles.background}>
      <div id="Anleitung">Anleitung Phase2.pdf</div>

      <div id={styles.WillkommenText}>
        <b>Willkommen in der Betriebsphase.</b>
        <br />
        Hier überwachen Sie nun den Server. Alles sollte reibungslos laufen, da
        jeden Tag neue Nutzer Ihren Dienst benutzen. Jeder Nutzer bringt ein
        konstantes Einkommen mit sich.
        <br />
        Um ihren Server auf dem neusten Stand zu halten, gibt es das
        Monitoring-Programm in der Taskleiste.
        <br />
        Für die Nutzerzufriedenheit ist nun die PictureIT-Website
        betriebsfertig.
        <br />
        Zudem treten ab und zu Ereignisse ein.
      </div>

      <div id={styles.Aufbau}> Aufbau des Spiels Phase 2 </div>

      <div id={styles.Taskbar}> Die Taskleiste: </div>

      <div id={styles.TaskbarBild}>
        <img alt="Taskbar" src={Taskbar} />
      </div>

      <div id={styles.TaskbarText}>
        Die Taskleiste in Phase 2 hat sich leicht verändert. <br />
        Das neue Programm Monitoring kann benutzt werden. Dieses ist rechts
        neben dem E-Mail-Icon zu finden. <br />
        Zudem hat sich die erweiterte Taskleiste verändert.
      </div>

      <div id={styles.Monitoring}> Monitoring: </div>

      <div id={styles.MonitoringBild}>
        <img alt="Monitoring" src={Monitoring} />
      </div>

      <div id={styles.MonitoringText}>
        In diesem Programm sehen Sie in der Übersicht alle relevanten Daten.{" "}
        <br />
        Hier ist es wichtig, die Server-Auslastung und die Speicherbelegung im
        Auge zu behalten. <br />
        Wenn der Server zu stark beansprucht ist, bekommen Sie eine E-Mail.
      </div>

      <div id={styles.Dashboard}> Das Dashboard: </div>

      <div id={styles.TaskAreaBild}>
        <img alt="Taskarea" src={TaskArea} />
      </div>

      <div id={styles.DashboardText}>
        Das Aufgaben-Board wurde nun zu einem Dashboard verändert. <br />
        Der Grund dafür ist einfach, in Phase 2 gibt es nun keine Aufgaben mehr.
        Hier werden stattdessen die aktuellen Ereignisse angezeigt, die in Phase
        2 auftauchen.
        <br />
        Zum Beispiel, wenn der Speicher des Servers erweitert wird oder ein
        Ereignis zurückgestellt wird, wird dies hier gezeigt. <br />
        Nur die Tage, die verstreichen, werden permanent angezeigt.
      </div>

      <div id={styles.ErweiterteTaskbar}> Erweiterte Taskleiste: </div>

      <div id={styles.ErweiterteTaskbarBild}>
        <img alt="Erweiterte Taskbar" src={ErweiterteTaskbar} />
      </div>

      <div id={styles.ErweiterteTaskbarText}>
        Die Erweiterung zeigt nun das Vermögen. Das Einkommen pro Tag wird
        darunter angezeigt. <br />
        Daneben befindet sich der Zeitablauf eines Tages. Mit den Knöpfen{" "}
        <i>Start</i> und <i>Pause</i> kann die Zeit gestartet bzw. angehalten
        werden. Somit kann eine Entscheidung in Ruhe überdacht werden.
        <br />
        Der Knopf neben dem Start/Pause-Knopf ist der Beschleunigung-Knopf. Wenn
        dieser aktiviert wird, vergeht die Zeit doppelt so schnell wie normal.
        <br />
        Ganz rechts in der erweiterten Taskleiste ist das Nutzer-Icon. Dieses
        zeigt an, wie viele Nutzer gerade Ihren Server in Anspruch nehmen.
      </div>
    </div>
  );
};

export default AnleitungZwei;
