import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./context/UserContextProvider";
import { TimeContextProvider } from "./context/TimeContextProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  //The context is sent to all child components with the help of the provider.
  <UserContextProvider>
    <TimeContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </TimeContextProvider>
  </UserContextProvider>
);
