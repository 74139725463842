import React from "react";
import styles from "./styles/MBI.module.css";

function MBIImpressum() {
  return (
    <div className={styles.container}>
      <div className={styles.places}>
        <div>
          <strong className={styles.heading}>Server Infos</strong>
        </div>
        <br />
        <strong>MBI Cloud Service GmbH</strong>
        <br />
        Ernst-Richter-Straße 45
        <br />
        10345 Berlin
        <br />
        <br />
        <strong>Geschäftsführer</strong>
        <br />
        Prof. Dr. Mark Winter
        <br />
        <br />
        <strong>Kontakt</strong>
        <br />
        kontakt@mbi-cloud.com
      </div>
    </div>
  );
}

export default MBIImpressum;
