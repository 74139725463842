import React from "react";
import styles from "./styles/Achievements.module.css";
import { Close_Black } from "../assets/Icons";
import { UserContext } from "../context/state";
import { useState } from "react";

/** list of all achievements */
function Achievements() {
  const isOpen = React.useContext(UserContext);

  //dummy data for achievements. the data is not yet implemented in the backend
  const [achievements] = useState([
    {
      name: "Der Anfang",
    },
    {
      name: "Cloud it up",
    },
    {
      name: "Host it up",
    },
    {
      name: "Sicherheit",
    },
    {
      name: "Wissbegierig",
    },
    {
      name: "Test",
    },
    {
      name: "Test2",
    },
  ]);

  return (
    <div>
      {isOpen.achievementsOpened ? (
        <div className={styles.container}>
          <div className={styles.modalcontent}>
            <div className={styles.close}>
              <img
                src={Close_Black}
                alt="Closebutton"
                width="40px"
                onClick={() => isOpen.openAchievement()}
              />
            </div>
            <div className={styles.modalheader}>
              <h1 className={styles.heading}>Erfolge</h1>
            </div>
            <div className={styles.buttonContainer}>
              {achievements.map((index) => (
                <div className={styles.erfolg}>{index.name}</div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Achievements;
