import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function Pentest() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Pentest</p>
        <p>
          Pentest (Langform: Penetrationstest) ist der fachsprachliche Ausdruck
          für einen umfassenden Sicherheitstest einzelner Rechner oder Netzwerke
          jeglicher Größe.
        </p>
        <p>
          In der IT-Sicherheit versteht man darunter die Prüfung der Sicherheit
          möglichst aller Systembestandteile und Anwendungen eines Netzwerks
          oder Softwaresystems.
        </p>
        <p>
          Die Methoden und Mittel, die hierbei benutzt werden, um unautorisiert
          in das System einzudringen, sind die eines Angreifers oder Hackers.
        </p>
        <p>
          Somit werden die Schwachstellen von Systemen ermittelt und können
          behoben werden, bevor Dritte diese missbrauchen.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/sicherheitscheck" className={styles.link}>
            <li>Sicherheitscheck Allgemein</li>
          </Link>
          <Link to="/browser/wiki/firewall" className={styles.link}>
            <li>Firewall Allgemein</li>
          </Link>
          <Link to="/browser/wiki/isoiec" className={styles.link}>
            <li>ISO/IEC 27001</li>
          </Link>
          <Link to="/browser/wiki/digitaleforensik" className={styles.link}>
            <li>Digitale Forensik</li>
          </Link>
          <Link to="/browser/wiki/abusecase" className={styles.link}>
            <li>Abuse Case</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Pentest;
