import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function DigitaleForensik() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>DigitaleForensik</p>
        <p>
          Digitale Forensik bedeutet die Wiederherstellung und Untersuchung von
          Hard- und Software in digitalen Geräten. Sobald Dritte oder Hacker
          einen Missbrauch (Abuse Case) von Daten auslösen, zum Beispiel
          Nutzerdaten werden entwedent, kann dieser untersucht werden. Der
          Eindringling hinterlässt Spuren, denen gefolgt werden kann.
          </p>
          <p>
          In der digitalen Forensik wird die Hard- und Software von speziell
          geschulten Firmen untersucht. Hierbei wird alles nach Hinweisen
          durchsucht und aufbereitet. Wenn eine Spur entdeckt wurde, wird dieser
          nachgegangen. Somit können die Schäden, die entstanden sind oder noch
          entstehen werden, besser geschätzt und eingeordnet werden.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/sicherheitscheck" className={styles.link}>
            <li>Sicherheitscheck Allgemein</li>
          </Link>
          <Link to="/browser/wiki/firewall" className={styles.link}>
            <li>Firewall Allgemein</li>
          </Link>
          <Link to="/browser/wiki/isoiec" className={styles.link}>
            <li>ISO/IEC 27001</li>
          </Link>
          <Link to="/browser/wiki/pentest" className={styles.link}>
            <li>Pentest</li>
          </Link>
          <Link to="/browser/wiki/abusecase" className={styles.link}>
            <li>Abuse Case</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default DigitaleForensik;
