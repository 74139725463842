import { TimeContext } from "./state";
import { useState } from "react";
import {
  createEmptyGameState,
  GameState,
  getGameState,
  IActiveChat,
  IEmail,
} from "../api/api";
import useStateRef from "react-usestateref";

interface TimeContextProviderProps {
  children: React.ReactNode;
}

//The context is sent to all child components with the help of the provider.
export const TimeContextProvider = (props: TimeContextProviderProps) => {
  const [currentTime, setCurrentTime, timeRef] = useStateRef<number>(0);
  const [timeMod, setTimeMOd, timeModeRef] = useStateRef<number>(1);



  return (
    <TimeContext.Provider
      value={{
        currentTime: currentTime,
        setCurrentTime: (time: number) => setCurrentTime(time),
        timeRef: timeRef,
        timeMod: timeMod,
        setTimeMOd: (time: number) => setTimeMOd(time),
        timeModeRef: timeModeRef,
      }}
    >
      {props.children}
    </TimeContext.Provider>
  );
};
