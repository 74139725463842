import React, { useMemo } from "react";
import { MonitoringLog } from "../../../api/api";
import { UserContext } from "../../../context/state";
import { formatDate } from "../../../util/GeneralUtil";
import styles from "../styles/Logs.module.css";

const LogsPage: React.FC = () => {
  const { gameState } = React.useContext(UserContext);
  const logs: MonitoringLog[] = useMemo(
    () => gameState.phaseTwoState.currentServer.logHistory,
    [gameState]
  );
  return (
    <div className={styles.logsWrapper}>
      <div className={styles.logsHeaderContainer}>
        <h3 className={styles.logsHeader}>Logs</h3>
      </div>

      <div className={styles.logsCardContainer}>
        <div className={styles.logsCard}>
          <div className={styles.logsCardHeaderContainer}>
            <h4 className={styles.logsCardHeader}>Logs</h4>
          </div>
          <div className={styles.logsTableContainer}>
            {logs.length > 0 ? (
              logs
                .sort((logA, logB) => {
                  const dateA = new Date(logA.date);
                  const dateB = new Date(logB.date);
                  if (dateA.getTime() < dateB.getTime()) return 1;
                  if (dateA.getTime() > dateB.getTime()) return -1;
                  return 0;
                })
                .map((log) => (
                  <div className={styles.logsMessageContainer}>
                    <p>{`${formatDate(new Date(log.date))}:`}</p>
                    <p className={styles.logMessage}>{log.message}</p>
                  </div>
                ))
            ) : (
              <p>Keine Logs vorhanden</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogsPage;
