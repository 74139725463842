import React, { useState } from "react";
import styles from "./styles/MBIStartpage.module.css";
import { Blob } from "../../../../assets/Icons";
import { AiFillStar } from "react-icons/ai";

function MBIStartpage() {
  const [clients] = useState([
    {
      logo: "https://s20.directupload.net/images/220328/9o4uhjar.png",
    },
    {
      logo: "https://s20.directupload.net/images/220328/pk57vjy8.png",
    },
    {
      logo: "https://s20.directupload.net/images/220328/6a5t6ing.png",
    },
    {
      logo: "https://s20.directupload.net/images/220328/afvp6v7v.png",
    },
    {
      logo: "https://s20.directupload.net/images/220328/8h3noolb.png",
    },
    {
      logo: "https://s20.directupload.net/images/220328/2vwrnh98.png",
    },
  ]);

  return (
    <div className={styles.startpage}>
      <div className={styles.pages}>
        <div className={styles.container}>
          <div className={styles.saas}>Serious as a Service</div>
          <div className={styles.heading}>Ihr Partner in Sachen Cloud!</div>
          <div className={styles.subtext}>
            Sicher, kompetent, zuverlässig und garantiert - das ist MBI!
          </div>
          <div className={styles.button}>
            <div className={styles.contact}>Loslegen</div>
          </div>
        </div>
        <div className={styles.container}>
          <img src={Blob} alt="SVG-Grafik" className={styles.blob} />
        </div>
      </div>
      {/*Partner */}
      <div className={styles.partner}>
        <div className={styles.text}>Ein Auszug unserer Kunden</div>
        <div className={styles.logos}>
          {clients.map((index) => (
            <div className={styles.client}>
              <img src={index.logo} height="100" alt="Firmenlogo" />
            </div>
          ))}
        </div>
      </div>
      {/*Rating */}
      <div className={styles.ratingContainer}>
        <div className={styles.logo}>
          <img
            src="https://s20.directupload.net/images/220328/qi7znxxy.png"
            alt="Websieger"
          />
        </div>
        <div className={styles.ratingHeader}>Bewertung</div>
        <div className={styles.reachedpoints}>
          4,75<span className={styles.points}> / 5</span>
        </div>

        <div className={styles.ratingheading}>Ehrliche Bewertungen</div>
        <div className={styles.rating}>
          <div className={styles.feedback}>
            <div className={styles.name}>Peter K.</div>
            <div className={styles.stars}>
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
            </div>
            <div className={styles.text}>
              "Berät die Kunden sehr ausführlich und nimmt das Thema Datenschutz
              sehr ernst. Die Daten werden alle in der EU gehostet"
            </div>
          </div>
          <div className={styles.feedback}>
            <div className={styles.name}>Léon E. S.</div>
            <div className={styles.stars}>
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
            </div>
            <div className={styles.text}>
              "Sehr zuverlässig und kompetent. MBI nimmt sich Zeit für
              Beratungsgespräche und der Support reagiert sehr schnell und
              antwortet meist in wenigen Stunden, alternativ steht auch der
              Kundenservice im Chat zur Verfügung. Sehr empfehlenswert!"
            </div>
          </div>
          <div className={styles.feedback}>
            <div className={styles.name}>Martina B.</div>
            <div className={styles.stars}>
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
              <AiFillStar size={20} />
            </div>
            <div className={styles.text}>
              "Bis jetzt kann ich MBI sehr empfehlen. Sehr vertrauenswürdig, das
              Geld ist es mir wert!"
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MBIStartpage;
