import dayjs from "dayjs";
import React, { RefObject, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStateRef from "react-usestateref";
import {
  applyStatusEffect,
  dispatchStatusToTakeEffect,
  endCycle,
  GameState,
  IncidentResponse,
  Phase,
  revertStatusEffect,
  ServerUpgrade,
  StatEffectType,
  StatusDescription,
  StatusEffect,
  StatusTime,
  upgradeServer,
  selectIndicentResponse,
  executePendingEventCard,
  PendingEventCard,
  GeneralClientConfig,
  Incident,
  PendingResponse,
} from "../../api/api";
import { TimeContext, UserContext } from "../../context/state";
import { prepareIncidentResponseToQueue } from "../EventUtils";
import { translateSecondsToInGameTime } from "../GeneralUtil";
import {
  prepareRamInterval,
  checkRamCriticalStatusInterval,
  addAndDispatchLogs,
  cycleHandler,
} from "../TimeUtil";

export const useCentralTime = (): {
  dispatchOrApplyStatusEffect: (
    status: StatusEffect<any>
  ) => Promise<void | number | undefined>;
  endCycleHelper: (gameState: GameState) => void;
  fastForwardActive: boolean;
  toggleFastForward: (toggle: boolean) => void;
  playPauseActive: boolean;
  togglePlayPause: (toggle: boolean) => void;
  pauseRef: RefObject<boolean>;
} => {
  const {
    gameState,
    fetchGameState,
    gameStateRef,
    setLocalGameState,
    setGameOver,
    incidentsModalOpened,
    gameOver,
    victory,
    selectedIncident,
    setSelectedIncident,
    openIncidentsModal,
  } = React.useContext(UserContext);

  const { timeRef, setCurrentTime, timeMod } = React.useContext(TimeContext);

  const { phase } = { ...gameState };

  const [promisesToBeResolved, setPromisesToBeResolved, promisesRef] =
    useStateRef<Promise<any>[]>([]);

  const [fastForwardActive, toggleFastForward] = useState<boolean>(false);

  const [playPauseActive, togglePlayPause, pauseRef] =
    useStateRef<boolean>(false);

  const [intervalId, setIntervalId, intervalRef] = useStateRef<NodeJS.Timer>();

  const [currentTimeIntervalId, setCurrentTimeIntervalId] =
    useState<NodeJS.Timer>();

  const [activeUsersIntervalId, setactiveUsersIntervalId] =
    useState<NodeJS.Timer>();
  const [ramCriticalInterval, setRamCriticalInterval] =
    useState<NodeJS.Timer>();

  /**
   * Checks for new imminent {@link Incident}s and opens the modal if there is a new one
   */
  useEffect(() => {
    if (
      !gameState.phaseTwoState.imminentIncidents ||
      gameState.phaseTwoState.imminentIncidents.length <= 0 ||
      selectedIncident?.gameId ===
        gameState.phaseTwoState.imminentIncidents[0].gameId ||
      !!gameState.phaseTwoState.pendingResponses.find(
        (response) =>
          !!gameState.phaseTwoState.imminentIncidents
            .flatMap((imminent) => imminent.responses)
            .find(
              (imminentResponse) =>
                response.response.gameId === imminentResponse.gameId
            )
      )
    )
      return;
    const firstIncident = { ...gameState.phaseTwoState.imminentIncidents[0] };
    openIncidentsModal(true);
    const startTime = translateSecondsToInGameTime(
      {
        inGameDate: gameState.currentDate,
        inGameTime: timeRef.current || 0,
      },
      0,
      10
    );
    const timeUntilActive = translateSecondsToInGameTime(
      {
        inGameDate: gameState.currentDate,
        inGameTime: timeRef.current || 0,
      },
      firstIncident.reactionTime,
      10
    );
    setSelectedIncident({ ...firstIncident, timeUntilActive, startTime });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  /**
   * useEffect to handle day/time cycle
   */
  useEffect(() => {
    if (phase !== Phase.TWO) {
      clearInterval(currentTimeIntervalId);
      clearInterval(activeUsersIntervalId);
      clearInterval(ramCriticalInterval);
      return;
    }
    if (playPauseActive || incidentsModalOpened || gameOver || victory) {
      clearInterval(currentTimeIntervalId);
      clearInterval(activeUsersIntervalId);
      clearInterval(ramCriticalInterval);
    } else {
      clearInterval(currentTimeIntervalId);
      clearInterval(activeUsersIntervalId);
      clearInterval(ramCriticalInterval);

      setactiveUsersIntervalId(
        prepareRamInterval(gameStateRef, timeRef, setLocalGameState, timeMod)
      );
      setCurrentTimeIntervalId(
        cycleHandler(timeMod, setCurrentTime, cycleFinished, timeRef)
      );
      setRamCriticalInterval(
        checkRamCriticalStatusInterval(
          gameStateRef,
          timeRef,
          setLocalGameState,
          timeMod
        )
      );
    }

    return function () {
      // perform cleanup...
      clearInterval(currentTimeIntervalId);
      clearInterval(activeUsersIntervalId);
      clearInterval(ramCriticalInterval);
    };
    //eslint-disable-next-line
  }, [
    phase,
    playPauseActive,
    fastForwardActive,
    timeMod,
    incidentsModalOpened,
    gameOver,
  ]);

  /**
   * This effect will be executed when Status To Display changes
   */
  useEffect(() => {
    if (
      !gameStateRef.current ||
      !gameStateRef.current.phaseTwoState ||
      !gameStateRef.current.phaseTwoState.statusToDispatch ||
      gameStateRef.current.phaseTwoState.statusToDisplay.length <= 0
    )
      return;
    const statusToDisplay = gameStateRef.current.phaseTwoState.statusToDisplay;
    statusToDisplay.forEach((status) => {
      toast(
        `${status.title} - Status Effekt ${
          StatusDescription[status.type as StatEffectType]
        }: ${status.firstParameter}${
          status.secondParameter ? status.secondParameter : ""
        } !`,
        {
          position: "top-right",
          autoClose: GeneralClientConfig.toastLengthInMs,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
    });
  }, [gameStateRef]);

  /**
   * Helper method to prepare the selected response and add it to the pending responses and execute it on time
   * @param selectedResponse - the selected {@link IncidentResponse}
   */
  const helperForResponseSelection = (
    selectedResponse: IncidentResponse,
    passedIncident: Incident
  ) => {
    const preparedResponse: PendingResponse = prepareIncidentResponseToQueue(
      selectedResponse,
      timeRef.current!,
      gameStateRef.current!.currentDate
    );
    setLocalGameState({
      ...gameStateRef.current!,
      phaseTwoState: {
        ...gameStateRef.current!.phaseTwoState,
        imminentIncidents:
          gameStateRef.current!.phaseTwoState.imminentIncidents.filter(
            (incident) => incident.gameId !== passedIncident.gameId
          ),
        pendingResponses: [
          ...gameStateRef.current!.phaseTwoState.pendingResponses,
          preparedResponse,
        ],
      },
    });
    openIncidentsModal(false);
    setSelectedIncident(undefined);
    localStorage.removeItem("selectedIncident");
  };

  /**
   * Helper function to prepare the end of the c<cle
   */
  const cycleFinished = (): void => {
    clearInterval(currentTimeIntervalId);
    clearInterval(activeUsersIntervalId);
    clearInterval(ramCriticalInterval);
    let gameStatePreparedForCycleFinish: GameState =
      addAndDispatchLogs(gameStateRef);
    endCycleHelper(gameStatePreparedForCycleFinish);
  };

  /**
   * Helper function that gets executed when a cycle ends. Adds promise to array of promises to be resolved.
   * @param gameState - state to pass to end function
   */
  const endCycleHelper = (gameState: GameState) => {
    setLocalGameState({
      ...gameState,
      currentDate: dayjs(new Date(gameState.currentDate))
        .add(1, "day")
        .toDate(),
    });
    setPromisesToBeResolved([...promisesRef.current, endCycle(gameState)]);
  };

  /**
   * When promises are ready to resolve this effect will be executed.
   */
  useEffect(() => {
    if (promisesToBeResolved.length === 0) return;
    Promise.all([...promisesToBeResolved])
      .then((responses) => {
        setPromisesToBeResolved([]);
        return responses.includes("cycle");
      })
      .then((cycleEndIncluded) => {
        fetchGameState();
        if (cycleEndIncluded) {
          setCurrentTime(0);
        }
      });
    //eslint-disable-next-line
  }, [promisesToBeResolved]);

  /**
   * Helper function to prepare promises for {@link ServerUpgrade}
   * @param upgradesToBeMade - the upgrade to be made
   * @returns - array of promises
   */
  const prepareServerUpgrade = (
    upgradesToBeMade: {
      id: string;
      time: StatusTime;
      upgrade: ServerUpgrade;
    }[]
  ): Promise<boolean | undefined>[] => {
    let upgradePromises: Promise<boolean | undefined>[] = [];
    for (
      let upgradeIndex = 0;
      upgradeIndex < upgradesToBeMade.length;
      upgradeIndex++
    ) {
      const upgradeWithTime = upgradesToBeMade[upgradeIndex];
      setLocalGameState({
        ...gameStateRef.current!,
        phaseTwoState: {
          ...gameStateRef.current!.phaseTwoState,
          pendingUpgrades:
            gameStateRef.current!.phaseTwoState.pendingUpgrades.filter(
              (pendingUprade) => pendingUprade.id !== upgradeWithTime.id
            ),
        },
      });
      upgradePromises.push(upgradeServer(upgradeWithTime.upgrade));
    }
    return upgradePromises;
  };

  /**
   * Helper function to prepare promises for {@link EventIncidentsUnionType}
   * @param eventsToBeExecuted  - the event to be executed
   * @returns  - array of promises
   */
  const preparePendingEventCardToExecute = (
    eventsToBeExecuted: PendingEventCard[]
  ): Promise<boolean | undefined>[] => {
    let eventPromises: Promise<boolean | undefined>[] = [];
    for (
      let upgradeIndex = 0;
      upgradeIndex < eventsToBeExecuted.length;
      upgradeIndex++
    ) {
      const eventsWithTime = eventsToBeExecuted[upgradeIndex];
      if (
        "effects" in eventsWithTime.card &&
        eventsWithTime.card.effects.find(
          (effect) => effect.type === StatEffectType.DEFEAT
        )
      ) {
        initiateGameOverHelper();
        break;
      } else {
        setLocalGameState({
          ...gameStateRef.current!,
          phaseTwoState: {
            ...gameStateRef.current!.phaseTwoState,
            pendingEventCards:
              gameStateRef.current!.phaseTwoState.pendingEventCards.filter(
                (pendingEventCard) =>
                  pendingEventCard.card.gameId !== eventsWithTime.card.gameId
              ),
          },
        });
      }

      eventPromises.push(executePendingEventCard(eventsWithTime.card));
    }
    return eventPromises;
  };

  /**
   * Helper function to prepare promises for {@link IncidentResponse}
   * @param responsesToBeExecuted  - the response to be executed
   * @returns  - array of promises
   */
  const prepareIncidentResponseExecution = (
    responsesToBeExecuted: {
      id: string;
      time: StatusTime;
      response: IncidentResponse;
    }[]
  ): Promise<boolean | undefined>[] => {
    let incidentResponsePromises: Promise<boolean | undefined>[] = [];
    for (
      let responseIndex = 0;
      responseIndex < responsesToBeExecuted.length;
      responseIndex++
    ) {
      const responseWithTime = responsesToBeExecuted[responseIndex];
      setLocalGameState({
        ...gameStateRef.current!,
        phaseTwoState: {
          ...gameStateRef.current!.phaseTwoState,
          pendingResponses:
            gameStateRef.current!.phaseTwoState.pendingResponses.filter(
              (pendingResponse) => pendingResponse.id !== responseWithTime.id
            ),
        },
      });
      incidentResponsePromises.push(
        selectIndicentResponse(responseWithTime.response)
      );
    }
    return incidentResponsePromises;
  };

  /**
   * Helper function to prepare a status effect to either be applied or reverted
   * @param statusEffects  - array of status effects
   * @param type  - apply / revert
   * @returns  - array of promises
   */
  const prepareStatusEffect = (
    statusEffects: StatusEffect<any>[],
    type: "apply" | "revert"
  ): Promise<number | undefined>[] => {
    let statusPromises: Promise<number | undefined>[] = [];
    for (
      let statusIndex = 0;
      statusIndex < statusEffects.length;
      statusIndex++
    ) {
      const status: StatusEffect<any> = statusEffects[statusIndex];
      try {
        if (type === "apply") {
          setLocalGameState({
            ...gameStateRef.current!,
            phaseTwoState: {
              ...gameStateRef.current!.phaseTwoState,
              statusToDispatch:
                gameStateRef.current!.phaseTwoState.statusToDispatch.filter(
                  (statusEffect) => statusEffect.id !== status.id
                ),
            },
          });
          statusPromises.push(applyStatusEffect(status));
          toast(
            `${status.title} -  Status Effekt ${
              StatusDescription[status.type as StatEffectType]
            }: ${status.firstParameter}${
              status.secondParameter ? status.secondParameter : ""
            } !`,
            {
              position: "top-right",
              autoClose: GeneralClientConfig.toastLengthInMs,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
        } else {
          setLocalGameState({
            ...gameStateRef.current!,
            phaseTwoState: {
              ...gameStateRef.current!.phaseTwoState,
              statusToRevert:
                gameStateRef.current!.phaseTwoState.statusToRevert.filter(
                  (statusEffect) => statusEffect.id !== status.id
                ),
            },
          });
          statusPromises.push(revertStatusEffect(status));
        }
      } catch {
        console.error(`Error ${type}ing status effect`);
      }
    }
    return statusPromises;
  };

  /**
   * Effect that triggers an interval on render to check whether a status effect
   * should be applied or reverted.
   */
  useEffect(() => {
    clearInterval(intervalId);
    const bufferIntervalId: NodeJS.Timer = setInterval(() => {
      const currentTime: number = timeRef.current || -1;
      const paused: boolean | undefined = pauseRef.current || undefined;
      const gameState: GameState | undefined =
        gameStateRef.current || undefined;
      if (
        currentTime < 0 ||
        !gameState ||
        gameState?.phase !== Phase.TWO ||
        paused
      )
        return;
      let resolvingPromises: Promise<number | undefined | boolean>[] = [];
      if (
        selectedIncident &&
        (dayjs(selectedIncident.timeUntilActive!.inGameDate).isBefore(
          gameState.currentDate,
          "day"
        ) ||
          (dayjs(selectedIncident.timeUntilActive!.inGameDate).isSame(
            gameState.currentDate,
            "day"
          ) &&
            selectedIncident.timeUntilActive!.inGameTime <= currentTime))
      ) {
        helperForResponseSelection(
          selectedIncident.responses[0],
          selectedIncident
        );
      }
      resolvingPromises = [
        ...prepareStatusEffect(
          gameState.phaseTwoState.statusToDispatch.filter(
            (statusEffect) =>
              dayjs(statusEffect.timeUntilActive!.inGameDate).isBefore(
                gameState.currentDate,
                "day"
              ) ||
              (dayjs(statusEffect.timeUntilActive!.inGameDate).isSame(
                gameState.currentDate,
                "day"
              ) &&
                statusEffect.timeUntilActive!.inGameTime <= currentTime)
          ),
          "apply"
        ),
      ];
      resolvingPromises = [
        ...resolvingPromises,
        ...prepareStatusEffect(
          gameState.phaseTwoState.statusToRevert.filter(
            (statusEffect) =>
              dayjs(statusEffect.duration!.inGameDate).isBefore(
                gameState.currentDate,
                "day"
              ) ||
              (dayjs(statusEffect.duration!.inGameDate).isSame(
                gameState.currentDate,
                "day"
              ) &&
                statusEffect.duration!.inGameTime <= currentTime)
          ),
          "revert"
        ),
      ];
      resolvingPromises = [
        ...resolvingPromises,
        ...prepareServerUpgrade(
          gameState.phaseTwoState.pendingUpgrades.filter(
            (pendingUpgrade) =>
              dayjs(pendingUpgrade.time.inGameDate).isBefore(
                gameState.currentDate,
                "day"
              ) ||
              (dayjs(pendingUpgrade.time.inGameDate).isSame(
                gameState.currentDate,
                "day"
              ) &&
                pendingUpgrade.time.inGameTime <= currentTime)
          )
        ),
      ];
      resolvingPromises = [
        ...resolvingPromises,
        ...prepareIncidentResponseExecution(
          gameState.phaseTwoState.pendingResponses.filter(
            (pendingResponse) =>
              dayjs(pendingResponse.time.inGameDate).isBefore(
                gameState.currentDate,
                "day"
              ) ||
              (dayjs(pendingResponse.time.inGameDate).isSame(
                gameState.currentDate,
                "day"
              ) &&
                pendingResponse.time.inGameTime <= currentTime)
          )
        ),
      ];
      resolvingPromises = [
        ...resolvingPromises,
        ...preparePendingEventCardToExecute(
          gameState.phaseTwoState.pendingEventCards.filter(
            (pendingEventCard) =>
              dayjs(pendingEventCard.executionTime.inGameDate).isBefore(
                gameState.currentDate,
                "day"
              ) ||
              (dayjs(pendingEventCard.executionTime.inGameDate).isSame(
                gameState.currentDate,
                "day"
              ) &&
                pendingEventCard.executionTime.inGameTime <= currentTime)
          )
        ),
      ];

      setPromisesToBeResolved([...resolvingPromises]);
    }, 1000);

    setIntervalId(bufferIntervalId);

    return () => {
      clearInterval(intervalId);
    };
    //eslint-disable-next-line
  }, []);

  const initiateGameOverHelper = () => {
    setGameOver(true);
    setPromisesToBeResolved([]);
    clearInterval(intervalRef.current!);
  };

  /**
   * Helper method to send the status effect to apply or dispatch.
   * @param status  The status effect to send.
   */
  const sendStatusEffectToApplyOrDispatch = (
    status: StatusEffect<any>
  ): Promise<void | number | undefined> => {
    if (!!status.timeUntilActive) {
      return dispatchStatusToTakeEffect(status).then(() => fetchGameState());
    } else {
      return applyStatusEffect(status);
    }
  };

  return {
    dispatchOrApplyStatusEffect: sendStatusEffectToApplyOrDispatch,
    endCycleHelper: (gameState) => endCycleHelper(gameState),
    fastForwardActive: fastForwardActive,
    toggleFastForward: toggleFastForward,
    playPauseActive: playPauseActive,
    togglePlayPause: togglePlayPause,
    pauseRef: pauseRef,
  };
};
