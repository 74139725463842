import React from "react";
import { Outlet, Link } from "react-router-dom";
import styles from "./styles/FCorp.module.css";

function FCorp() {
  return (
    <div className={styles.container}>
      <div className={styles.navBar}>
        <div className={styles.left}>
          <Link to="" className={styles.link}>
            <strong>FCorp</strong>
          </Link>
        </div>
        <div className={styles.right}>
          <Link to="" className={styles.link}>
            Impressum
          </Link>
        </div>
      </div>
      <h2 className={styles.heading}>Die sichere Lösung aus Deutschland.</h2>
      <div className={styles.content}>
        <p>
          <strong>Wer wir sind:</strong>
        </p>
        <div>
          Mit mehr als sieben Millionen Kundenverträgen ist FCorp einer der
          führenden europäischen Anbieter und ist Partner von Selbstständigen,
          Privatpersonen und Unternehmen aus aller Welt.
        </div>
        <p>
          Die Rechenzentren von FCorp sind in ganz Europa verteilt und bieten
          einen schnellen und sicheren Austausch mit den eigenen Daten.
        </p>

        <p>
          Im Jahr 2005 wurde FCorp von Elliot Anders und Darlene White
          gegründet.
        </p>
        <em>
          "Wir sind mehr als eine Firma, die Server verkauft. Wir verkaufen
          Zukunft."
        </em>
        <p>Elliot Anders</p>
        <em>
          "Unsere Server sind zu jeder Zeit erreichbar. Wartungen bekommen Sie
          nicht mit.""
        </em>
        <p>Darlene White</p>
      </div>

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.box}>
            <div className={styles.cardContent}>
              <h2>10 GB</h2>
              <p className={styles.list}>
                <ul>
                  <li>14 Tage kostenfrei testen</li>
                  <li>automatische Versionsverwaltung</li>
                  <li>Ende-zu-Ende-Verschlüsselung</li>
                  <li>Serverstandort in Deutschland</li>
                  <li>Keine Mindestvertragslaufzeit</li>
                </ul>
              </p>
              <span className={styles.price}>3€/Monat</span>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.box}>
            <div className={styles.cardContent}>
              <h2>50 GB</h2>
              <p className={styles.list}>
                <ul>
                  <li>14 Tage kostenfrei testen</li>
                  <li>automatische Versionsverwaltung</li>
                  <li>Ende-zu-Ende-Verschlüsselung</li>
                  <li>Serverstandort in Deutschland</li>
                  <li>Keine Mindestvertragslaufzeit</li>
                </ul>
              </p>
              <span className={styles.price}>5€/Monat</span>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.box}>
            <div className={styles.cardContent}>
              <h2>100 GB</h2>
              <p className={styles.list}>
                <ul>
                  <li>14 Tage kostenfrei testen</li>
                  <li>automatische Versionsverwaltung</li>
                  <li>Ende-zu-Ende-Verschlüsselung</li>
                  <li>Serverstandort in Deutschland</li>
                  <li>Keine Mindestvertragslaufzeit</li>
                </ul>
              </p>
              <span className={styles.price}>8€/Monat</span>
            </div>
          </div>
        </div>
      </div>

      <>
        <Outlet />
      </>
    </div>
  );
}

export default FCorp;
