import React from "react";
import styles from "../styles/Statistics.module.css";

import { ReactComponent as DollarIcon } from "../../../assets/icons/Monitoring/dollar.svg";
import { ReactComponent as UserGroupIcon } from "../../../assets/icons/Monitoring/usergroup.svg";
import { OverviewItem } from "../components/OverviewItem";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import {
  getUserLineChartOptions,
  createUserLineData,
} from "../../../util/Monitoring/Monitoring.types";
import { UserContext } from "../../../context/state";
import { formatDate } from "../../../util/GeneralUtil";

const StatisticsPage: React.FC = () => {
  const { gameState } = React.useContext(UserContext);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  return (
    <div className={styles.statisticsWrapper}>
      <div className={styles.statisticsHeaderContainer}>
        <h3 className={styles.statisticsHeader}>Benutzer und Finanzen</h3>
      </div>
      <div className={styles.statisticsItemContainer}>
        <OverviewItem
          text="Registrierte Benutzer"
          icon={<UserGroupIcon />}
          textAndBorderColor="#4e73df"
          value={gameState.phaseTwoState.currentUsers.toString()}
        />

        <OverviewItem
          text="Tägliches Einkommen"
          icon={<DollarIcon />}
          textAndBorderColor="#1cc88a"
          value={gameState.phaseTwoState.incomePerCycle.toString()}
        />
      </div>
      <div className={styles.ressourceChartContainer}>
        <div className={styles.chartCard}>
          <div className={styles.chartCardHeaderContainer}>
            <h4 className={styles.chartCardHeader}>Benutzerzahlen</h4>
          </div>
          <div className={styles.chartContainer}>
            <Line
              className={styles.chartLine}
              options={getUserLineChartOptions(
                gameState.phaseTwoState.currentServer.userAmountHistory.flatMap(
                  (userDate) => formatDate(new Date(userDate.date))
                ),
                gameState.phaseTwoState.currentServer.maxStorage /
                  gameState.gameConfigs.phaseTwo.storageConfig.storagePerUser
              )}
              data={createUserLineData(
                gameState.phaseTwoState.currentServer.userAmountHistory.flatMap(
                  (userDate) => formatDate(new Date(userDate.date))
                ),
                gameState.phaseTwoState.currentServer.userAmountHistory.flatMap(
                  (userDate) => userDate.amount
                )
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsPage;
