import React from "react";
import styles from "./styles/MBI.module.css";

function MBISupport() {
  return (
    <div className={styles.container}>
      <div className={styles.places}>
        <div>
          <strong className={styles.heading}>Server Infos</strong>
          <br /> Mit unseren Servern bieten wir ein Höchstmaß an Qualität:
          <ul>
            <li>100% Uptime</li>
            <li>100% skalierbar</li>
            <li>Modernste Server-Technologie</li>
            <li>Einfache Einrichtung</li>
            <li>Höchstes Maß an Sicherheit</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MBISupport;
