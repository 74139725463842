import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function Sicherheitscheck() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Sicherheitscheck Allgemein</p>
        <p>
          Sicherheitscheck in der IT bedeutet: Das ausgewählte Programm wird
          gestestet. Der Test beinhaltet zum Beispiel, ob das System
          Schlupflöcher hat, die ausgenutzt werden können, um das System zu
          beschädigen oder um sensible Daten zu erlangen. Um die Schlupflöcher
          zu finden, dringt die Firma wie ein Angreifer in das System ein.
        </p>
        <p>
          Durch diesen Angriff wird schnell bemerkt, ob ein System
          Sicherheitslücken aufweist.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/firewall" className={styles.link}>
            <li>Firewall Allgemein</li>
          </Link>
          <Link to="/browser/wiki/isoiec" className={styles.link}>
            <li>ISO/IEC 27001</li>
          </Link>
          <Link to="/browser/wiki/pentest" className={styles.link}>
            <li>Pentest</li>
          </Link>
          <Link to="/browser/wiki/digitaleforensik" className={styles.link}>
            <li>Digitale Forensik</li>
          </Link>
          <Link to="/browser/wiki/abusecase" className={styles.link}>
            <li>Abuse Case</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Sicherheitscheck;
