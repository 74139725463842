import dayjs from "dayjs";
import { StatusTime } from "../api/api";

/**
 * Util function to pad a digit to with zeros
 * @param numberToPad - number to pad
 * @returns  - padded number
 */
const padTo2Digits = (numberToPad: number) => {
  return numberToPad.toString().padStart(2, "0");
};

/**
 * Util function to format date to DD/MM/YYYY
 * @param dateToFormat - date to format
 * @returns - formatted date
 */
export const formatDate = (dateToFormat: Date): string => {
  return [
    padTo2Digits(dateToFormat.getDate()),
    padTo2Digits(dateToFormat.getMonth() + 1),
    dateToFormat.getFullYear(),
  ].join("/");
};

/**
 * Util function to check if two dates are the same day
 * @param date1 - date to compare
 * @param date2 - date to compare
 * @returns - true if the dates are the same day
 */
export const isSameDay = (date1: Date, date2: Date): boolean => {
  if (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Util function to format a desired duration to in game time
 * @param gameTime - current in game time
 * @param timeToAdd - desired time to add
 * @param cycleLength - length of a cycle
 * @returns - the new in game time
 */
export const translateSecondsToInGameTime = (
  gameTime: StatusTime,
  timeToAdd: number,
  cycleLength: number
): StatusTime => {
  const addedTime = gameTime.inGameTime + timeToAdd;
  const daysToAdd = Math.floor(addedTime / cycleLength);
  const newTime = addedTime % cycleLength;
  return {
    inGameDate: dayjs(gameTime.inGameDate).add(daysToAdd, "day").toDate(),
    inGameTime: newTime,
  };
};
