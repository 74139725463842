import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function PlatformAsAService() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Platform as a Service (PaaS)</p>
        <p>
          Der Cloud-Anbieter übernimmt die Wartung und Infrastruktur des
          Servers. Übernommen werden muss noch:
          <ul>
            <li>Entwicklung der Software</li>
            <li>Inbetriebnahme der Software</li>
            <li>Administration der Anwendung und Server-Infrastruktur</li>
          </ul>
          Der Verwaltungsfreiraum ist zu einem gewissen Grad sebst
          konfigurierbar.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/saas" className={styles.link}>
            <li>Software as a Service (SaaS)</li>
          </Link>
          <Link to="/browser/wiki/iaas" className={styles.link}>
            <li>Infrastructure as a Service (IaaS)</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default PlatformAsAService;
