import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function HostingAnbieter() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Hosting-Anbieter</p>
        <p>
          Hosting-Anbieter bedeutet, dasss der externe Anbieter einen Server
          stellt. Auf diesem wird ein Teil des Speichers reserviert, alle Daten
          werden dort gespeichert.
        </p>
        <p>
          Der Server kann gewisse Dienste bereits unterstützen oder alles wird
          selbst konfiguriert
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/selfhosted" className={styles.link}>
            <li>Self-Hosted</li>
          </Link>
          <Link to="/browser/wiki/cloud" className={styles.link}>
            <li>Cloud-Anbieter</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default HostingAnbieter;
