import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function ISOIEC() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Sicherheitscheck Allgemein</p>
        <p>
          Die internationale Norm ISO/IEC 27001 umfasst einen Katalog von
          Anforderungen an die Umsetzung, Einrichtung, Aufrechterhaltung und
          fortlaufende Verbesserung eines dokumentierten Managementsystems für
          Informationssicherheit. Dies bedeutet, dass die Firma die
          Informationssicherheit dauerhaft definieren, steuern, kontrollieren,
          aufrechterhalten und fortlaufend verbessern muss.
        </p>
        <p>
          Das Bundesamt für Sicherheit in der Informationstechnik legt
          besonderen Wert auf die Bereiche Vertraulichkeit, Integrität und
          Verfügbarkeit der Informationen.
        </p>
        <p>
          Die Norm beschreibt auch die Anforderungen für die Beurteilung und
          Behandlung von Informationssicherheitsrisiken entsprechend den
          individuellen Bedürfnissen der Firma.
        </p>
        <p>
          Die ISO/IEC 27001 soll für verschiedene Bereiche anwendbar sein,
          insbesondere:
          <ul>
            <li>
              Zur Formulierung von Anforderungen und Zielsetzungen zur
              Informationssicherheit
            </li>
            <li>Zum kosteneffizienten Management von Sicherheitsrisiken</li>
            <li>
              Zur Sicherstellung der Konformität mit Gesetzen und Regularien
            </li>
            <li>
              Als Prozessrahmen für die Implementierung und das Management von
              Maßnahmen zur Sicherstellung von spezifischen Zielen zur
              Informationssicherheit
            </li>
            <li>
              Zur Definition von neuen
              Informationssicherheits-Managementprozessen
            </li>
            <li>
              Zur Identifitation und Definition von bestehenden
              Informationssicherheits-Managementprozessen
            </li>
            <li>
              Zur Definition von Informationssicherheits-Managementtätigkeiten
            </li>
            <li>
              Zum Gebrauch durch interne und externe Auditoren zur Feststellung
              des Umsetzungsgrades von Richtlinien und Standards
            </li>
          </ul>
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <div className={styles.subheader}>Zurück zum Wiki</div>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/firewall" className={styles.link}>
            <li>Firewall Allgemein</li>
          </Link>
          <Link to="/browser/wiki/sicherheitscheck" className={styles.link}>
            <li>Sicherheitscheck Allgemein</li>
          </Link>
          <Link to="/browser/wiki/pentest" className={styles.link}>
            <li>Pentest</li>
          </Link>
          <Link to="/browser/wiki/digitaleforensik" className={styles.link}>
            <li>Digitale Forensik</li>
          </Link>
          <Link to="/browser/wiki/abusecase" className={styles.link}>
            <li>Abuse Case</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default ISOIEC;
