import React from "react";
import EmailLayout from "./EmailLayout";
import Dashboard from "../../components/Dashboard";
import { UserContext } from "../../context/state";
import { Outlet } from "react-router-dom";

/**
 * 
 * @returns inserts all subpages as outlet
 */
function Email() {
  const isOpen = React.useContext(UserContext);

  return (
    <div>
      {isOpen?.dashboardOpened ? <Dashboard /> : null}
      <EmailLayout>
        <Outlet />
      </EmailLayout>
    </div>
  );
}

export default Email;
