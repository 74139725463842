import React from "react";
import { Outlet, Link } from "react-router-dom";
import { MesoBanner, MesoFooter } from "./../../../../assets/Icons";
import styles from "./styles/Meso.module.css";

function Meso() {
  return (
    <div className={styles.container}>
      <img src={MesoBanner} width="100%" />
      <div className={styles.navBar}>
        <Link to="server" className={styles.link}>Server</Link>
        <Link to="standorte" className={styles.link}>Standorte</Link>
        <Link to="support" className={styles.link}>Support</Link>
      </div>

      <>
        <Outlet />
      </>
      <div className={styles.footer}><Link to="impressum" className={styles.impressumLink}>Impressum</Link></div>
      
    </div>
  );
}

export default Meso;
