import { IActiveChat, IMessage } from "../../../api/api";


/**
 * 
 * Garbage inherited from the predecessors
 * 
 *
 */ 

//Function which returns next NPC messages + object with answers after getting the filter
function getNextMessageAnswerCombination(chat: IActiveChat, filter: Array<string> = [], numberFilterArray: Array<number> = []) {
    
    if (!chat) {
        console.error("No chat found");
        throw new Error("No chat found");
    }

    //Copy array, not only reference, so array can be changed
    const Messages = [...chat.chatObj.Messages];
    const resMessages :IMessage[] = [];
    const resAnswers :IMessage[] = [];

    
    // Filtering the messages


    //If chat is starting, add first message without filtering
    if (filter.length === 0) {
    
        //Delete messages after foreach loop is finished, otherwise it will skip some messages
        let deleteMessages = [];
        for (const message of Messages) {

            //If filter is not set, initialize it empty
            if (!message.Filter) message.Filter = [];
            
            //As soon as an answer is found, stop searching
            if (message.IsAnswer || message.Filter?.length !== 0) {
                deleteMessages.forEach((message) => {
                    Messages.splice(Messages.indexOf(message), 1);
                });
                break;
            } else {
                //Add message to result array and remove it from the original array
                resMessages.push(message);
                deleteMessages.push(message);
            }
        }
        
        //Filter is set
    } else {
        
        //Delete messages after foreach loop is finished, otherwise it will skip some messages
        let deleteMessages = [];
        for (const message of Messages) {
            
            //If filter is not set, initialize it empty
            if (!message.Filter) message.Filter = [];

            if (filter.length === message.Filter?.filter(value => filter.includes(value)).length) {
                
                //As soon as an answer is found, stop searching
                if (message.IsAnswer) {
                    deleteMessages.forEach((message) => {
                        Messages.splice(Messages.indexOf(message), 1);
                    });
                    break;
                } else {
                    
                    //Add message to result array and remove it from the original array
                    resMessages.push(message);
                    deleteMessages.push(message);
                }
                
            }
            
            
        }
    }
    
    // Filtering the answers

    //Delete messages after foreach loop is finished, otherwise it will skip some messages
    let deleteMessages :Array<IMessage> = [];
    for (const message of Messages) {
        
        //If filter is not set, initialize it empty
        if (!message.Filter) {message.Filter = [];}

        //If numberFilter is not set, initialize it empty
        if (!message.numberFilter) {
            //console.warn("No numberFilter found for message, maybe should be added in db. Message: ", message);
            message.numberFilter = 0;
        }

        if (filter.length === 0 || filter.length === message.Filter?.filter(value => filter.includes(value)).length) {

            //Make sure message is an answer.
            if (message.IsAnswer) {

                //Also filter messages with numberFilter, so they are not shown multiple times
                if (message.numberFilter === 0 || !numberFilterArray.includes(message.numberFilter)) {
                    resAnswers.push(message);
                    deleteMessages.push(message);
                }
            } else {
                deleteMessages.forEach(message => {
                    Messages.splice(Messages.indexOf(message), 1);
                });
                break;
            }
        }
    }


    return {resMessages, resAnswers}
}



export {getNextMessageAnswerCombination}