import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Navigator.module.css";

interface NavigatorProps {}

interface NavigationButtonProps {
  text: string;
  link: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ text, link }) => {
  return (
    <div className={styles.buttonContainer}>
      <Link to={link} className={styles.button}>
        <p className={styles.navigationText}>{text}</p>
      </Link>
    </div>
  );
};

const Navigator: React.FC<NavigatorProps> = () => {
  return (
    <div className={styles.navigatorContainer}>
      <div className={styles.navigatorHeadlineContainer}>
        <h1>Monitoring</h1>
      </div>
      <div className={styles.navigatorButtonContainer}>
        <NavigationButton text="Übersicht" link="/monitoring" />
        <NavigationButton
          text="Server-Ressourcen"
          link="/monitoring/ressourcen"
        />
        <NavigationButton
          text="Benutzerstatistiken"
          link="/monitoring/statistics"
        />
        <NavigationButton text="Logs" link="/monitoring/logs" />
      </div>
    </div>
  );
};

export default Navigator;
