import React from "react";
import styles from "./styles/MBIStandorte.module.css";
import { MBIKarte } from "../../../../assets/Icons";

function MBIStandorte() {
  return (
    <div className={styles.container}>
      <div className={styles.places}>
        <div>
          Die Standorte unserer Server-Zentren sind verteilt über ganz
          Deutschland.
          <br />
          Die genaue Adresse ist geheim. Hier sehen Sie aber ein paar unserer
          Rechenzentren:
        </div>

        <div className={styles.map}>
          <img src={MBIKarte} width="30%" />
        </div>
      </div>
    </div>
  );
}

export default MBIStandorte;
