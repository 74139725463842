import { RefObject } from "react";
import { toast } from "react-toastify";
import {
  GameState,
  MonitoringLog,
  LogMessages,
  StatEffectType,
  StatusEffect,
  GeneralClientConfig,
} from "../api/api";
import { convertLogTypeToToastType } from "./Monitoring/Monitoring.types";
import {
  checkAndAddRamCriticalStatus,
  createLog,
  getCurrentUsersActiveInPercent,
  updateRamLoadList,
} from "./TaskBarUtil";

/**
 * Util function to add time to the day according to given interval
 * @param timeMod - the speed of how fast a day passes
 * @param setTime - sets time for the progress bar UI
 * @param cycleFinished - handler for when a cycle/day passes
 * @param currentTimeRef - reference to get the current time
 * @returns - id of the interval
 */
export const cycleHandler = (
  timeMod: number,
  setTime: (time: number) => void,
  cycleFinished: () => void,
  currentTimeRef: RefObject<number>
): NodeJS.Timer => {
  return setInterval(() => {
    if ((currentTimeRef.current || 0) >= 10) {
      cycleFinished();
      setTime(0);
    }
    setTime(currentTimeRef.current! + 0.01);
  }, 10 / timeMod);
};

/**
 * Adds new logs for the day and dispatches them
 * @returns - a gamestate with added logs
 */
export const addAndDispatchLogs = (
  gameStateRef: RefObject<GameState>
): GameState => {
  const optionalLog: MonitoringLog[] = createLog(
    gameStateRef.current!.currentDate,
    gameStateRef.current!.phaseTwoState.currentServer.ramLoadHistory,
    gameStateRef.current!.gameConfigs.phaseTwo
  );

  if (!optionalLog || optionalLog.length <= 0)
    return {
      ...gameStateRef.current!,
      phaseTwoState: {
        ...gameStateRef.current!.phaseTwoState,
        currentServer: {
          ...gameStateRef.current!.phaseTwoState.currentServer,
        },
      },
    };

  //Dispatches all added logs to the player
  optionalLog.forEach((log) =>
    toast(LogMessages[log.type], {
      type: convertLogTypeToToastType(log.type),
      autoClose: GeneralClientConfig.toastLengthInMs,
      hideProgressBar: false,
      closeOnClick: true,
    })
  );

  const logHistory = [
    ...gameStateRef.current!.phaseTwoState.currentServer.logHistory,
    ...optionalLog,
  ];
  const clonedGameState: GameState = { ...gameStateRef.current! };

  return {
    ...clonedGameState,
    phaseTwoState: {
      ...clonedGameState.phaseTwoState,
      currentServer: {
        ...clonedGameState.phaseTwoState.currentServer,
        logHistory: [...logHistory.slice(-30)],
      },
    },
  };
};

/**
 * Helper function to set off the interval regarding active users and ram load calculation
 * @returns - the interval id
 */
export const prepareRamInterval = (
  gameStateRef: RefObject<GameState>,
  timeRef: RefObject<number>,
  setLocalGameState: (gameState: GameState) => void,
  timeMod: number
): NodeJS.Timer => {
  return setInterval(() => {
    const activeUsers = getCurrentUsersActiveInPercent(
      gameStateRef.current!.phaseTwoState.currentUsers,
      timeRef
    );
    updateRamLoadList(
      activeUsers,
      gameStateRef.current!,
      (partialServerConfig) =>
        setLocalGameState({
          ...gameStateRef.current!,
          ...partialServerConfig,
        })
    );
  }, 1000 / timeMod);
};

/**
 * Helper function that sets an interval to check if critical ram has been reached
 * and adds a status effect if so
 * @returns - id of set interval
 */
export const checkRamCriticalStatusInterval = (
  gameStateRef: RefObject<GameState>,
  timeRef: RefObject<number>,
  setLocalGameState: (gameState: GameState) => void,
  timeMod: number
): NodeJS.Timer => {
  return setInterval(() => {
    const sliceAmount: number = timeMod > 1 ? Math.ceil(-2 * timeMod) : -2;
    const ramCriticalStatus: StatusEffect<StatEffectType.RATING_SET>[] = [
      ...checkAndAddRamCriticalStatus(
        gameStateRef.current!.gameConfigs.phaseTwo.ratingRamFull,
        gameStateRef.current!.currentDate,
        gameStateRef.current!.phaseTwoState.ratingToBeAdded,
        timeRef,
        gameStateRef.current!.phaseTwoState.currentServer.ramLoadHistory.slice(
          sliceAmount
        )
      ),
    ];
    setLocalGameState({
      ...gameStateRef.current!,
      phaseTwoState: {
        ...gameStateRef.current!.phaseTwoState,
        ratingToBeAdded:
          ramCriticalStatus.length > 0 &&
          gameStateRef.current!.phaseTwoState.ratingToBeAdded >= 3
            ? 2
            : gameStateRef.current!.phaseTwoState.ratingToBeAdded,
        currentServer: {
          ...gameStateRef.current!.phaseTwoState.currentServer,

          ramWarningThresholdReached: !!gameStateRef
            .current!.phaseTwoState.currentServer.ramLoadHistory.slice(
              sliceAmount
            )
            .find(
              (load) =>
                load.load / 100 >
                gameStateRef.current!.gameConfigs.phaseTwo.ramConfig.warning
            ),
          ramCritical: !!gameStateRef
            .current!.phaseTwoState.currentServer.ramLoadHistory.slice(
              sliceAmount
            )
            .find(
              (load) =>
                load.load / 100 >
                gameStateRef.current!.gameConfigs.phaseTwo.ramConfig.full
            ),
        },
        statusToDispatch: [
          ...gameStateRef.current!.phaseTwoState.statusToDispatch,
          ...ramCriticalStatus,
        ],
      },
    });
  }, 1000);
};
