import React, { useCallback, useRef } from "react";
import styles from "./../styles/Taskbar.module.css";
import { Link } from "react-router-dom";
import { TimeContext, UserContext } from "../../context/state";
import {
  BrowserIcon_inactive,
  EmailIcon_inactive,
  ChatIcon_inactive,
  DesktopIcon_inactive,
  DashboardIcon_inactive,
  MenuIcon_inactive,
  CheckNormal,
  CheckActive,
  Checklist,
  WaehrungEuro,
  Monitoring_inactive,
  User,
} from "../../assets/Icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { Phase } from "../../api/api";
import { useWindowSize } from "react-use";
import { ReactComponent as PlayPauseIcon } from "../../assets/icons/PlayPause.svg";
import { ReactComponent as FastForwardIcon } from "../../assets/icons/FastForward.svg";
import { useCentralTime } from "../../util/hooks/useCentralTime";

const Taskbar: React.FC = () => {
  const { width } = useWindowSize();
  const { gameState, openDashboard, openMenu } = React.useContext(UserContext);

  const { currentTime, setTimeMOd } = React.useContext(TimeContext);
  const { phase, phaseOneState, phaseTwoState } = { ...gameState };

  const progressBarRef = useRef<HTMLDivElement>(null);

  const {
    fastForwardActive,
    toggleFastForward,
    playPauseActive,
    togglePlayPause,
  } = useCentralTime();

  // console.table(gameState.phaseTwoState.ratings);

  /**
   * Helper method to calculate width for progress bar
   * @returns number of width in pixels
   */
  const calculateProgress = useCallback(() => {
    if (!progressBarRef.current) return 0;

    return (
      (currentTime / 10) * progressBarRef.current.getBoundingClientRect().width
    );
    //eslint-disable-next-line
  }, [width, currentTime]);

  return (
    <div className={styles.taskbarWrapper}>
      <ToastContainer position="top-center" newestOnTop />
      <div className={styles.taskbarClipped}>
        <div className={styles.ressourceContainer}>
          <div className={styles.budgetContainer}>
            <div className={styles.budgetWrapper}>
              <span className={styles.budget}>
                <img src={WaehrungEuro} alt="check" className={styles.euro} />
                {gameState.budget}
              </span>
            </div>
            {phase === Phase.TWO && (
              <div className={styles.modifierWrapper}>
                <p className={styles.modifier}>
                  <span>{phaseTwoState.incomePerCycle || 0}</span>
                  {" / Tag"}
                </p>
              </div>
            )}
          </div>
          {phase === Phase.ONE ? (
            <div className={styles.checks}>
              <img src={Checklist} width="26px" alt="check" />
              {Array(phaseOneState.decisions)
                .fill(0)
                .map((_, index) => (
                  <img
                    src={
                      index + 1 < phaseOneState.currentDecision
                        ? CheckActive
                        : CheckNormal
                    }
                    width="24px"
                    alt="check"
                  />
                ))}
            </div>
          ) : (
            <>
              <div className={styles.timeContainer}>
                <div className={styles.progressBarContainer}>
                  <div
                    ref={progressBarRef}
                    className={styles.progressBar}
                  ></div>
                  <div
                    className={styles.progressBarTrack}
                    style={{ width: `${calculateProgress()}px` }}
                  ></div>
                </div>
                <div className={styles.buttonContainer}>
                  <div
                    className={styles.buttonRunning}
                    onClick={() => togglePlayPause(!playPauseActive)}
                  >
                    <PlayPauseIcon
                      className={
                        playPauseActive
                          ? styles.playPauseIconSelected
                          : styles.playPauseIcon
                      }
                    />
                  </div>
                  <div
                    className={styles.buttonFastForward}
                    onClick={() => {
                      setTimeMOd(!fastForwardActive ? 2.5 : 1);
                      toggleFastForward(!fastForwardActive);
                    }}
                  >
                    <FastForwardIcon
                      className={
                        fastForwardActive
                          ? styles.fastForwardIconSelected
                          : styles.fastForwardIcon
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.userContainer}>
                <div className={styles.userWrapper}>
                  <span className={styles.user}>
                    <img src={User} alt="check" className={styles.userIcon} />
                    {phaseTwoState.currentUsers}
                  </span>
                </div>
                <div className={styles.modifierWrapper}>
                  <p className={styles.modifier}>
                    {"ca. "}
                    <span>{phaseTwoState.usersPerCycle || 0}</span>
                    {" / Tag"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <nav className={styles.taskbar} style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flex: 1,
          }}
        >
          <span className={styles.navItem}>
            <Link to="/browser">
              <img
                src={BrowserIcon_inactive}
                alt="BrowserIcon"
                className={styles.icon}
              />
              <span className={styles.tooltip}>Browser</span>
            </Link>
          </span>
          <span className={styles.navItem}>
            <Link to="/chat">
              <img
                src={ChatIcon_inactive}
                alt="ChatIcon"
                className={styles.icon}
              />
              <span className={styles.tooltip}>Chat</span>
            </Link>
          </span>
          <span className={styles.navItem}>
            <Link to="/email">
              <img
                src={EmailIcon_inactive}
                alt="EmailIcon"
                className={styles.icon}
              />
              <span className={styles.tooltip}>Email</span>
            </Link>
          </span>
          {phase === Phase.TWO && (
            <span className={styles.navItem}>
              <Link to="/monitoring">
                <img
                  src={Monitoring_inactive}
                  alt="MonitoringIcon"
                  className={styles.icon}
                />
                <span className={styles.tooltip}>Monitoring</span>
              </Link>
            </span>
          )}
        </div>
        <div style={{ flex: 2 }} />
        <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
          <span className={styles.navItem}>
            <Link to="/desktop">
              <img
                src={DesktopIcon_inactive}
                alt="DesktopIcon"
                className={styles.icon}
              />
              <span className={styles.tooltip}>Desktop</span>
            </Link>
          </span>
          <span className={styles.navItem}>
            <img
              src={DashboardIcon_inactive}
              alt="DashboardIcon"
              className={styles.icon}
              onClick={() => openDashboard()}
            />
            <span className={styles.tooltip}>Dashboard</span>
          </span>
          <span className={styles.navItemDate}>
            {new Date(gameState.currentDate).toLocaleString().split(",")[0]}
          </span>
          <span className={styles.navItem}>
            <img
              src={MenuIcon_inactive}
              alt="MenuIcon"
              className={styles.icon}
              onClick={() => openMenu()}
            />
            <span className={styles.tooltip}>Menü</span>
          </span>
        </div>
      </nav>
    </div>
  );
};

export default Taskbar;
