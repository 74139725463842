import React, { useState } from "react";
import styles from "./styles/StartScreen.module.css";
import IntroBild from "../assets/icons/IntroBild.png";
import { Link } from "react-router-dom";
import { UserContext } from "../context/state";

function StartScreen(props: any) {
  const context = React.useContext(UserContext);
  const [show, setShow] = useState(true);

  const startGame = () => {
    if (!context.isAuth) {
      props.handleCookie();
      setShow(!show);
    }
  };
  return (
    <div className={styles.container}>
      {show ? (
        <div className={styles.modal}>
          <div className={styles.modalcontent}>
            <div className={styles.content}>
              <div className={styles.modalheader}>
                <img src={IntroBild} width="60%" alt="Introbild" />
              </div>
              <div>
                <div className={styles.intro}>
                  In diesem Spiel sollen Sie die Infrastruktur für eine eigene
                  Webanwendung von Grund auf aufbauen und betreiben. Die Datei{" "}
                  <em>Anleitung.pdf</em> auf Ihrem Desktop erklärt Ihnen die
                  unterschiedlichen Funktionen des Spiels.
                </div>
                <div className={styles.intro}>
                  Wir wünschen Ihnen viel Spaß beim Spielen.
                </div>
                <div className={styles.buttonContainer}>
                  <Link
                    to="/"
                    className={styles.button}
                    onClick={() => startGame()}
                  >
                    Neues Spiel
                  </Link>
                  <Link
                    to="/"
                    className={styles.button}
                    onClick={() => context.openTutorial()}
                  >
                    Tutorial starten
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default StartScreen;
