import React from "react";
import { Check, Server, Cloud } from "../../../../assets/Icons";
import styles from "./styles/GreenHills.module.css";

function GreenHillsStartpage() {
  return (
    <div>
      <div className={styles.content}>
        <strong>
          Die grüne Lösung für die Zukunft.
          <div>Bessere Software. Schnellere Verbindung.</div>
        </strong>
      </div>
      <div className={styles.content}>
        <img src={Check} width="40px" className={styles.check}/><p>Nutzen Sie die Infrastruktur, die sie wollen</p>
        <img src={Check} width="40px" className={styles.check}/><div>Profitieren Sie von Sicherheit</div>
      </div>

      <table className={styles.table}>
        <thead>
        <tr>
            <th><img src={Cloud}/></th>
            <th><img src={Server}/></th>
          </tr>
          <tr>
            <th className={styles.tableHeader}>Zukunftssichere Infrastruktur</th>
            <th className={styles.tableHeader}>Leistungsfähige Server</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Sichere, global verfügbare und leistungsstarke Server in Dublin.{" "}
              <br />
              Unsere Server sind auf langfristigen Erfolg ausgerichtet.
            </td>
            <td>
              Nutzen Sie IaaS, PaaS oder SaaS. Unsere Server unterstützen alles.
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  );
}

export default GreenHillsStartpage;
