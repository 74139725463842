import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function InfrastructureAsAService() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Infrastructure as a Service (IaaS)</p>
        <p>
          Der Cloud-Anbieter übernimmt die Infrastruktur des Servers. Das
          bedeutet: Physische und virtuelle Hardware.
        </p>
        <p>
          Übernommen werden muss noch:
          <ul>
            <li>Betriebssystem</li>
            <li>Entwicklungsumgebung</li>
            <li>Inbetriebnahme</li>
            <li>Administration der Anwendungen und des Servers</li>
          </ul>
          Dieser Service bietet einen großen Verwaltungsfreiraum. Das System
          kann ganz nach Belieben angepasst werden.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/paas" className={styles.link}>
            <li>Platform as a Service (PaaS)</li>
          </Link>
          <Link to="/browser/wiki/saas" className={styles.link}>
            <li>Software as a Service (SaaS)</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default InfrastructureAsAService;
