import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";
import {
  FirewallAnimation
} from "../../../../assets/Icons";

function Firewall() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <div className={styles.subheader}>Zurück zum Wiki</div>
        </Link>
        <p className={styles.heading}>Firewall Allgemein</p>
        <p>
          Eine Firewall bedeutet, dass ein Rechnernetz oder ein einzelner
          Computer vor unerwünschten Netzwerkzugriffen geschützt ist. Die
          Firewall funktioniert im Grunde wie eine Burg mit Zugbrücke. Die Burg
          sind hierbei die Daten der Nutzer und die Zugbrücke die Tür hinein.
        </p>
        <p>
          Ist ein Passierschein vorhanden, wird die Zugbrücke geöffnet und der
          Zugang ist erlaubt.
          Wenn kein Passierschein vorhanden ist, wird die Genehmigung des
          Passierens nicht erteilt. Die Zugbrücke bleibt geschlossen.
        </p>
        <p>
          Die standardmäßige Firewall wird mit dem Betriebssystem (Windows, Mac
          oder Linux) eines Rechners mitgeliefert. Diese Firewall kann nach
          Belieben erweitert werden, dies passiert größtenteils im geschäftlichen Bereich.
        </p>
        <p className={styles.animationContainer}>
          <img src={FirewallAnimation} width="900px" height="auto"/>
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <div className={styles.subheader}>Zurück zum Wiki</div>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/sicherheitscheck" className={styles.link}>
            <li>Sicherheitscheck Allgemein</li>
          </Link>
          <Link to="/browser/wiki/isoiec" className={styles.link}>
            <li>ISO/IEC 27001</li>
          </Link>
          <Link to="/browser/wiki/pentest" className={styles.link}>
            <li>Pentest</li>
          </Link>
          <Link to="/browser/wiki/digitaleforensik" className={styles.link}>
            <li>Digitale Forensik</li>
          </Link>
          <Link to="/wiki/abusecase" className={styles.link}>
            <li>Abuse Case</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Firewall;
