import React from "react";
import Taskbar from "./Taskbar";

interface TaskbarOutletProps {
  outlet: JSX.Element;
}
const TaskbarOutlet: React.FC<TaskbarOutletProps> = ({ outlet }) => {
  return (
    <div>
      {outlet}
      <Taskbar />
    </div>
  );
};

export default TaskbarOutlet;
