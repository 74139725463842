import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function Schutzziele() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Schutzziele</p>
        <p>
          <ul>
            <li>Vertraulichkeit</li>
            <li>Verfügbarkeit</li>
            <li>Integrität</li>
            <li>Authentizität</li>
            <li>Nichtabstreitbarkeit</li>
            <li>Zurechenbarkeit</li>
          </ul>
          <p>
            <strong>Vertraulichkeit</strong>
          </p>
          <p>
            Vertraulichkeit bedeutet, dass Daten lediglich von autorisierten
            Benutzern gelesen oder verändert werden dürfen. Dies gilt beim
            Zugriff auf Daten aber auch während der Datenübertragung.
          </p>
          <p>
            <strong>Verfügbarkeit</strong>
          </p>
          <p>
            Verfügbarkeit bedeutet, dass das System nicht ausfallen darf. Der
            Zugriff auf Daten muss innerhalb eines vereinbarten Zeitraumes
            gewährleistet sein.
          </p>
          <p>
            <strong>Integrität</strong>
          </p>
          <p>
            Integrität bedeutet, dass Daten nicht unbemerkt verändert werden
            dürfen. Alle Änderungen müssen nachvollziehbar sein.
          </p>
          <p>
            <h3>Weitere Schutzziele</h3>
          </p>
          <p>
            <strong>Authentizität</strong>
          </p>
          <p>
            Authentizität bedeutet, dass Überprüfbarkeit, Eigenschaften der
            Echtheit und Vertrauenswürdigkeit eines Objektes gewährleistet ist.
          </p>
          <p>
            <strong>Nichtabstreitbarkeit</strong>
          </p>
          <p>
            Nichtabstreitbarkeit bedeutet, dass keiner seine durchgeführten
            Handlungen abstreiten kann. Dies wird zum Beispiel durch
            elektronische Signaturen erlangt.
          </p>
          <p>
            <strong>Zurechenbarkeit</strong>
          </p>
          <p>
            Zurechenbarkeit bedeutet, dass eine durchgeführte Handlung einem
            eindeutigen Kommunikationsteilnehmer zugeordnet werden kann.
          </p>
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <div className={styles.subheader}>Zurück zum Wiki</div>
        </Link>
      </div>
    </div>
  );
}

export default Schutzziele;
