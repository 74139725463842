import React from "react";
import { MesoImpressum } from './../../../../assets/Icons'

function MesoImpressumpage() {
  return (
    <div>
      <>
        <img src={MesoImpressum} width="100%"/>
        Twitter: <a href="https://twitter.com/MBuckerzerg?s=20&t=jB1hUjy5U88hp-QSqw_bmA">Marquis Buckerzerg</a>
      </>
    </div>
  );
}

export default MesoImpressumpage;
