import React, { useMemo } from "react";
import styles from "../styles/PictureIT.module.css";
import {
  Bau,
  Logo,
  SternHalb,
  SternLeer,
  SternVoll,
} from "../../../assets/Icons";
import { UserContext } from "../../../context/state";
import { Phase } from "../../../api/api";
import { comments, RatingComment } from "../../../util/BrowserUtil";

interface RatingCommentDisplayProps {
  ratingComment: RatingComment;
}

const RatingCommentDisplay: React.FC<RatingCommentDisplayProps> = ({
  ratingComment,
}) => {
  const renderRatingStars = () => {
    const stars: JSX.Element[] = [];
    for (let i = 0; i < ratingComment.rating; i++) {
      stars.push(<img alt="Stern voll" src={SternVoll} />);
    }
    for (let i = 0; i < 5 - ratingComment.rating; i++) {
      stars.push(<img alt="Stern leer" src={SternLeer} />);
    }
    return stars;
  };
  return (
    <div className={styles.ratingComment}>
      <div className={styles.ratingAuthor}>{ratingComment.author}</div>
      <div className={styles.ratingStars}>
        {renderRatingStars().map((star, index) => (
          <span key={index}>{star}</span>
        ))}
      </div>
      <div className={styles.ratingCommentContainer}>
        <p>{ratingComment.comment}</p>
      </div>
    </div>
  );
};

export const PictureIT: React.FC = () => {
  const { gameState } = React.useContext(UserContext);

  const amountOfRatingsText = useMemo(() => {
    if (
      !gameState ||
      !gameState.phaseTwoState ||
      !gameState.phaseTwoState.ratings ||
      gameState.phaseTwoState.ratings.length <= 0
    )
      return `0 Bewertungen`;
    else if (gameState.phaseTwoState.ratings.length === 1) return `1 Bewertung`;
    else return `${gameState.phaseTwoState.ratings.length} Bewertungen`;
    //eslint-disable-next-line
  }, [gameState.phaseTwoState.ratings]);

  const averageRatingToDotFive = useMemo(() => {
    if (
      !gameState ||
      !gameState.phaseTwoState ||
      !gameState.phaseTwoState.ratings ||
      gameState.phaseTwoState.ratings.length <= 0
    )
      return 3;
    return (
      Math.round(
        (gameState.phaseTwoState.ratings.reduce((a, b) => a + b, 0) /
          gameState.phaseTwoState.ratings.length) *
          2
      ) / 2
    );
    //eslint-disable-next-line
  }, [gameState.phaseTwoState.ratings]);

  const renderedRatingsAsStars = useMemo(() => {
    const stars: JSX.Element[] = [];
    const amountFullStars: number = +averageRatingToDotFive
      .toString()
      .split(".")[0];
    const hasHalfStar: boolean =
      averageRatingToDotFive.toString().split(".")[1] === "5";
    for (let i = 0; i < amountFullStars; i++) {
      stars.push(<img alt="Stern voll" src={SternVoll} />);
    }
    if (hasHalfStar) {
      stars.push(<img alt="Stern halb" src={SternHalb} />);
    }
    for (let i = 0; i < 5 - amountFullStars - (hasHalfStar ? 1 : 0); i++) {
      stars.push(<img alt="Stern leer" src={SternLeer} />);
    }
    return stars;
    //eslint-disable-next-line
  }, [averageRatingToDotFive]);

  const rederedComments: JSX.Element[] = useMemo(() => {
    if (
      !gameState ||
      !gameState.phaseTwoState ||
      !gameState.phaseTwoState.ratings ||
      gameState.phaseTwoState.ratings.length <= 0
    )
      return [];
    let ratingComments: JSX.Element[] = [];
    let ratingDone: number[] = [];
    gameState.phaseTwoState.ratings.forEach((rating) => {
      if (ratingDone.includes(rating)) return;
      ratingComments.push(
        <RatingCommentDisplay ratingComment={comments[rating - 1]} />
      );
      ratingDone.push(rating);
    });
    return ratingComments;
    //eslint-disable-next-line
  }, [gameState.phaseTwoState.ratings]);

  return (
    <>
      {gameState.phase !== Phase.TWO ? (
        <div className={styles.container}>
          <div className={styles.heading}>
            Hier entsteht bald etwas Großartiges!
          </div>
          <div>
            <img src={Bau} width="300" alt="Baustelle" />
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.headerWrapper}>
            <div id={styles.logo}>
              <img alt="PictureIT Logo" src={Logo} />{" "}
            </div>

            <div className={styles.newHeading}>
              <div>
                <h2>PictureIT</h2>
              </div>
              <div>
                <p>{amountOfRatingsText}</p>
              </div>
            </div>
            <div className={styles.visualRatingContainer}>
              <div>
                {renderedRatingsAsStars.map((star, index) => (
                  <span key={index}>{star}</span>
                ))}
              </div>
              <div>
                <p>Durchschnittl. Bewertung</p>
              </div>
            </div>
          </div>

          <div className={styles.ratingsContainer}>
            {rederedComments.map((comment, index) => (
              <div key={index}>{comment}</div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PictureIT;
