import React from "react";
import styles from "./styles/Email.module.css";
import { Link } from "react-router-dom";
import { Close } from "../../assets/Icons";

type Props = {
  children: React.ReactNode;
};

/**
 * 
 * @param param0 all subpages
 * @returns view navigation in email application
 */
const Email: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className={styles.programContainer}>
      <section className={styles.programHeader}>
        <h1 className={styles.programHeading}>E-Mail</h1>
        <Link to="/">
          <img src={Close} alt="Closebutton" width="70px" />
        </Link>
      </section>
      <section className={styles.programBody}>
        <div className={styles.programFilter}>
          <h1>Ordner</h1>
          <Link
            to=""
            style={{ textDecoration: "none" }}
            className={styles.filterButton}
          >
            Posteingang
          </Link>
          <Link
            to="spam"
            style={{ textDecoration: "none" }}
            className={styles.filterButton}
          >
            Gesendet
          </Link>
          <Link
            to="spam"
            style={{ textDecoration: "none" }}
            className={styles.filterButton}
          >
            Spam
          </Link>
        </div>
        <div className={styles.programMainComponent}>{children}</div>
      </section>
    </div>
  );
};

export default Email;
