import React from "react";
import styles from "./styles/StartScreen.module.css";
import { Link } from "react-router-dom";
import { UserContext } from "../context/state";
import { Phase, startPhaseTwo } from "../api/api";
import { useNavigate } from "react-router";

function PhaseOneEnd() {
  const { gameState, setLocalGameState, fetchGameState } =
    React.useContext(UserContext);
  const navigate = useNavigate();

  /**
   * Helper function to start phase two and navigate to the start screen
   */
  const helperToStartPhaseTwo = async () => {
    setLocalGameState({ ...gameState, phase: Phase.TWO });
    await startPhaseTwo();
    await fetchGameState();
    navigate("/");
  };

  return (
    <div className={styles.container}>
      {gameState.phase === Phase.ONE_END ? (
        <div className={styles.modal}>
          <div className={styles.modalcontent}>
            <div className={styles.content}>
              <div className={styles.modalheader}>Phase 1 abgeschlossen</div>
              <div>
                <div className={styles.intro}>
                  Glückwunsch, du hast die erste Phase des Spieles PictureIT
                  gemeistert.
                </div>
                <div className={styles.buttonContainer}>
                  <Link to="/" className={styles.button}>
                    Zusammenfassung anzeigen
                  </Link>

                  <div
                    onClick={() => helperToStartPhaseTwo()}
                    className={styles.button}
                  >
                    Weiter zu Phase 2
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PhaseOneEnd;
