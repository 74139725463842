import React from "react";
import { Outlet } from "react-router-dom";
import MonitoringLayout from "./MonitoringLayout";

interface MonitoringProps {}

const Monitoring: React.FC<MonitoringProps> = ({}) => {

  return (
    <MonitoringLayout>
      <Outlet />
    </MonitoringLayout>
  );
};

export default Monitoring;
