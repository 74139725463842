import React, { useCallback, useRef } from "react";
import { useWindowSize } from "react-use";
import styles from "./styles/CountdownButton.module.css";

export interface CountdownButtonProps {
  startTime?: number;
  timeLeft?: number | string;
  title: string;
  onClick?(): void;
  tooltip?: string;
}

const CountdownButton: React.FC<CountdownButtonProps> = ({
  startTime,
  timeLeft,
  title,
  onClick,
  tooltip,
}) => {
  const trackRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  /**
   * Helper method to calculate width for progress bar
   * @returns number of width in pixels
   */
  const calculateProgress = useCallback(() => {
    if (!trackRef.current) return 0;
    return Math.min(
      (+timeLeft! / startTime!) *
        trackRef.current.getBoundingClientRect().width,
      trackRef.current.getBoundingClientRect().width
    );
    //eslint-disable-next-line
  }, [width, timeLeft]);

  return (
    <>
      <div
        className={styles.buttonContainer}
        style={onClick ? { cursor: "pointer" } : {}}
        onClick={() => onClick?.()}
        ref={trackRef}
      >
        <div className={styles.buttonTextWapper}>
          {timeLeft && typeof timeLeft === "number" && (
            <p>{`${title} - ${Math.floor(timeLeft)}s`}</p>
          )}
          {timeLeft && typeof timeLeft === "string" && (
            <p>
              <p>{`${title} - Bis Tag: ${timeLeft}`}</p>
            </p>
          )}
        </div>
        {startTime && timeLeft && typeof timeLeft === "number" && (
          <>
            <div />
            <div
              style={{ width: `${calculateProgress()}px` }}
              className={styles.timeTrack}
            />
          </>
        )}
        {tooltip && <span className={styles.tooltiptext}>{tooltip}</span>}
      </div>
    </>
  );
};

export default CountdownButton;
