import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function Overview() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <p className={styles.heading}>PictureIT Wiki</p>
        <p className={styles.subheader}>Willkommen im Wiki</p>
        <p>Hier finden Sie alle Wiki-Seiten auf einem Blick</p>

        <div className={styles.subheader}>Hosting-Modelle</div>
        <ul>
          <li className={styles.item}>
            <Link to="selfhosted" className={styles.item}>
              Self-Hosted
            </Link>
          </li>
          <li>
            <Link to="hosting" className={styles.item}>
              Hosting-Anbieter
            </Link>
          </li>
          <li>
            <Link to="cloud" className={styles.item}>
              Cloud-Anbieter
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>Hosting-Anbieter Service-Modell</div>
        <ul>
          <li>
            <Link to="sharedserver" className={styles.item}>
              Shared Server
            </Link>
          </li>
          <li>
            <Link to="dedicatedserver" className={styles.item}>
              Dedicated Server
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>Cloud-Anbieter Modelle</div>
        <ul>
          <li>
            <Link to="privatecloud" className={styles.item}>
              Private Cloud
            </Link>
          </li>
          <li>
            <Link to="publiccloud" className={styles.item}>
              Public Cloud
            </Link>
          </li>
          <li>
            <Link to="hybridcloud" className={styles.item}>
              Hybrid Cloud
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>Cloud-Service Modelle</div>
        <ul>
          <li>
            <Link to="saas" className={styles.item}>
              Software as a Service (SaaS)
            </Link>
          </li>
          <li>
            <Link to="paas" className={styles.item}>
              Platform as a Service (PaaS)
            </Link>
          </li>
          <li>
            <Link to="iaas" className={styles.item}>
              Infrastructure as a Service (IaaS)
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>Authentifizierung</div>
        <ul>
          <li>
            <Link to="zweifaktorauthentifizierung" className={styles.item}>
              Zwei-Faktor-Authentifizierung
            </Link>
          </li>
          <li>
            <Link to="thirdpartyauthentifizierung" className={styles.item}>
              Third-Party-Authentifzierung
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>Verschlüsselung</div>
        <ul>
          <li>
            <Link to="verschluesselung" className={styles.item}>
              Verschlüsselung allgemein
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>
          Schutzziele Informationssicherheit
        </div>
        <ul>
          <li>
            <Link to="schutzziele" className={styles.item}>
              Schutzziele
            </Link>
          </li>
        </ul>

        <div className={styles.subheader}>IT-Sicherheit</div>
        <ul>
          <li>
            <Link to="sicherheitscheck" className={styles.item}>
              Sicherheitscheck allgemein
            </Link>
          </li>
          <li>
            <Link to="firewall" className={styles.item}>
              Firewall allgemein
            </Link>
          </li>
          <li>
            <Link to="isoiec" className={styles.item}>
              ISO/IEC 27001
            </Link>
          </li>
          <li>
            <Link to="pentest" className={styles.item}>
              Pentest
            </Link>
          </li>
          <li>
            <Link to="digitaleforensik" className={styles.item}>
              Digitale Forensik
            </Link>
          </li>
          <li>
            <Link to="abusecase" className={styles.item}>
              Abuse Case
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Overview;
