import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function AbuseCase() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <div className={styles.subheader}>Zurück zum Wiki</div>
        </Link>
        <p className={styles.heading}>Abuse Case</p>
        <p>
          Abuse Case, oder auch Missbrauchsfälle, beschreibt wie ein Angreifer
          die Schwächen von Software-Funktionen missbraucht oder ausnutzt. In
          diesem Fall benutzt er die Schwachstellen, um die Anwendung
          anzugreifen. Genauer gesagt, suchen die Unruhestifter eine
          Möglichkeit, eine Funktion zu nutzen, die von den Entwicklern nicht
          erwartet wurde. Somit können Angreifer das System zu ihren Gunsten
          beeinflussen und unter Umständen an Daten gelangen.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <div className={styles.subheader}>Zurück zum Wiki</div>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/sicherheitscheck" className={styles.link}>
            <li>Sicherheitscheck Allgemein</li>
          </Link>
          <Link to="/browser/wiki/firewall" className={styles.link}>
            <li>Firewall Allgemein</li>
          </Link>
          <Link to="/browser/wiki/isoiec" className={styles.link}>
            <li>ISO/IEC 27001</li>
          </Link>
          <Link to="/browser/wiki/digitaleforensik" className={styles.link}>
            <li>Digitale Forensik</li>
          </Link>
          <Link to="/browser/wiki/digitaleforensik" className={styles.link}>
            <li>Digitale Forensik</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default AbuseCase;
