import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function CloudAnbieter() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Cloud-Anbieter</p>
        <p>
          Cloud-Anbieter bedeutet, dass der externe Anbieter eine virtuelle
          Cloud bereitstellt.
        </p>
        <p>
          Konkret gesagt liegt der Server virtuell im Internet. Der physische
          Hardware-Server mit den Daten steht in einem Gebäude. Auf diesen ist
          der Zugriff gesperrt.
        </p>
        <p>
          Es werden bereits gewisse Dienste unterstützt. Deshalb ist der
          Verwaltungsfreiraum begrenzt. Die Skalierung des Servers ist aus
          Benutzersicht einfach.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/selfhosted" className={styles.link}>
            <li>Self-Hosted</li>
          </Link>
          <Link to="/browser/wiki/hosting" className={styles.link}>
            <li>Hosting-Anbieter</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default CloudAnbieter;
