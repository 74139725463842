import dayjs from "dayjs";
import { RefObject } from "react";
import {
  createStatusEffect,
  GameState,
  LogMessages,
  LogType,
  MonitoringLog,
  PhaseTwoConfigProps,
  RamLoadHistory,
  StatEffectType,
  StatusEffect,
} from "../api/api";

/**
 * Util function to get active users as percentage
 * @param absoluteUserCount - absolute registered users
 * @param currentTimeRef - ref object to access current time
 * @returns - active users as percentage
 */
export const getCurrentUsersActiveInPercent = (
  absoluteUserCount: number,
  currentTimeRef: RefObject<number>
): number => {
  const stretch_x: number = 24.3;
  const stretch_y: number = 0.12;
  const cycleProgressInPercent: number =
    currentTimeRef.current === 0 ? 0 : currentTimeRef.current! / 10;
  let usersActivePercentage: number =
    stretch_y *
    (-0.00000453 * Math.pow(cycleProgressInPercent * stretch_x, 6) +
      0.00028576 * Math.pow(cycleProgressInPercent * stretch_x, 5) -
      0.006167224 * Math.pow(cycleProgressInPercent * stretch_x, 4) +
      0.04500372 * Math.pow(cycleProgressInPercent * stretch_x, 3) +
      0.081086309 * Math.pow(cycleProgressInPercent * stretch_x, 2) -
      1.318214285 * (cycleProgressInPercent * stretch_x) +
      2.6);
  return Math.round(absoluteUserCount * usersActivePercentage);
};

/**
 * Util function to calculate the ram used based on users currently active on PictureIT
 * @param ramPerUser - the amount of ram used per user
 * @param currentlyActiveUsers - the number of users currently active
 * @param maxAvailableRam - maximum available ram
 * @returns - ram used
 */
export const calculateRamUsed = (
  ramPerUser: number,
  currentlyActiveUsers: number,
  maxAvailableRam: number
): number => Math.min(ramPerUser * currentlyActiveUsers, maxAvailableRam);

/**
 * Util function to update the ram load list and possibly add warning/ full log
 * @param activeUsers - currently active users
 * @param gameState - current gameState
 * @param setGameState - gameStateSetter
 */
export const updateRamLoadList = (
  activeUsers: number,
  gameState: GameState,
  setGameState: (gameState: GameState) => void
) => {
  const phaseTwoConfig = gameState.gameConfigs.phaseTwo;
  const phaseTwoState = gameState.phaseTwoState;
  const currentServer = phaseTwoState.currentServer;

  const calculatedRamUsed = calculateRamUsed(
    phaseTwoConfig.ramConfig.ramPerUser,
    activeUsers,
    currentServer.maxRam
  );

  let clonedRamLoadHistory = [...(currentServer.ramLoadHistory || [])];
  clonedRamLoadHistory.push({
    date: gameState.currentDate,
    load: (calculatedRamUsed / currentServer.maxRam) * 100,
  });
  setGameState({
    ...gameState,
    phaseTwoState: {
      ...phaseTwoState,
      currentServer: {
        ...currentServer,
        ramUsed: calculatedRamUsed,
        ramLoadHistory: [...clonedRamLoadHistory.slice(-30)],
        maxRam: currentServer.maxRam,
      },
    },
  });
};

/**
 * Util function to check whether the ram is critical and returns status so
 * @param ratingRamFull - rating when ram is full
 * @param currentDate - current in game date
 * @param ratingToBeAdded - currently set rating that will be added
 * @param timeRef - ref object to access current time
 * @param slicedramLoadHistory - most current ram loads
 * @returns - returns arraaay of status effects
 */
export const checkAndAddRamCriticalStatus = (
  ratingRamFull: number,
  currentDate: Date,
  ratingToBeAdded: number,
  timeRef: RefObject<number>,
  slicedramLoadHistory: RamLoadHistory[]
): StatusEffect<any>[] => {
  if (ratingToBeAdded <= 2) return [];
  const ramCritical = !!slicedramLoadHistory.find(
    (ramLoad) => ramLoad.load >= 90
  );
  if (!ramCritical) return [];
  return [
    {
      ...createStatusEffect<StatEffectType.RATING_SET>(
        StatEffectType.RATING_SET,
        ratingRamFull,
        undefined,
        {
          inGameDate: dayjs(currentDate).toDate(),
          inGameTime: timeRef.current || 0,
        },
        {
          inGameDate: dayjs(currentDate).add(3, "day").toDate(),
          inGameTime: timeRef.current || 0,
        },
        "Server ist langsam"
      ),
      durationInSeconds: 30,
    },
  ];
};

/**
 * Util function to create logs according to server
 * @param inGameDate - current in game date
 * @param ramLoadHistory - history of ram loads
 * @param phaseTwoConfig - phase two config
 * @returns - monitoring logs
 */
export const createLog = (
  inGameDate: Date,
  ramLoadHistory: RamLoadHistory[],
  phaseTwoConfig: PhaseTwoConfigProps
): MonitoringLog[] => {
  let logToReturn: MonitoringLog[] = [];
  const currentDayRamLoad = [
    ...ramLoadHistory.filter(
      (ramLoad) =>
        new Date(ramLoad.date).getDate() === new Date(inGameDate).getDate()
    ),
  ].reverse();
  for (let ramIndex = 0; ramIndex <= currentDayRamLoad.length - 1; ramIndex++) {
    const loadInPercent = currentDayRamLoad[ramIndex].load / 100;
    if (loadInPercent >= phaseTwoConfig.ramConfig.full) {
      logToReturn.push({
        date: inGameDate,
        message: LogMessages.RAM_CRITICAL,
        type: LogType.RAM_CRITICAL,
      });
      break;
    } else if (loadInPercent >= phaseTwoConfig.ramConfig.warning) {
      logToReturn.push({
        date: inGameDate,
        message: LogMessages.RAM_WARNING,
        type: LogType.RAM_WARNING,
      });
      break;
    }
  }

  return logToReturn;
};
