import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function PublicCloud() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Public Cloud/öffentliche Cloud</p>
        <p>
          Der Cloud-Anbieter stellt ein Netzwerk zur Verfügung, welches sich
          mehrere Nutzer teilen. Dieses Netzwerk ist direkt über das Internet
          erreichbar.
        </p>
        <p>Das bedeutet: Alle Nutzerdaten liegen zusammen.</p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/privatecloud" className={styles.link}>
            <li>Private Cloud</li>
          </Link>
          <Link to="/browser/wiki/hybridcloud" className={styles.link}>
            <li>Hybrid Cloud</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default PublicCloud;
