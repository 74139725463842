import React from "react";
import { Outlet } from "react-router-dom";
import Dashboard from "../../components/Dashboard";
import BrowserLayout from "./BrowserLayout";
import { UserContext } from "../../context/state";

/**
 *
 * @returns the layout of the Browserwindow (sidebar, header)
 */
function Browser() {
  const isOpen = React.useContext(UserContext);

  return (
    <div>
      {isOpen?.dashboardOpened ? <Dashboard /> : null}
      <BrowserLayout>
        {/*Outlet allows nested UI to show up when child routes are rendered. it will render a child index route or nothing if there is no index route.*/}
        <Outlet />
      </BrowserLayout>
    </div>
  );
}

export default Browser;
