import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function SelfHosted() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Self Hosted</p>
        <p>
          Self-Hosted oder auch selbst gehosted bedeutet, dass der Server intern
          im eigenen Firmengebäude steht. Dieser Server muss 24/7 (= rund um die
          Uhr) angeschaltet sein. Sobald der Strom ausfällt, ist der Server
          nicht erreichbar.
          </p><p>
          Die Hardware und die Software müssen eigenständig gewartet und
          gepflegt werden. Durch die personalisierten Einrichtungen können alle
          Anforderungen selbst angepasst werden. Die Sicherheit der Daten muss
          auch eigenständig festgelegt werden. Diese Variante gewährleistet die
          volle Kontrolle über die Daten.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/hosting" className={styles.link}>
            <li>Hosting-Anbieter</li>
          </Link>
          <Link to="/browser/wiki/cloud" className={styles.link}>
            <li>Cloud-Anbieter</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default SelfHosted;
