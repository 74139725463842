import React, { useCallback } from "react";
import { useContext } from "react";
import { useState } from "react";
import { TimeContext, UserContext } from "../context/state";
import styles from "./styles/Dashboard.module.css";
import { motion } from "framer-motion";
import { useEffect } from "react";
import {
  getCorrectDescription,
  ISolution,
  IStory,
  Phase,
  StatEffectType,
  StatusTime,
  UpgradeText,
} from "../api/api";
import DecisionDetailView from "./DecisionDetailView";
import { ReactComponent as KalenderIcon } from "../assets/icons/Kalender.svg";
import dayjs from "dayjs";
import CountdownButton from "./CountdownButton";
import { ReactComponent as IncomeUp } from "../assets/icons/Income_higher.svg";
import { ReactComponent as IncomeDown } from "../assets/icons/Income_lower.svg";
import { ReactComponent as UserUp } from "../assets/icons/User_rate_higher.svg";
import { ReactComponent as UserDown } from "../assets/icons/User_rate_lower.svg";
import { ReactComponent as UpgradeIcon } from "../assets/icons/Task.svg";

function Dashboard() {
  const [solutions, setSolutions] = useState<ISolution[]>([]);
  const [story, setStory] = useState<IStory>();
  const [selectedSolution, setSelectedSolution] = useState<ISolution[]>([]);

  const {
    gameState,
    isAuth,
    openDecisionDetail,
    selectedIncident,
    openIncidentsModal,
    gameStateRef,
  } = useContext(UserContext);

  const { timeRef } = useContext(TimeContext);

  /**
   * useEffect to rerender on solutions added
   */
  useEffect(() => {
    if (!isAuth) return;
    let solutionsArray: ISolution[] = gameState.story.solutions || [];
    setStory(gameState.story);
    setSolutions(solutionsArray);
  }, [gameState, isAuth]);

  //animate dashboard background
  const dashboard = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
      },
    },
    hidden: {
      opacity: 0,
      x: 400,
    },
  };

  //animate list items
  const item = {
    visible: (custom: number) => ({
      opacity: 1,
      x: 0,
      transition: { delay: custom * 0.3 },
    }),
    hidden: { opacity: 0, x: -80 },
  };

  const showDetailView = (p: string) => {
    setSelectedSolution(solutions.filter((solution) => solution.title === p));
    openDecisionDetail();
  };

  /**
   * Helper method to get correct icon
   * @param statEffectType - type of status effect
   * @param firstParaemter - first parameter
   * @param secondParameter - second parameter
   * @returns - correct jsx icon
   */
  const getCorrectStatusIcon = (
    statEffectType: StatEffectType,
    firstParaemter: any,
    secondParameter: any
  ) => {
    if (
      statEffectType === StatEffectType.USER_RATE ||
      statEffectType === StatEffectType.USER ||
      statEffectType === StatEffectType.RATING_MOD
    ) {
      if (secondParameter >= 1) {
        return <UserUp />;
      } else {
        return <UserDown />;
      }
    } else if (
      statEffectType === StatEffectType.MONEY_RATE ||
      statEffectType === StatEffectType.MONEY
    ) {
      if (secondParameter >= 1) {
        return <IncomeUp />;
      } else {
        return <IncomeDown />;
      }
    } else if (statEffectType === StatEffectType.RATING_SET) {
      if (firstParaemter < 3) {
        return <UserDown />;
      } else {
        return <UserUp />;
      }
    }
  };

  /**
   * Helper method to get the difference between start of phase two until now (In-Game Date)
   * @returns - number of days
   */
  const getDayDifference = (dayToCalc: Date): number => {
    return dayjs(dayToCalc).diff(
      dayjs(new Date(gameState.phaseTwoState.startDate)),
      "day"
    );
  };

  /**
   * Helper method to calculate the remaining time in seconds of imminent Incident
   * @returns - number of seconds
   */
  const calcSec = useCallback(
    (statusTime: StatusTime) => {
      const dayDifference = Math.max(
        0,
        dayjs(new Date(statusTime.inGameDate)).diff(
          dayjs(gameStateRef.current!.currentDate),
          "day"
        )
      );
      if (dayDifference <= 0) {
        return statusTime.inGameTime - timeRef.current!;
      }

      return dayDifference * 10 + statusTime.inGameTime - timeRef.current!;
    },
    //eslint-disable-next-line
    [timeRef.current, gameStateRef.current]
  );

  return (
    <>
      <motion.div
        className={styles.container}
        initial="hidden"
        animate="visible"
        variants={dashboard}
      >
        {gameState.phase === Phase.ONE ? (
          <>
            <h1 className={styles.header}>Aufgaben</h1>
            <div className={styles.question}>{story?.title}</div>
            <div className={styles.buttonContainer}>
              {selectedIncident && (
                <motion.div
                  variants={item}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className={styles.button}
                  onClick={() => openIncidentsModal(true)}
                >
                  {selectedIncident.title}
                </motion.div>
              )}
              {solutions?.map((index, id) => {
                if (gameState.budget - index.solutionCostMoney < 0) {
                  return (
                    <motion.div
                      custom={id + 1}
                      key={id}
                      variants={item}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      className={styles.buttonDisabled}
                    >
                      {index.title}
                    </motion.div>
                  );
                } else {
                  return (
                    <motion.div
                      custom={id + 1}
                      key={id}
                      variants={item}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      className={styles.button}
                      onClick={() => showDetailView(index.title)}
                    >
                      {index.title}
                      <span className={styles.tooltip}>
                        {index.description}
                      </span>
                    </motion.div>
                  );
                }
              })}
            </div>
          </>
        ) : (
          <>
            <h1 className={styles.header}>Dashboard</h1>
            <div className={styles.dayContainer}>
              <KalenderIcon className={styles.kalenderIcon} />
              <p>Tag:</p>
              <p>{getDayDifference(new Date(gameState.currentDate))}</p>
            </div>
            {selectedIncident && (
              <div className={styles.incidentContainer}>
                <h5>Drohender Zwischenfall:</h5>
                <CountdownButton
                  startTime={selectedIncident.reactionTime}
                  timeLeft={calcSec(selectedIncident.timeUntilActive)}
                  title={selectedIncident.title}
                  onClick={() => openIncidentsModal(true)}
                />
              </div>
            )}
            {gameState.phaseTwoState.statusToRevert &&
              gameState.phaseTwoState.statusToRevert.length > 0 && (
                <div className={styles.statusContainer}>
                  <h5>Aktive Vorgänge:</h5>
                  {gameState.phaseTwoState.statusToRevert.map(
                    (statusEffect) => {
                      const calcedTime = calcSec(
                        statusEffect.duration || {
                          inGameDate: gameState.currentDate,
                          inGameTime: timeRef.current!,
                        }
                      );
                      const statusDescription = getCorrectDescription(
                        statusEffect.type,
                        statusEffect.firstParameter,
                        statusEffect.secondParameter
                      );
                      return (
                        <div className={styles.statusWrapper}>
                          {calcedTime &&
                          typeof calcedTime === "number" &&
                          calcedTime <= 0 ? (
                            <></>
                          ) : (
                            <>
                              {getCorrectStatusIcon(
                                statusEffect.type,
                                statusEffect.firstParameter,
                                statusEffect.secondParameter
                              )}
                              <CountdownButton
                                startTime={statusEffect.durationInSeconds}
                                timeLeft={calcedTime}
                                title={statusEffect.title}
                                tooltip={statusDescription}
                              />
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )}

            {gameState.phaseTwoState.pendingUpgrades &&
              gameState.phaseTwoState.pendingUpgrades.length > 0 && (
                <div className={styles.statusContainer}>
                  <h5>Geplante Upgrades:</h5>
                  {gameState.phaseTwoState.pendingUpgrades.map(
                    (pendingUpgrade) => {
                      const calcedTime = calcSec(
                        pendingUpgrade.time || {
                          inGameDate: gameState.currentDate,
                          inGameTime: timeRef.current!,
                        }
                      );

                      return (
                        <div className={styles.statusWrapper}>
                          {calcedTime &&
                          typeof calcedTime === "number" &&
                          calcedTime <= 0 ? (
                            <></>
                          ) : (
                            <>
                              <UpgradeIcon />
                              <CountdownButton
                                timeLeft={calcedTime}
                                title={UpgradeText[pendingUpgrade.upgrade]}
                              />
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            {gameState.phaseTwoState.permanentStatus &&
              gameState.phaseTwoState.permanentStatus.length > 0 && (
                <div className={styles.statusContainer}>
                  <h5>Permanente Status-Effekte:</h5>
                  {gameState.phaseTwoState.permanentStatus.map(
                    (statusEffect) => {
                      const calcedTime = calcSec(
                        statusEffect.duration || {
                          inGameDate: gameState.currentDate,
                          inGameTime: timeRef.current!,
                        }
                      );
                      const statusDescription = getCorrectDescription(
                        statusEffect.type,
                        statusEffect.firstParameter,
                        statusEffect.secondParameter
                      );
                      return (
                        <div className={styles.statusWrapper}>
                          {calcedTime &&
                          typeof calcedTime === "number" &&
                          calcedTime <= 0 ? (
                            <></>
                          ) : (
                            <>
                              {getCorrectStatusIcon(
                                statusEffect.type,
                                statusEffect.firstParameter,
                                statusEffect.secondParameter
                              )}
                              <CountdownButton
                                timeLeft={calcedTime}
                                title={statusEffect.title}
                                tooltip={statusDescription}
                              />
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )}
          </>
        )}
      </motion.div>

      <DecisionDetailView solution={selectedSolution} />
    </>
  );
}

export default Dashboard;
