import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function SharedServer() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Virtuelle Maschine</p>
        <p>
          Der Hosting-Anbieter stellt eine virtuelle Maschine bereit, die auf
          einem Hardware-Server (Hypervisor) läuft, auf dem auch virtuelle
          Maschinen (VMs) anderer Kunden laufen können.
        </p>
        <p>
          Bei mangelnden Sicherheitsvorkehrungen ist es theoretisch möglich, aus
          einer VM auszubrechen und Daten aus dem Hypervisor und anderen VMs
          auszulesen oder sogar die Kontrolle über diese zu übernehmen.
        </p>
        <p>
          Andererseits ist die Erweiterung bzw. Aufrüstung einer virtuellen
          Maschine in der Regel problemlos möglich, da einfach neue
          virtualisierte Hardware-Ressourcen dazugescchaltet werden können.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/dedicatedserver" className={styles.link}>
            <li>Dedicated Server</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default SharedServer;
