import React from "react";
import styles from "./styles/Desktop.module.css";
import { Link } from "react-router-dom";
import Dashboard from "../../components/Dashboard";
import { UserContext } from "../../context/state";
import { ReadMe01 } from "../../assets/Icons";
import { Phase } from "../../api/api";

/**
 *
 * @returns desktop screen with readme link
 */
function Desktop() {
  const { dashboardOpened, gameState } = React.useContext(UserContext);
  return (
    <div className={styles.desktop}>
      {dashboardOpened ? <Dashboard /> : null}
      <div className={styles.instructionContainer}>
        <div className={styles.instruction}>
          <Link to="/browser/readme01">
            <img
              src={ReadMe01}
              width="100px"
              height="auto"
              className={styles.readMe}
              alt="Anleitung"
            />
          </Link>
          <div className={styles.subtext}>Anleitung</div>
        </div>

        {gameState.phase === Phase.TWO && (
          <div className={styles.instruction}>
            <Link to="/browser/readme02">
              <img
                src={ReadMe01}
                width="100px"
                height="auto"
                className={styles.readMeTwo}
                alt="Anleitung Phase Zwei"
              />
            </Link>
            <div className={styles.subtextTwo}>Anleitung Phase Zwei</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Desktop;
