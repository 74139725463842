import { uid } from "uid";

/*Incidents Interfaces */
export interface Response {
  id: string;
  title: string;
  description: string;
  costTime: number;
  costMoney: number;
  score: number;
}

export interface Prereq {
  typeString: string;
  trigger_id: string;
  seconds?: number;
  parameter?: string[];
  duration?: number;
}

export interface IIncident {
  id: string;
  title: string;
  description: string;
  object: string;
  reactionTime: number;
  noNewUsersWhilePending?: boolean;
  responses: Response[];
  prereqs?: Prereq[];
}

/*Email Interfaces */
export interface IEmail {
  _id: string;
  activeStoryNode: string;
  Header: string;
  Sender: string;
  Receiver: string;
  Date: string;
  IsSpam: boolean;
  revealStorySolution: string;
  ContentFile: string;
  AnswerId: number;
  answers: Array<any>;
}

/*Story Interfaces */
export interface IStory {
  id: string;
  description: string;
  title: string;
  solutions?: ISolution[];
  successor?: Array<any>;
}

export interface ISolution {
  id: string;
  title: string;
  description: string;
  longDescription: string;
  solutionBonusFortification?: number;
  solutionCostMoney: number;
  solutionCostTime: number;
  solutionTrait?: string;
  successor?: Array<any>;
}

/*Websites Interfaces */
export interface IWebsite {
  _id: string;
  name: string;
  identifier: string;
  revealed: boolean;
}

/*Chats Interfaces */
export interface IActiveChat {
  ContactName: string;
  ChatJSONPath: string;
  chatId: string;
  chatObj: IMessages;
}

export interface IMessages {
  Messages: IMessage[];
}

export interface IMessage {
  Sender: string;
  Content: string;
  IsAnswer: boolean;
  Filter?: Array<string>;
  AddsFilter?: Array<string>;
  numberFilter?: number;
  revealStorySolution?: string;
}
export interface IChat {
  _id: string;
  activeStoryNode: string;
  activeChats: IActiveChat[];
}

export interface RamLoadHistory {
  date: Date;
  load: number;
}

/**
 * Enumeration of different types of logs
 */
export enum LogType {
  RAM_WARNING = "RAM_WARNING",
  RAM_CRITICAL = "RAM_CRITICAL",
}

/**
 * Interface for amount of users at given date;
 */
export interface UserAmountHistory {
  date: Date;
  amount: number;
}

/**
 * Interface for logs of monitoring
 */
export interface MonitoringLog {
  date: Date;
  message: string;
  type: LogType;
}

/**
 * Log Messages for {@link LogType}
 */
export const LogMessages: Record<LogType, string> = {
  [LogType.RAM_WARNING]:
    "Warnung: Systemlast liegt über 80%. Leistungsupgrade empfohlen.",
  [LogType.RAM_CRITICAL]:
    "Kritisch: Systemlast liegt über 90%. Leistungsupgrade dringend empfohlen.",
};

/**
 * Interface for current state of server in game
 */
export interface ServerProps {
  provider: string;
  hosting: string;
  maxStorage: number;
  maxCPU: number;
  maxRam: number;
  ramWarningThresholdReached: boolean;
  storageWarningThresholdReached: boolean;
  ramCritical: boolean;
  storageUsed: number;
  ramUsed: number;
  cpuLoad: number;
  ramLoadHistory: RamLoadHistory[];
  userAmountHistory: UserAmountHistory[];
  logHistory: MonitoringLog[];
}

export type PendingResponse = {
  id: string;
  time: StatusTime;
  response: IncidentResponse;
};

export type PendingEventCard = {
  card: EventIncidentsUnionType;
  executionTime: StatusTime;
};
export interface PhaseTwoState {
  startDate: Date;
  usersPerCycle: number;
  incomePerCycle: number;
  currentUsers: number;
  currentServer: ServerProps;
  statusToDispatch: StatusEffect<any>[];
  statusToRevert: StatusEffect<any>[];
  statusToDisplay: StatusEffect<any>[];
  permanentStatus: StatusEffect<any>[];

  pendingUpgrades: {
    id: string;
    time: StatusTime;
    upgrade: ServerUpgrade;
  }[];
  pendingResponses: PendingResponse[];
  pendingEventCards: PendingEventCard[];
  ratings: number[];
  ratingToBeAdded: number;
  eventPool: EventIncidentsUnionType[];
  imminentIncidents: Incident[];
}

export interface PhaseOneState {
  decisions: number;
  currentDecision: number;
}

export interface GameState {
  emails: IEmail[];
  chats: IChat[];
  websites: IWebsite[];
  story: IStory;
  phase: Phase;
  budget: number;
  currentDate: Date;
  phaseOneState: PhaseOneState;
  phaseTwoState: PhaseTwoState;
  gameConfigs: {
    phaseOne: PhaseOneConfigProps;
    phaseTwo: PhaseTwoConfigProps;
  };
}

/**
 * Added a constant where general configuration variables
 * can be defined for the Game Client
 */
export const GeneralClientConfig = {
  toastLengthInMs: 5000,
};

export interface PhaseOneConfigProps {
  budget: number; // The start budget of the player
  decisions: number; // the number of decisions the game has
  currentDecision: number; // the number of decision the player is currently on
  phase: Phase.ONE; // Player is on Phase ONE
  startDate: Date;
}

export interface PhaseTwoConfigProps {
  startUsers: number;
  startRating: number;
  incomePerUser: number;
  userRateMultipliedMod: number;
  storageConfig: StorageConfig;
  ramConfig: RamConfig;
  ratingUserLeaveThreshold: number;
  ratingStorageFull: number;
  ratingUsersCantJoin: number;
  ratingRamFull: number;
}

export interface StorageConfig {
  startStorage: number;
  storagePerUser: number;
  upgradeCostSmall: number;
  upgradeAmountSmall: number;
  upgradeCostBig: number;
  upgradeAmountBig: number;
  upgradeDuration: number;
  warning: number;
}

/**
 * Interface for the config of RAM
 */
export interface RamConfig {
  startRam: number;
  ramPerUser: number;
  upgradeCostSmall: number;
  upgradeFactorSmall: number;
  upgradeCostBig: number;
  upgradeFactorBig: number;
  upgradeDuration: number;
  warning: number;
  full: number;
}

/**
 * Enumeration for current Phase of Game
 */
export enum Phase {
  ONE = "ONE",
  ONE_END = "ONE_END",
  TWO = "TWO",
}

/**
 * Different kind of Status Effects that can be applied to the game
 */
export enum StatEffectType {
  UNEVALUATED_TYPE = "UNEVALUATED_TYPE", // this needs to be first so all invalid json entries will default to this
  USER = "USER",
  MONEY = "MONEY",
  USER_RATE = "USER_RATE",
  MONEY_RATE = "MONEY_RATE",
  RATING_MOD = "RATING_MOD",
  RATING_SET = "RATING_SET",
  DEFEAT = "DEFEAT",
  VICTORY = "VICTORY",
  //SYSTEM_DOWN
}

/**
 * Possible parameters for {@link StatEffectType}
 */
export type StatusFirstParameter = {
  [StatEffectType.MONEY]: "+" | "*";
  [StatEffectType.USER_RATE]: "*";
  [StatEffectType.USER]: "+" | "*";
  [StatEffectType.RATING_MOD]: "+";
  [StatEffectType.MONEY_RATE]: "+" | "*";
  [StatEffectType.RATING_SET]: number;
  [StatEffectType.DEFEAT]: string;
  [StatEffectType.VICTORY]: string;
  [StatEffectType.UNEVALUATED_TYPE]: undefined;
};

/**
 * Descriptions for {@link StatEffectType}
 */
export enum StatusDescription {
  MONEY = "Budget",
  USER_RATE = "User Rate",
  USER = "Anzahl der User",
  RATING_MOD = " Bewertungsmodifikator",
  MONEY_RATE = "Einfluss auf das Budget",
  RATING_SET = "Neue Bewertung",
  DEFEAT = "Game Over",
  VICTORY = "Du hast gewonnen!",
  UNEVALUATED_TYPE = "",
}

/**
 * Possible parameters for {@link StatEffectType}
 */
export type StatusSecondParameter = {
  [StatEffectType.MONEY]: number;
  [StatEffectType.USER_RATE]: number;
  [StatEffectType.USER]: number;
  [StatEffectType.MONEY_RATE]: number;
  [StatEffectType.RATING_MOD]: number;
  [StatEffectType.RATING_SET]: undefined;
  [StatEffectType.DEFEAT]: undefined;
  [StatEffectType.VICTORY]: undefined;
  [StatEffectType.UNEVALUATED_TYPE]: undefined;
};

export interface StatusTime {
  inGameDate: Date;
  inGameTime: number;
}

/**
 * Represents a single effect that applies to the game
 */
export interface StatusEffect<T extends StatEffectType> {
  id: string;
  type: T;
  firstParameter: StatusFirstParameter[T];
  secondParameter: StatusSecondParameter[T];
  timeUntilActive: StatusTime | null;
  duration: StatusTime | null;
  durationInSeconds?: number;
  timeUntilActiveInSeconds?: number;
  title: string;
  handled: boolean;
}

/**
 * Gets the correct description for a {@link StatusEffect}
 * @param statEffectType  - the type of the {@link StatusEffect}
 * @param firstParaemter - the first parameter of the {@link StatusEffect}
 * @param secondParameter - the second parameter of the {@link StatusEffect}
 * @returns - the correct description for the {@link StatusEffect}
 */
export const getCorrectDescription = (
  statEffectType: StatEffectType,
  firstParaemter: any,
  secondParameter: any
): string => {
  if (statEffectType === StatEffectType.USER_RATE) {
    if (secondParameter >= 1) {
      return "User Wachstumsrate erhöht";
    } else {
      return "User Wachstumsrate verringert";
    }
  } else if (statEffectType === StatEffectType.USER) {
    if (secondParameter >= 1) {
      return "User Anzahl erhöht";
    } else {
      return "User Anzahl verringert";
    }
  } else if (
    statEffectType === StatEffectType.MONEY_RATE ||
    statEffectType === StatEffectType.MONEY
  ) {
    if (secondParameter >= 1) {
      return "Budget erhöht";
    } else {
      return "Budget verringert";
    }
  } else if (statEffectType === StatEffectType.RATING_MOD) {
    if (secondParameter >= 1) {
      return "Bewertung erhöht";
    } else {
      return "Bewertung verringert";
    }
  } else if (statEffectType === StatEffectType.RATING_SET) {
    if (firstParaemter < 3) {
      return "Bewertung verringert";
    } else {
      return "Bewertung erhäht";
    }
  }
  return "";
};

/**
 * Helper function to create an instance of a status effect
 * @param type - type of the effect
 * @param firstParaemter - first parameter of the effect
 * @param parameters - second parameter of the effect
 * @param timeUntilActive - time before the effect is active
 * @param duration - duration of the effect
 * @param title - title of the effect
 * @returns - {@link StatusEffect}
 */
export const createStatusEffect = <T extends StatEffectType>(
  type: T,
  firstParameter: StatusFirstParameter[T],
  secondParameter: StatusSecondParameter[T],
  timeUntilActive: StatusTime | null,
  duration: StatusTime | null,
  title: string
): StatusEffect<T> => ({
  id: uid(),
  type,
  firstParameter,
  secondParameter,
  timeUntilActive,
  duration,
  title,
  handled: false,
});

/**
 * Enumeration for the different types of pre requisites for events and incidents
 */
export enum PrereqType {
  UNEVALUATED_TYPE = "UNEVALUATED_TYPE",
  EVENT_CARD = "EVENT_CARD",
  TRIGGER = "TRIGGER",
  MONEY = "MONEY",
  USERS = "USERS",
}

/**
 * Interface for the prerequisits of an event card
 */
export interface EventCardPrereq {
  type: PrereqType;
  trigger_id: string;
  seconds: number;
  probability: number;
  fired: boolean;
}

/**
 * Interface for the event card that will be extended
 * by event and incident interface
 */
export interface EventCard {
  _id: string;
  gameId: string;
  title: string;
  description: string;
  perpetual?: boolean;
  prereqs: EventCardPrereq[];
  valid: boolean;
}

export interface IncidentResponse {
  _id: string;
  gameId: string;
  title: string;
  description: string;
  costTime: number;
  costMoney: number;
  score: number;
  consequences: StatusEffect<any>[];
}

export interface Incident extends EventCard {
  reactionTime: number;
  responses: IncidentResponse[];
  noNewUsersWhilePending: boolean;
  object?: string;
}

export type ImminentIncident = Incident & {
  timeUntilActive: StatusTime;
  startTime: StatusTime;
};

export interface GameEvent extends EventCard {
  effects: StatusEffect<any>[];
  emailId: string;
}

export interface Incidents {
  incidents: Incident[];
  events: GameEvent[];
}

export type EventIncidentsUnionType = GameEvent | Incident;

/**
 * Base configuration for phase ONE of pictureIT
 */
export const PhaseOneStartConfig: PhaseOneConfigProps = {
  budget: 13,
  decisions: 8,
  currentDecision: 1,
  phase: Phase.ONE,
  startDate: new Date(Date.parse("2025-08-18")),
};

/**
 * Base Configuration for phase TWO of pictureIT;
 */
export const PhaseTwoStartConfig: PhaseTwoConfigProps = {
  startRating: 3,
  startUsers: 0,
  incomePerUser: 2,
  userRateMultipliedMod: 1,
  storageConfig: {
    startStorage: 1000,
    storagePerUser: 10,
    upgradeCostSmall: 10,
    upgradeAmountSmall: 100,
    upgradeCostBig: 90,
    upgradeAmountBig: 1000,
    upgradeDuration: 3,
    warning: 0.8,
  },
  ramConfig: {
    startRam: 8,
    ramPerUser: 0.6,
    upgradeCostSmall: 40,
    upgradeFactorSmall: 2,
    upgradeCostBig: 80,
    upgradeFactorBig: 4,
    upgradeDuration: 10,
    warning: 0.8,
    full: 0.9,
  },
  ratingUserLeaveThreshold: 2.9,
  ratingStorageFull: 1,
  ratingRamFull: 2,
  ratingUsersCantJoin: 1,
};

/**
 * Helper function to return an empty {@link GameState}
 * @returns new Game state
 */
export const createEmptyGameState = (): GameState => ({
  emails: [],
  chats: [],
  websites: [],
  story: {
    id: "",
    description: "",
    successor: [],
    solutions: [],
    title: "",
  },
  phase: Phase.ONE,
  budget: 0,
  currentDate: new Date(),
  phaseOneState: createEmptyPhaseOneState(),
  phaseTwoState: createEmptyPhaseTwoState(),
  gameConfigs: {
    phaseOne: { ...PhaseOneStartConfig },
    phaseTwo: { ...PhaseTwoStartConfig },
  },
});

/**
 * Helper function to create empty phase one state
 * @returns - A gamestate specific to phase one
 */
export const createEmptyPhaseOneState = (): PhaseOneState => ({
  decisions: 0,
  currentDecision: 0,
});

/**
 * Helper function to create empty phase two state
 * @returns - A gamestate specific to phase two
 */
export const createEmptyPhaseTwoState = (): PhaseTwoState => ({
  startDate: new Date(),
  usersPerCycle: 0,
  incomePerCycle: 0,
  currentUsers: 0,
  currentServer: {
    provider: "",
    hosting: "",
    maxStorage: 0,
    maxCPU: 0,
    maxRam: 0,
    storageUsed: 0,
    ramWarningThresholdReached: false,
    storageWarningThresholdReached: false,
    ramUsed: 0,
    cpuLoad: 0,
    ramLoadHistory: [],
    userAmountHistory: [],
    logHistory: [],
    ramCritical: false,
  },
  statusToDispatch: [],
  statusToRevert: [],
  permanentStatus: [],
  pendingUpgrades: [],
  pendingResponses: [],
  pendingEventCards: [],
  ratings: [],
  ratingToBeAdded: 0,
  eventPool: [],
  imminentIncidents: [],
  statusToDisplay: [],
});

/**
 * Enumeration for ServerUpgrades
 */
export enum ServerUpgrade {
  STORAGE_SMALL = "STORAGE_SMALL",
  STORAGE_BIG = "STORAGE_BIG",
  RAM_SMALL = "RAM_SMALL",
  RAM_BIG = "RAM_BIG",
}

/**
 * Text for enum ServerUpgrade
 */
export const UpgradeText = {
  STORAGE_SMALL: "Speicherupgrade Klein",
  STORAGE_BIG: "Speicherupgrade Groß",
  RAM_SMALL: "Leistungsupgrade Klein",
  RAM_BIG: "Leistungsupgrade Groß",
};

/**
 * start game and get cookie
 * @returns
 */
export async function getNewUser() {
  const data = {
    name: null,
    story: null,
  };

  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/users/new`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response.status;
  } catch (error) {
    console.error(error);
  }
}

/**
 * POST Method to dispatch a status effect to the backend
 * @param statusEffect - status effect to dispatch
 * @returns - status code of the response
 */
export const dispatchStatusToTakeEffect = async <T extends StatEffectType>(
  statusEffect: StatusEffect<T>
): Promise<number | undefined> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/game/dispatchStatus`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        statusToDispatch: statusEffect,
      }),
    });

    return response.status;
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST Method to activate a status effect
 * @param statusEffect - status effect to activate
 * @returns - status code
 */
export const applyStatusEffect = async <T extends StatEffectType>(
  statusEffect: StatusEffect<T>
): Promise<number | undefined> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/game/applyStatus`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        statusEffectToApply: statusEffect,
      }),
    });

    return response.status;
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST Method to revert a status effect
 * @param statusEffect - status effect to revert
 * @returns - status code
 */
export const revertStatusEffect = async <T extends StatEffectType>(
  statusEffect: StatusEffect<T>
): Promise<number | undefined> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/game/revertStatus`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        statusEffectToRevert: statusEffect,
      }),
    });

    return response.status;
  } catch (error) {
    console.error(error);
  }
};

/**
 * call api to reset game
 * @returns
 */
export async function resetGame() {
  const data = {
    name: null,
    story: null,
  };

  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/game/update`;
    const response = await fetch(apiURL, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response.status;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Call Api to check, if user is registered or not
 * @returns status of response
 */
export async function getAuthState() {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/users/isRegistered`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.status;
  } catch (error) {
    console.error(error);
  }
}

/**
 * API method to send restart game to backend
 * @returns  status code
 */
export async function sendRestartGame() {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/users/restart`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.status;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Call Api to check, if user is registered or not
 * @returns status of response
 */
export async function startPhaseTwo() {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/users/startPhaseTwo`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.status;
  } catch (error) {
    console.error(error);
  }
}

/**
 * API method to execute selected {@link Incidents}
 * @returns status of response
 */
export const selectIndicentResponse = async (
  incidentResponse: IncidentResponse
): Promise<boolean> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/incidents/response`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ responseToExecute: incidentResponse }),
    });
    return response.status === 200;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * API method to execute selected {@link}
 * @returns status of response
 */
export const executePendingEventCard = async (
  cardToExecute: EventIncidentsUnionType
): Promise<boolean> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/events/execute`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ eventToExecute: cardToExecute }),
    });
    return response.status === 200;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * call api to get actual game state
 * @returns gamestate
 */
export const getGameState = async (): Promise<GameState | undefined> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/game/getState`;
    const response = await fetch(apiURL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const gameStateData = await response.json();
    return gameStateData;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Call api to unlock solution depending on props
 * @param solutionString The string of the unlocked solution
 * @returns array of solutions
 */
export async function revealStorySolution(solutionString: string) {
  const data = {
    revealSolution: solutionString,
  };

  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/story/revealSolution`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseStatus: number = response.status;
    if (responseStatus !== 200) return undefined;
    const solutionData = await response.json();
    return solutionData;
  } catch (error) {
    console.error(error);
  }
}
/**
 * API Method to upgrade server
 * @param upgrade {@link ServerUpgrade} the selected upgrade
 */
export const upgradeServer = async (
  upgrade: ServerUpgrade
): Promise<boolean> => {
  const data = {
    upgrade: upgrade,
  };

  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/server/upgrade`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseStatus: number = response.status;
    if (responseStatus === 200) return true;

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * select solution and get all details of solution
 * @param solutionString The string of the solution
 * @returns detail data of solution
 */
export async function selectSolution(solutionString: string) {
  const data = {
    selectSolution: solutionString,
  };

  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/story/selectSolution`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const solutionData = await response.json();
    return solutionData;
  } catch (error) {
    console.error(error);
  }
}
/**
 * API method when a day also referred to as cycle ends
 * @returns detail data of solution
 */
export const endCycle = async (
  currentGameState: GameState
): Promise<"cycle" | undefined> => {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/time/endCycle`;
    const response = await fetch(apiURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gameState: currentGameState,
      }),
    });
    return response.status === 200 ? "cycle" : undefined;
  } catch (error) {
    console.error(error);
  }
};

/**
 * call api to get all incidents
 * @returns array of incidents
 */
export async function getIncidentsData() {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/incidents`;
    const response = await fetch(apiURL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const incidentsData = await response.json();
    return incidentsData;
  } catch (error) {
    console.error(error);
  }
}

/**
 * call api to get all events
 * @returns array of events
 */
export async function getEventsData() {
  try {
    const apiURL = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/events`;
    const response = await fetch(apiURL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const eventsData = await response.json();
    return eventsData;
  } catch (error) {
    console.error(error);
  }
}
