import React from "react";
import { Outlet, Link } from "react-router-dom";
import { Abtrennung, Klee } from "../../../../assets/Icons";
import styles from "./styles/GreenHills.module.css";

function GreenHills() {
  return (
    <div>
      <div className={styles.header}>
        <Link to="" className={styles.link}>
          Green Hills <img src={Klee} width="30"/>
        </Link>
      </div>
      <div className={styles.subheader}>
        <Link to="server" className={styles.link}>
          Server
        </Link>
        <img src={Abtrennung} className={styles.line}/>
        <Link to="standort" className={styles.link}>
          Standort
        </Link>
        <img src={Abtrennung} className={styles.line}/>
        <Link to="" className={styles.link}>
          Support
        </Link>
      </div>
      <>
        <Outlet />
      </>
    </div>
  );
}

export default GreenHills;
