import React from "react";
import styles from "../styles/Messages.module.css";

/**
 * 
 * @returns Start page if no chat has been selected yet
 */
function ChatStartpage() {
  return <div className={styles.center}><p>Keine Konversation ausgewählt.</p></div>
}

export default ChatStartpage;
