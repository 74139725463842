import React, { useEffect } from "react";
import styles from "./styles/Chat.module.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  Close,
  AndiValkanis,
  MartinFärber,
  NiklasKortig,
  StephanieEberhardt,
} from "../../assets/Icons";
import { GameState, getGameState, IActiveChat } from "../../api/api";
import { UserContext } from "../../context/state";

type Props = {
  children: React.ReactNode;
};

const ChatLayout: React.FunctionComponent<Props> = () => {
  const context = React.useContext(UserContext);
  const navigate = useNavigate();

  //call api in external api file to get chats depending on game state
  useEffect(() => {
    if (context.isAuth) {
      (async () => {
        let gameState: GameState | undefined = await getGameState();
        if (!gameState) return;
        let activeChats = gameState.chats[0].activeChats;
        context.setChat(activeChats);
      })();
    }
  }, []);

  return (
    <div className={styles.programContainer}>
      <section className={styles.programHeader}>
        <h1 className={styles.programHeading}>Chat</h1>
        <Link to="/">
          <img src={Close} alt="Closebutton" width="70px" />
        </Link>
      </section>
      <section className={styles.programBody}>
        <div className={styles.programFilter}>
          <h1>Kontakte</h1>
          {context.chat.map((chatToActivate: IActiveChat, id) => {
            let images: string | undefined;

            switch (chatToActivate.ContactName) {
              case "Martin Färber":
                images = MartinFärber;
                break;
              case "Andi Valkanis":
                images = AndiValkanis;
                break;
              case "Stephanie Eberhardt":
                images = StephanieEberhardt;
                break;
              case "Niklas Kortig":
                images = NiklasKortig;
                break;
              default:
                break;
            }

            return (
              <div
                key={id}
                onClick={() => navigate(chatToActivate.chatId)}
                style={{ textDecoration: "none" }}
                className={styles.filterButton}
              >
                <div className={styles.pictureContainer}>
                  <img
                    src={images}
                    width="40px"
                    alt={chatToActivate.ContactName}
                    className={styles.pictures}
                  />
                </div>
                <p className={styles.contactName}>
                  {" "}
                  {chatToActivate.ContactName}
                </p>
              </div>
            );
          })}
        </div>
        <div className={styles.programMainComponent}>
          <Outlet />
        </div>
      </section>
    </div>
  );
};

export default ChatLayout;
