import React, { useContext } from "react";
import { Outlet } from "react-router";
import Navigator from "./subPages/Navigator";
import styles from "./styles/Layout.module.css";
import { Link } from "react-router-dom";
import { Close } from "../../assets/Icons";
import { UserContext } from "../../context/state";
import Dashboard from "../../components/Dashboard";
type MonitoringLayoutProps = {
  children: React.ReactNode;
};

const MonitoringLayout: React.FC<MonitoringLayoutProps> = () => {
  const { dashboardOpened } = useContext(UserContext);

  return (
    <div className={styles.layoutWrapper}>
      {dashboardOpened ? <Dashboard /> : null}
      <div className={styles.blueHeader}>
        <h1 className={styles.programHeading}>Monitoring</h1>
        <Link to="/">
          <img src={Close} alt="Closebutton" width="70px" />
        </Link>
      </div>
      <div className={styles.layoutContent}>
        <div className={styles.navigationContainer}>
          <Navigator />
        </div>
        <div className={styles.subpageContainer}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MonitoringLayout;
