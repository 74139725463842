import { TypeOptions } from "react-toastify";
import { LogType } from "../../api/api";

/**
 * Helper function to get options object for chartjs
 * @param labels - given labels
 * @returns - options object for chartjs
 */
export const getServerLineChartOptions = (labels: string[]): any => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
      },
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val: any, index: number) {
            // Hide every 2nd tick label
            return index === 0 || index === labels.length - 1
              ? labels[index]
              : "";
          },
          color: "blue",
        },
      },
    },
  };
};
/**
 * Helper function to get options object for chartjs
 * @param labels - given labels
 * @returns - options object for chartjs
 */
export const getUserLineChartOptions = (labels: string[], maxAmountOfUsersThatCanRegister: number): any => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: maxAmountOfUsersThatCanRegister
      },
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val: any, index: number) {
            // Hide every 2nd tick label
            return index === 0 || index === labels.length - 1
              ? labels[index]
              : "";
          },
          color: "blue",
        },
      },
    },
  };
};
/**
 * Helper function to get options object for chartjs
 * @param labels - given labels
 * @returns - options object for chartjs
 */
export const getStoragePieChartOptions = (): any => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: false,
      },
    },
  };
};

/**
 * Helper function to get data object for chartjs
 * @param labels - given labels
 * @param data - given data
 * @returns - data object for chartjs
 */
export const createServerLineData = (
  labels: string[],
  data: number[]
): any => ({
  labels,
  datasets: [
    {
      fill: true,
      label: "Serverauslastung",
      data: data,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
});
/**
 * Helper function to get data object for chartjs
 * @param labels - given labels
 * @param data - given data
 * @returns - data object for chartjs
 */
export const createUserLineData = (labels: string[], data: number[]): any => ({
  labels,
  datasets: [
    {
      fill: true,
      data: data,
      labels: [],
      label: "Benutzerzahlen",
      lineTension: 0.3,
      backgroundColor: "rgba(78, 115, 223, 0.05)",
      borderColor: "rgba(78, 115, 223, 1)",
      pointRadius: 3,
      pointBackgroundColor: "rgba(78, 115, 223, 1)",
      pointBorderColor: "rgba(78, 115, 223, 1)",
      pointHoverRadius: 3,
      pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
      pointHoverBorderColor: "rgba(78, 115, 223, 1)",
      pointHitRadius: 10,
      pointBorderWidth: 2,
    },
  ],
});

/**
 * Helper function to get data object for chartjs
 * @param labels - given labels
 * @param data - given data
 * @returns - data object for chartjs
 */
export const createStoragePieData = (
  maxStorage: number,
  occupiedStorage: number
): any => ({
  labels: ["Frei", "Belegt"],
  datasets: [
    {
      data: [maxStorage - occupiedStorage, occupiedStorage],
      backgroundColor: ["rgba(170, 170, 170, 1)", "rgba(45, 115, 199, 1)"],
      borderColor: ["rgba(170, 170, 170, 1)", "rgba(45, 115, 199, 1)"],
      borderWidth: 1,
    },
  ],
});

/**
 * Helper function to get corresponding toast type for log type
 * @param logType - given log type
 * @returns - appropriate toast type
 */
export const convertLogTypeToToastType = (logType: LogType): TypeOptions => {
  switch (logType) {
    case LogType.RAM_WARNING:
    case LogType.RAM_CRITICAL:
      return "warning";
    default:
      return "info";
  }
};
