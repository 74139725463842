import React, { useMemo, useState } from "react";
import { Routes, Route } from "react-router";
import { Chat, Desktop, Browser, Email, Monitoring } from "./pages";
import {
  StartPage,
  Wiki,
  Anleitung,
  PictureIT,
} from "./pages/Browser/subpages";
import Messages from "./pages/Chats/subpages/Messages";
import {
  StartScreen,
  IncidentsModal,
  GameOverModal,
  Dashboard,
  Menu,
  Achievements,
  Tutorial,
  VictoryScreen,
} from "./components/index";
import ListEmails from "./pages/Emails/subpages/ListEmails";
import EmailDetail from "./pages/Emails/subpages/EmailDetail";
import {
  CloudAnbieter,
  DedicatedServer,
  HostingAnbieter,
  HybridCloud,
  InfrastructureAsAService,
  PlatformAsAService,
  PrivateCloud,
  PublicCloud,
  SelfHosted,
  SharedServer,
  SoftwareAsAService,
  ThirdPartyAuthentifizierung,
  ZweiFaktorAuthentifizierung,
  Verschluesselung,
  Schutzziele,
  Sicherheitscheck,
  Firewall,
  ISOIEC,
  Pentest,
  AbuseCase,
  Overview,
  DigitaleForensik,
} from "./pages/Browser/subpages/Wiki";
import SpamEmails from "./pages/Emails/subpages/SpamEmails";
import {
  MBIImpressum,
  MBIServer,
  MBIStandorte,
  MBIStartpage,
  MBISupport,
  MBI,
} from "./pages/Browser/subpages/MBI";
import Meso from "./pages/Browser/subpages/Meso/MesoLayout";
import { UserContext } from "./context/state";
import { getAuthState, getNewUser, Phase, startPhaseTwo } from "./api/api";
import { useEffect } from "react";
import ChatStartpage from "./pages/Chats/subpages/ChatStartpage";
import MesoStartpage from "./pages/Browser/subpages/Meso/MesoStartpage";
import MesoSupportpage from "./pages/Browser/subpages/Meso/MesoSupport";
import MesoServerpage from "./pages/Browser/subpages/Meso/MesoServer";
import MesoStandortpage from "./pages/Browser/subpages/Meso/MesoStandorte";
import MesoImpressumpage from "./pages/Browser/subpages/Meso/MesoImpressum";
import FCorp from "./pages/Browser/subpages/FCorp/FCorpLayout";
import PhaseOneEnd from "./components/PhaseOneEnd";
import GreenHills from "./pages/Browser/subpages/GreenHills/GreenHills";
import GreenHillsStartpage from "./pages/Browser/subpages/GreenHills/GreenHillsStartpage";
import GreenHillsStandorte from "./pages/Browser/subpages/GreenHills/GreenHillsStandorte";
import GreenHillsServer from "./pages/Browser/subpages/GreenHills/GreenHillsServer";
import SuspiciousEnterprise from "./pages/Browser/subpages/SuspiciousEnterprise/SuspiciousLayout";
import Credits from "./components/Credits";
import TaskbarOutlet from "./components/taskbar/TaskbarOutlet";
import OverviewPage from "./pages/Monitoring/subPages/OverviewPage";
import ServerRessourcenPage from "./pages/Monitoring/subPages/ServerRessourcenPage";
import StatisticsPage from "./pages/Monitoring/subPages/StatisticsPage";
import LogsPage from "./pages/Monitoring/subPages/LogsPage";
import AnleitungPhaseZwei from "./pages/Browser/subpages/AnleitungPhaseZwei";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath?: string;
  outlet: JSX.Element;
  checkedAuth: boolean;
};

/**
 * create protected Routes
 * @param isAuthenticated
 * @param outlet
 * @returns the pages that are displayed depending on the authentication status of the User
 */
export function ProtectedRoute({
  isAuthenticated,
  outlet,
  checkedAuth = false,
}: ProtectedRouteProps) {
  const { fetchGameState, setIsAuth } = React.useContext(UserContext);

  //create a new User when the player clicks on 'Start Game'
  const handleCookie = async () => {
    await getNewUser();
    await fetchGameState();
    setIsAuth(true);
  };

  //if the user is authenticated, all pages are displayed. If not, the start screen is displayed
  if (isAuthenticated) {
    return outlet;
  } else if (checkedAuth)
    return (
      <TaskbarOutlet
        outlet={
          <>
            <Desktop />
            <StartScreen handleCookie={() => handleCookie()} />
          </>
        }
      />
    );
  else return <></>;
}

/**
 *
 * @returns (protected) routes and modals
 */
function App() {
  let context = React.useContext(UserContext);
  //test commit

  const [checkedAuth, toggleCheckedAuth] = useState<boolean>(false);

  //associate isAuthenticated variable with the state isAuth in context
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> =
    useMemo(
      (): Omit<ProtectedRouteProps, "outlet"> => ({
        isAuthenticated: context.isAuth,
        checkedAuth: checkedAuth,
      }),
      [context.isAuth, checkedAuth]
    );

  //Check if the user is registered. If yes, set IsAuth to true in context (true = user has access to protected routes).
  useEffect(() => {
    (async () => {
      let isRegistered = await getAuthState();
      if (isRegistered === 200) {
        context.setIsAuth(true);
        const localGameState = localStorage.getItem("gameState");
        if (!!localGameState) {
          context.setLocalGameState(JSON.parse(localGameState));
        } else {
          await context.fetchGameState();
        }
        if (context.gameState.phase !== Phase.TWO) {
          localStorage.removeItem("selectedIncident");
          return;
        }
        const localIncident = localStorage.getItem("selectedIncident");
        if (
          !!localIncident &&
          localIncident !== "null" &&
          localIncident !== "undefined"
        ) {
          context.setSelectedIncident(JSON.parse(localIncident));
        }
      }
      toggleCheckedAuth(true);
    })();
    // eslint-disable-next-line
  }, [context.isAuth]);

  return (
    <div style={{ textAlign: "center" }}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Desktop />} />}
            />
          }
        />
        <Route
          path="/browser"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Browser />} />}
            />
          }
        >
          {/*Nested Routes. SubPages are called*/}
          <Route path="" element={<StartPage />} />
          <Route path="startpage" element={<StartPage />} />
          <Route path="tutorial" element={<Tutorial />} />
          <Route path="wiki" element={<Wiki />}>
            <Route path="" element={<Overview />} />
            <Route path="selfhosted" element={<SelfHosted />} />
            <Route path="hosting" element={<HostingAnbieter />} />
            <Route path="cloud" element={<CloudAnbieter />} />
            <Route path="sharedserver" element={<SharedServer />} />
            <Route path="dedicatedserver" element={<DedicatedServer />} />
            <Route path="privatecloud" element={<PrivateCloud />} />
            <Route path="publiccloud" element={<PublicCloud />} />
            <Route path="hybridcloud" element={<HybridCloud />} />
            <Route path="saas" element={<SoftwareAsAService />} />
            <Route path="paas" element={<PlatformAsAService />} />
            <Route path="iaas" element={<InfrastructureAsAService />} />
            <Route
              path="zweifaktorauthentifizierung"
              element={<ZweiFaktorAuthentifizierung />}
            />
            <Route
              path="thirdpartyauthentifizierung"
              element={<ThirdPartyAuthentifizierung />}
            />
            <Route path="verschluesselung" element={<Verschluesselung />} />
            <Route path="schutzziele" element={<Schutzziele />} />
            <Route path="sicherheitscheck" element={<Sicherheitscheck />} />
            <Route path="firewall" element={<Firewall />} />
            <Route path="isoiec" element={<ISOIEC />} />
            <Route path="pentest" element={<Pentest />} />
            <Route path="digitaleforensik" element={<DigitaleForensik />} />
            <Route path="abusecase" element={<AbuseCase />} />
          </Route>
          <Route path="readme01" element={<Anleitung />} />
          <Route path="readme02" element={<AnleitungPhaseZwei />} />
          <Route path="pictureit" element={<PictureIT />} />
          <Route path="bteam" element={<SuspiciousEnterprise />} />
          <Route path="cyberp" element={<SuspiciousEnterprise />} />
          <Route path="mbi" element={<MBI />}>
            <Route path="" element={<MBIStartpage />} />
            <Route path="standorte" element={<MBIStandorte />} />
            <Route path="support" element={<MBISupport />} />
            <Route path="server" element={<MBIServer />} />
            <Route path="impressum" element={<MBIImpressum />} />
          </Route>
          <Route path="mesa" element={<Meso />}>
            <Route path="" element={<MesoStartpage />} />
            <Route path="support" element={<MesoSupportpage />} />
            <Route path="server" element={<MesoServerpage />} />
            <Route path="standorte" element={<MesoStandortpage />} />
            <Route path="impressum" element={<MesoImpressumpage />} />
          </Route>
          <Route path="green_hills" element={<GreenHills />}>
            <Route path="" element={<GreenHillsStartpage />} />
            <Route path="standort" element={<GreenHillsStandorte />} />
            <Route path="server" element={<GreenHillsServer />} />
          </Route>
          <Route path="fcorp" element={<FCorp />}></Route>
          <Route
            path="suspicious_enterprise"
            element={<SuspiciousEnterprise />}
          />
        </Route>
        <Route
          path="/desktop"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Desktop />} />}
            />
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Chat />} />}
            />
          }
        >
          {/*Nested Routes. SubPages are called*/}
          <Route path="" element={<ChatStartpage />} />
          <Route path=":id" element={<Messages />} />
        </Route>
        <Route
          path="/email"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Email />} />}
            />
          }
        >
          <Route path="" element={<ListEmails />} />
          <Route path=":id" element={<EmailDetail />} />
          <Route path="spam" element={<SpamEmails />} />
        </Route>

        <Route
          path="/monitoring"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Monitoring />} />}
            />
          }
        >
          <Route index element={<OverviewPage />} />
          <Route path="ressourcen" element={<ServerRessourcenPage />} />
          <Route path="statistics" element={<StatisticsPage />} />
          <Route path="logs" element={<LogsPage />} />
        </Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<TaskbarOutlet outlet={<Dashboard />} />}
            />
          }
        />
      </Routes>
      <Menu />
      <IncidentsModal />
      <GameOverModal />
      <VictoryScreen />
      <Achievements />
      <Credits />
      <PhaseOneEnd />
      <Tutorial />
    </div>
  );
}

export default App;
