import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles/MBIHeader.module.css";

function MBIHeader() {
  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <Link to="" className={styles.link}>
          <div className={styles.left}>MBI</div>
        </Link>
        <div className={styles.right}>
          <Link to="server">
            <div className={styles.item}>Server</div>
          </Link>
          <Link to="standorte">
            <div className={styles.item}>Standorte</div>
          </Link>
          <Link to="support">
            <div className={styles.item}>Support</div>
          </Link>
          <Link to="impressum">
            <div className={styles.item}>Impressum</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MBIHeader;
