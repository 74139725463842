import React from "react";
import styles from "../styles/Anleitung.module.css";
import IntroBild from "../../../assets/icons/IntroBild.png";
import {
  Aufgabenboard,
  Browser,
  Email,
  Spielmenu,
} from "../../../assets/Icons";

/**
 * 
 * @returns instructions of the game
 */
function Anleitung() {
  return (
    <div className={styles.container}>
      <img
        src={IntroBild}
        width="50%"
        alt="Introbild"
        className={styles.introPicture}
      />

      <div className={styles.content}>
        Das neueste Projekt ihres Unternehmens soll ein Bilder-Upload Service
        werden. Zuerst sollten Sie eine Möglichkeit finden, die Bilder der
        Kunden auf einer Plattform zu speichern. Genauere Informationen sind in
        der <em>E-Mail</em> des <em>Chefs</em> zu finden.
      </div>

      <div className={styles.heading}>
        <u>Aufbau des Spiels</u>
      </div>
      <div className={styles.content}>
        <strong>
          Das Spiel wird, wie im echten Berufsleben, durch verschiedene
          Programme (Browser, Chat, E-Mail) bedient.
        </strong>
      </div>

      <div className={styles.heading}>Die Taskleiste:</div>

      <div className={styles.content}>
        Über die Taskleiste sind alle Programme des Spiels abrufbar.
        <br />
        Im linken Bereich der Taskleiste befinden sich die vorhandenen
        Programme:
      </div>

      <ul>
        <li>Browser</li>
        <li>Chat</li>
        <li>E-Mail</li>
      </ul>

      <div className={styles.content}>Auf der rechten Seite befinden sich:</div>

      <ul>
        <li>Desktop</li>
        <li>Aufgaben-Board</li>
        <li>Datum</li>
        <li>Spielmenü</li>
      </ul>

      <div className={styles.content}>
        In der erweiterten Taskbar befinden sich auf dem rechten Bereich:
      </div>

      <ul>
        <li>Budget</li>
        <li>
          Checkliste:
          <br />
          Diese zeigt an, wie weit Sie in dem Spiel vorangeschritten sind.
        </li>
      </ul>

      <div className={styles.heading}>Browser:</div>
      <img src={Browser} width="700px" alt="Browser" />

      <div className={styles.content}>
        Das erste Programm ganz links in der Taskbar ist der Browser. Im Laufe
        des Spiels kommen im Browser bestimmte Webseiten hinzu. Das Spiel weist
        dann darauf hin.
        <br />
        Das Wiki umfasst alle Themen, die im Spiel angesprochen werden. Hier
        können auch Antworten freigeschaltet werden, so wie dies auch im{" "}
        <em>Chat</em> oder in den <em>E-Mails</em> möglich ist.
      </div>

      <div className={styles.heading}>Chat:</div>

      <div className={styles.content}>
        Der Chat dient zur Interaktion mit den virtuellen Kollegen
        (Nicht-Spieler-Charakteren). Bestimmte Antworten oder Fragen können
        ausgewählt werden, um Lösungen freizuschalten und Wissenswertes zu den
        aktuellen Themen zu erfahren.
        <br />
        Sobald ein Chat ausgewählt wurde, erkennt man anhand der Punkte unter
        der Antwort, zwischen wie vielen Antwortmöglichkeiten gewählt werden
        kann.
      </div>

      <div className={styles.heading}>E-Mail:</div>
      <img src={Email} width="700px" alt="Email" />
      <div className={styles.content}>
        In diesem Programm werden alle E-Mails angezeigt. <br />
        Über das Datum der jeweiligen E-Mail, kann erkannt werden ob diese
        aktuell ist oder nicht. <br />
        Wenn eine neue E-Mail in den Posteingang eingeht, bekommt das
        E-Mail-Icon einen pinken Punkt mit der Zahl der ungelesenen E-Mails.
      </div>

      <div className={styles.heading}>Aufgaben-Board:</div>
      <img src={Aufgabenboard} width="700px" alt="Aufgabenboard" />
      <div className={styles.content}>
        Das Board wird über das Aufgabensymbol aufgerufen.
        <br />
        Hier werden die aktuellen Aufgaben gezeigt. Sobald eine Lösung
        aufgedeckt wird, wird diese unterhalb der Aufgabe angezeigt.
        <br />
        Die drei Punkte unter einer Lösung oder der Aufgabe zeigen an, dass es
        noch weitere Lösungen gibt, die freigeschaltet werden können.
        <br />
        Lösungen schaltet man über die <em>E-Mails</em>, den <em>Chat</em> und
        den <em>Browser</em> frei.
      </div>
      <div className={styles.content}>
        Ein Tooltip taucht auf, wenn mit dem Mauszeiger kurze Zeit über der
        Aufgabeverweilt wird. Dieser zeigt genauere Informationen an.
        <br />
        Der Tooltip funktioniert auch bei den Lösungen und der Taskleiste.
      </div>

      <div className={styles.heading}>Spiel-Menü:</div>
      <img src={Spielmenu} width="700px" alt="Spielmenu" />
      <div className={styles.content}>
        In diesem Menü kann der Sound an- und ausgeschaltet werden.
        <br />
        Zudem kann über den <em>Entscheidungen</em>-Button die
        Entscheidungsübersicht angezeigt werden. Alle Entscheidugen, die
        getroffen wurden, werden hier angezeigt.
        <br />
        Wenn der Button einen ausgegrauten Hintergrund besitzt, ist diese
        Funktion noch nicht eingebunden und demnach noch nicht verfügbar.
        <br />
        Die Funktion <em>Erkenntnisse</em> wird in Phase 2 freigeschaltet.
      </div>

      <div className={styles.heading}>Erweiterte Taskbar:</div>

      <div className={styles.content}>
        Das aktuelle Budget wird links angezeigt.
        <br />
        Neben dem Budget ist die Checkliste. Diese zeigt den aktuellen
        Fortschritt im Spiel an. Wird eine Aufgabe absolviert, färbt sich der
        Haken zur Hälfte oder ganz pink.
        <br />
        Mit Klick auf das Check-Icon oder die Checks öffnet sich die{" "}
        <em>Entscheidungsübersicht</em>. Hier werden alle Entscheidungen
        angezeigt, die getroffen wurden.
      </div>

      <div className={styles.heading}>Team-Steckbriefe:</div>

      <div className={styles.content}>
        <strong>Andi Valkanis</strong>
        <br />
        Andi ist ein Kollege von Stephanie. Wenn sie ihn fragt, ist er immer zur
        Stelle.
        <br />
        (Wird im Laufe des Spiels freigeschaltet)
        <br />
        Backend-Erfahrung
      </div>

      <div className={styles.content}>
        <strong>Martin Färber</strong>
        <br />
        Martin arbeitet in einer anderen Abteilung, kennt Sie aber persönlich.
        Er ist ein Workaholic. Deswegen arbeitet er immer.
        <br />
        Sein Gebiet ist die Server-Wartung (Hardware)
      </div>

      <div className={styles.content}>
        <strong>Stephanie Eberhardt</strong>
        <br />
        Stephanie ist eine Kollegin von Ihnen.
        <br />
        Sie arbeitet an einem anderen Projekt, ist aber immer zur Stelle, wenn
        Sie Hilfe benötigen.
        <br />
        Backend-Erfahrung
      </div>

      <div className={styles.content}>
        <strong>Niklas Kortig</strong>
        <br />
        Niklas ist der Social-Media-Experte und promotet jedes Projekt auf
        unterschiedlichen Plattformen. Er ist deswegen immer beschäftigt.
      </div>

      <div className={styles.content}>
        <strong>Carl Fredrickse</strong>
        <br />
        Herr Fredrickse ist Ihr Chef.
      </div>
    </div>
  );
}

export default Anleitung;
