import React from "react";
import styles from "../styles/ListEmails.module.css";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/state";

/**
 * 
 * @returns list of spam mails (not implemented in backend)
 */
function SpamEmails() {
  let context = React.useContext(UserContext)

  return (
    <div className={styles.container}>
      {context.email.filter(mail => mail.IsSpam === true).map((index, id) => (
        <Link
          key={id}
          to={`${index._id}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className={styles.email}>
            <div className={styles.left}>
              <span className={styles.sender}>Von: {index.Sender}</span>
              <p className={styles.date}>22.05.2022{index.Date}</p>
            </div>
            <strong className={styles.subject}>{index.Header}</strong>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default SpamEmails;
