import React from "react";
import styles from "./styles/Tutorial.module.css";
import { Close_Black } from "../assets/Icons";
import { UserContext } from "../context/state";

function Tutorial(props: any) {
  let context = React.useContext(UserContext);

  return (
    <div className={styles.container}>
      {context.tutorialOpened ? (
        <div className={styles.modal}>
          <div className={styles.modalcontent}>
          <div className={styles.close}>
              <img
                src={Close_Black}
                alt="Closebutton"
                width="40px"
                onClick={() => context.openTutorial()}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.modalheader}><h1>Tutorial</h1></div>
              <div>
                <video width="90%" controls>
                  <source src="../../Tutorial.mp4" type="video/mp4" />
                  <source src="../../Tutorial_ios.mp4" type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Tutorial;
