import React from "react";
import styles from './styles/Suspicious.module.css'

function SuspiciousEnterprise() {
  return (
    <div className={styles.content}>
      <h1>Coming soon...</h1>
    </div>
  );
}

export default SuspiciousEnterprise;
