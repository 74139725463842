import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function SoftwareAsAService() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Software as a Service (SaaS)</p>
        <p>
          Der Cloud-Anbieter übernimmt die Einrichtung, Wartung und
          Infrastruktur des Servers. Die Konfiguration der Anwendung muss
          eigenständig umgesetzt werden.
        </p>
        <p>
          Der Verwaltungsfreiraum ist durch die Abgabe an Themen/Verantwortung
          begrenzt.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/paas" className={styles.link}>
            <li>Platform as a Service (PaaS)</li>
          </Link>
          <Link to="/browser/wiki/iaas" className={styles.link}>
            <li>Infrastructure as a Service (IaaS)</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default SoftwareAsAService;
