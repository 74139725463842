import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function ThirdPartyAuthentifizierung() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Third-Party-Authentifizierung</p>
        <p>
          Die Zwei-Faktor-Authentifzierung (auch: Federated-Authentifizierung
          oder föderierte Identität) bezeichnet einen Austausch von Daten
          zwischen Systemen. In diesem Fall sind es die Login-Daten der Nutzer.
          Der Nutzer meldet sich auf dem System seiner Wahl über ein anderes
          System an.
        </p>
        <p>
          Zum Beispiel: Firma A bezieht die Login-Daten von Firma B. Nutzer von
          Firma B können sich nun mit diesem Konto auch bei Firma A anmelden.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link
            to="/browser/wiki/zweifaktorauthentifizierung"
            className={styles.link}
          >
            <li>Zwei-Faktor-Authentifizierung</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default ThirdPartyAuthentifizierung;
