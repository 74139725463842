import React from "react";
import styles from "../styles/Overview.module.css";
import { ReactComponent as CloudIcon } from "../../../assets/icons/Monitoring/cloud.svg";
import { ReactComponent as DatabaseIcon } from "../../../assets/icons/Monitoring/database.svg";
import { ReactComponent as DollarIcon } from "../../../assets/icons/Monitoring/dollar.svg";
import { ReactComponent as ServerIcon } from "../../../assets/icons/Monitoring/server.svg";
import { ReactComponent as TachometerIcon } from "../../../assets/icons/Monitoring/tachometer.svg";
import { ReactComponent as UserGroupIcon } from "../../../assets/icons/Monitoring/usergroup.svg";
import { ReactComponent as RatingIcon } from "../../../assets/icons/Monitoring/rating.svg";
import { OverviewItem } from "../components/OverviewItem";
import { UserContext } from "../../../context/state";

const OverviewPage: React.FC = () => {
  const { gameState } = React.useContext(UserContext);

  return (
    <div className={styles.overviewWrapper}>
      <div className={styles.overviewHeaderContainer}>
        <h3 className={styles.overviewHeader}>Übersicht</h3>
      </div>
      <div className={styles.overviewItemContainer}>
        <OverviewItem
          text="Anbieter"
          icon={<CloudIcon />}
          textAndBorderColor="#4e73df"
          value={gameState.phaseTwoState.currentServer.provider}
        />
        <OverviewItem
          text="Server-Typ"
          icon={<ServerIcon />}
          textAndBorderColor="#1cc88a"
          value={gameState.phaseTwoState.currentServer.hosting}
        />
        <OverviewItem
          text="Server-Auslastung"
          icon={<TachometerIcon />}
          textAndBorderColor="#1cc88a"
          progressValue={
            (gameState.phaseTwoState.currentServer.ramUsed /
              gameState.phaseTwoState.currentServer.maxRam) *
            100
          }
        />
        <OverviewItem
          text="Speicherbelegung"
          icon={<DatabaseIcon />}
          textAndBorderColor="#4e73df"
          progressValue={
            (gameState.phaseTwoState.currentServer.storageUsed /
              gameState.phaseTwoState.currentServer.maxStorage) *
            100
          }
        />
        <OverviewItem
          text="Registrierte Benutzer"
          icon={<UserGroupIcon />}
          textAndBorderColor="#4e73df"
          value={gameState.phaseTwoState.currentUsers.toString()}
        />

        <OverviewItem
          text="Tägliches Einkommen"
          icon={<DollarIcon />}
          textAndBorderColor="#1cc88a"
          value={gameState.phaseTwoState.incomePerCycle.toString()}
        />
        <OverviewItem
          text="Bewertung"
          icon={<RatingIcon />}
          textAndBorderColor="#4e73df"
          value={(gameState.phaseTwoState.ratings.reduce((a, b) => a + b, 0) / gameState.phaseTwoState.ratings.length).toString()}
        />
      </div>
    </div>
  );
};

export default OverviewPage;
