import React from "react";
import { MesoServer } from './../../../../assets/Icons'

function MesoServerpage() {
  return (
    <div>
      <>
        <img src={MesoServer} width="100%"/>
      </>
    </div>
  );
}

export default MesoServerpage;
