import React from "react";
import {
  Angepasst,
  Check,
  Cloud,
  Nutzerfreundlich,
  Schnell,
  Sicher,
} from "../../../../assets/Icons";
import styles from "./styles/GreenHills.module.css";

function GreenHillsServer() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <strong>
          <h2>Server</h2>
        </strong>
        In wenigen Schritten zum eigenen Server.
        <p>Unsere Server sind:</p>
      </div>
      <div className={styles.content}>
        <img src={Nutzerfreundlich} width="50px" className={styles.check} />
        <p>
          <strong>nutzerfreundlich</strong>
        </p>
        <img src={Schnell} width="50px" className={styles.check} />
        <p>
          <strong>schnell</strong>
        </p>
        <img src={Angepasst} width="50px" className={styles.check} />
        <p>
          <strong>an Sie angepasst</strong>
        </p>
        <img src={Sicher} width="50px" className={styles.check} />
        <p>
          <strong>sicher</strong>
        </p>
        <img src={Cloud} width="50px" className={styles.check} />
        <p>
          <strong>alles an einem Fleck</strong>
        </p>
      </div>

      <div className={styles.content}>
        <strong>
          <h3>Server-Übersicht</h3>
        </strong>

        <img src={Check} width="40px" className={styles.check} />
        <p>Intel Xeon E3-1270 v6</p>
        <img src={Check} width="40px" className={styles.check} />
        <p>4 Kerne x 3,8GHz (4,2GHZ Turbo Boost)</p>
        <img src={Check} width="40px" className={styles.check} />
        <p>64 GB DDR4 RAM"</p>
        <img src={Check} width="40px" className={styles.check} />
        <p>Unlimited Traffic</p>
        <img src={Check} width="40px" className={styles.check} />
        <p>Kostenlose Domain</p>
      </div>
    </div>
  );
}

export default GreenHillsServer;
