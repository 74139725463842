import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TimeContext, UserContext } from "../context/state";

import styles from "./styles/IncidentsModal.module.css";
import { Incident, IncidentResponse, PendingResponse } from "../api/api";
import { prepareIncidentResponseToQueue } from "../util/EventUtils";

export default function Modals(): JSX.Element {
  const {
    gameState,
    gameStateRef,
    openIncidentsModal,
    setLocalGameState,
    incidentsModalOpened,
    selectedIncident,
    setSelectedIncident,
  } = React.useContext(UserContext);
  const { timeRef } = React.useContext(TimeContext);
  //all incidents
  const ref = useRef(null);
  const mixer = useRef<THREE.AnimationMixer | null>();

  let clock = new THREE.Clock();

  /**
   * Helper method to prepare the selected response and add it to the pending responses and execute it on time
   * @param selectedResponse - the selected {@link IncidentResponse}
   */
  const helperForResponseSelection = (
    selectedResponse: IncidentResponse,
    passedIncident: Incident
  ) => {
    const preparedResponse: PendingResponse = prepareIncidentResponseToQueue(
      selectedResponse,
      timeRef.current!,
      gameStateRef.current!.currentDate
    );
    setLocalGameState({
      ...gameStateRef.current!,
      phaseTwoState: {
        ...gameStateRef.current!.phaseTwoState,
        imminentIncidents:
          gameStateRef.current!.phaseTwoState.imminentIncidents.filter(
            (incident) => incident.gameId !== passedIncident.gameId
          ),
        pendingResponses: [
          ...gameStateRef.current!.phaseTwoState.pendingResponses,
          preparedResponse,
        ],
      },
    });
    openIncidentsModal(false);
    setSelectedIncident(undefined);
  };

  /**
   * Helper to determine whether the selected response is affordable
   * @param selectedResponse  - the selected {@link IncidentResponse}
   * @returns  - true if the selected response is affordable, false otherwise
   */
  const checkIfBudgetIsEnough = (
    selectedResponse: IncidentResponse
  ): boolean => {
    return selectedResponse.costMoney <= gameState.budget;
  };

  useEffect(() => {
    if (!selectedIncident) return;
    const current = ref.current;

    if (current !== null && current !== undefined) {
      //Create scene
      const scene = new THREE.Scene();
      //Change background color to white (syringe has a better material with white background)
      scene.background = new THREE.Color(0xffffff);

      //renderer
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: current,
      });
      renderer.outputEncoding = THREE.sRGBEncoding;
      //set size of canvas
      renderer.setSize(270, 170);

      //camera
      let fov = 38;
      let aspectWidth = 270;
      let aspectHeight = 170;
      let near = 0.1;
      let far = 1000;
      const camera = new THREE.PerspectiveCamera(
        fov,
        aspectWidth / aspectHeight,
        near,
        far
      );
      camera.position.set(0, 0.8, 2);

      //Movement
      const controls = new OrbitControls(camera, renderer.domElement);
      //Disable/Enable Zoom
      controls.enableZoom = false;
      //Disable/Enable Rotation
      controls.enableRotate = true;
      //Disable/Enable Pan
      controls.enablePan = false;
      controls.update();

      //lights
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(0, 5, 1);
      const ambientlight = new THREE.AmbientLight(0xffffff, 1.6);
      scene.add(directionalLight);
      scene.add(ambientlight);

      const currentSelectedEvent: Incident = selectedIncident;
      if (currentSelectedEvent !== undefined) {
        //Load 3D-Object
        let loader = new GLTFLoader();
        if (!!currentSelectedEvent.object) {
          loader.load(currentSelectedEvent.object, function (gltf) {
            scene.add(gltf.scene);
            //Create an AnimationMixer, and get the list of AnimationClip instances
            mixer.current = new THREE.AnimationMixer(gltf.scene);
            let action = mixer.current.clipAction(gltf.animations[0]);
            action.setDuration(6);
            action.play();

            //Change position of object
            gltf.scene.position.set(0, -0.3, 0);

            window.addEventListener("resize", onWindowResize);
          });
        }
      }

      const onWindowResize = () => {
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(270, 170);
      };

      //update animation with loop
      const animation = () => {
        requestAnimationFrame(animation);
        const currentmixer = mixer.current;
        let mixerUpdateDelta = clock.getDelta();
        if (currentmixer != null) currentmixer.update(mixerUpdateDelta);

        renderer.render(scene, camera);
      };

      animation();
    } else {
      console.error("Cannot load Object");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident, incidentsModalOpened]);

  return (
    <div className={styles.container}>
      {incidentsModalOpened ? (
        <div className={styles.modal}>
          <div className={styles.modalcontent}>
            <div className={styles.content}>
              <div className={styles.animation}>
                <canvas ref={ref} id="canvas">
                  Animationen, die den Vorfall grafisch unterstreichen
                </canvas>
                <div
                  className={styles.closeButton}
                  onClick={() => openIncidentsModal(false)}
                >
                  Zurückstellen
                </div>
              </div>
              <div className={styles.modalheader}>
                <h3>{selectedIncident?.title}</h3>
              </div>
              <div className={styles.center}>
                <div className={styles.line} />
              </div>
              {/*Show Description of incident*/}
              <div className={styles.description}>
                {selectedIncident?.description}
              </div>
              {/*Show options*/}
              <div className={styles.questionContainer}>
                <div className={styles.question}>Was tun Sie?</div>
              </div>

              <div className={styles.modalfooter}>
                <div className={styles.buttonContainer}>
                  {selectedIncident?.responses.map((index, i) => (
                    <>
                      <div
                        onClick={
                          checkIfBudgetIsEnough(index)
                            ? () =>
                                helperForResponseSelection(
                                  index,
                                  selectedIncident
                                )
                            : () => {}
                        }
                        className={
                          checkIfBudgetIsEnough(index)
                            ? styles.button
                            : styles.buttonDisabled
                        }
                        key={i}
                      >
                        <div className={styles.option}>
                          <p className={styles.optionText}>
                            {" "}
                            {index.title}
                            {index.costMoney > 0
                              ? "(-" + index.costMoney + "€)"
                              : ""}
                          </p>
                          <span className={styles.tooltiptext}>
                            {checkIfBudgetIsEnough(index)
                              ? index.description
                              : "Nicht genug Geld"}
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
