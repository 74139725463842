import { useRef, useState, useEffect } from "react";
import { useWindowSize } from "react-use";
import styles from "../styles/OverviewItem.module.css";

interface OverviewItemProps {
  text: string;
  icon: JSX.Element;
  textAndBorderColor: string;
  progressValue?: number;
  value?: string;
}

export const OverviewItem: React.FC<OverviewItemProps> = ({
  text,
  icon,
  textAndBorderColor,
  progressValue,
  value,
}) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const [calculatedWidth, setCalculatedWidth] = useState<number>(0);

  /**
   * useEffect to handle progress bar width
   */
  useEffect(() => {
    calcProgressWidth();
  }, [progressBarRef.current, progressValue, width]);

  /**
   * Helper method to calculate width for progress bar
   */
  const calcProgressWidth = (): void => {
    if (!progressBarRef.current) return setCalculatedWidth(0);
    setCalculatedWidth(
      (progressValue! / 100) *
        progressBarRef.current.getBoundingClientRect().width
    );
  };

  return (
    <div
      className={styles.itemWrapper}
      style={{ borderColor: textAndBorderColor }}
    >
      <div className={styles.itemTextContainer}>
        <p className={styles.itemText} style={{ color: textAndBorderColor }}>
          {text}
        </p>

        {progressValue! >= 0 && (
          <div className={styles.progressBarPercentContainer}>
            <p>%</p>
            <div className={styles.progressBarContainer}>
              <div ref={progressBarRef} className={styles.progressBar}></div>
              <div
                className={styles.progressBarTrack}
                style={{
                  width: `${calculatedWidth}px`,
                  backgroundColor: textAndBorderColor,
                }}
              ></div>
            </div>
          </div>
        )}
        {value && (
          <div className={styles.valueContainer}>
            <p>{value}</p>
          </div>
        )}
      </div>
      <div className={styles.itemIconContainer}>{icon}</div>
    </div>
  );
};
