import React from "react";
import { Outlet } from "react-router-dom";
import { MBIHeader } from "./index";

function MBI() {
  return (
    <div>
      <MBIHeader />
      <>
        <Outlet />
      </>
    </div>
  );
}

export default MBI;
