import { UserContext } from "./state";
import { useState } from "react";
import {
  createEmptyGameState,
  GameState,
  getGameState,
  IActiveChat,
  IEmail,
  ImminentIncident,
} from "../api/api";
import useStateRef from "react-usestateref";
import {
  combineCurrentPendingEventsWithNew,
  combineCurrentWithNewImminentIncidents,
} from "../util/EventUtils";

interface UserContextProviderProps {
  children: React.ReactNode;
}

//The context is sent to all child components with the help of the provider.
export const UserContextProvider = (props: UserContextProviderProps) => {
  //is the dashboard open or closed?
  const [dashboardOpened, setDashboardOpened] = useState(false);
  //is the Modal for all incidents open or closed?
  const [incidentsModalOpened, setIncidentsModalOpened] = useState(false);
  //is the Menu open or closed?
  const [menuOpened, setMenuOpened] = useState(false);
  //is the tutorial open or closed?
  const [tutorialOpened, setTutorialOpened] = useState(false);
  //is the Achievements Dialog open or closed?
  const [achievementsOpened, setAchievementsOpened] = useState(false);
  //is the credits dialog open or closed?
  const [creditsOpened, setCreditsOpened] = useState(false);
  //is the Detailview of the decision open or closed?
  const [decisionDetailOpened, setDecisionDetailOpened] = useState(false);
  //list of actual emails
  const [email, setEmail] = useState<IEmail[]>([]);
  //list of actual chats
  const [chat, setChat] = useState<IActiveChat[]>([]);
  //is User registered?
  const [isAuth, setIsAuth] = useState(false);
  //the actual gamestate
  const [gameState, setGameState, gameStateRef] = useStateRef<GameState>(
    createEmptyGameState()
  );
  const [gameOver, setGameOver] = useState<boolean>(false);

  const [victory, setVictory] = useState<boolean>(false);

  const [selectedIncident, setSelectedIncident] = useState<
    ImminentIncident | undefined
  >();

  /**
   * Helper function to update the client game state
   */
  const fetchGameState = async (): Promise<void> => {
    let updatedGameState: GameState | undefined = await getGameState();
    if (!updatedGameState) return;
    const combinedIncidents = combineCurrentWithNewImminentIncidents(
      gameStateRef.current!.phaseTwoState.imminentIncidents,
      updatedGameState.phaseTwoState.imminentIncidents
    );
    const combinedPendingEvents = combineCurrentPendingEventsWithNew(
      gameStateRef.current!.phaseTwoState.pendingEventCards,
      updatedGameState.phaseTwoState.pendingEventCards
    );
    setAndSaveGameState(
      updatedGameState.phaseTwoState
        ? {
            ...updatedGameState,
            phaseTwoState: {
              ...updatedGameState.phaseTwoState,
              imminentIncidents: combinedIncidents,
              pendingEventCards: combinedPendingEvents,
              pendingResponses: [
                ...gameStateRef.current.phaseTwoState.pendingResponses,
              ],
              pendingUpgrades: [
                ...gameStateRef.current.phaseTwoState.pendingUpgrades,
              ],
              statusToDisplay: [
                ...gameStateRef.current!.phaseTwoState.statusToDisplay,
                ...updatedGameState.phaseTwoState.statusToDisplay,
              ],
              currentServer: {
                ...updatedGameState.phaseTwoState.currentServer,
                ramLoadHistory:
                  gameStateRef.current!.phaseTwoState.currentServer
                    .ramLoadHistory.length > 0
                    ? [
                        ...gameStateRef.current.phaseTwoState.currentServer
                          .ramLoadHistory,
                      ]
                    : [
                        ...updatedGameState.phaseTwoState.currentServer
                          .ramLoadHistory,
                      ],
              },
            },
          }
        : {
            ...updatedGameState,
          }
    );
  };

  /**
   * Updates the current gameState and saves it to the localStorage
   * @param gameState - the passed in gamestate
   */
  const setAndSaveGameState = (gameState: GameState) => {
    setGameState(gameState);
    localStorage.setItem("gameState", JSON.stringify(gameState));
  };

  /**
   * Updates and sets incident
   * @param incident - selected incident
   */
  const setAndSaveSelectedIncident = (
    incident: ImminentIncident | undefined
  ) => {
    setSelectedIncident(incident);
    localStorage.setItem("selectedIncident", JSON.stringify(incident));
  };

  return (
    <UserContext.Provider
      value={{
        dashboardOpened: dashboardOpened,
        openDashboard: () => {
          setDashboardOpened(!dashboardOpened);
        },
        incidentsModalOpened: incidentsModalOpened,
        openIncidentsModal: (opened: boolean) => {
          setIncidentsModalOpened(opened);
        },
        menuOpened: menuOpened,
        openMenu: () => {
          setMenuOpened(!menuOpened);
        },
        tutorialOpened: tutorialOpened,
        openTutorial: () => {
          setTutorialOpened(!tutorialOpened);
        },
        achievementsOpened: achievementsOpened,
        openAchievement: () => {
          setAchievementsOpened(!achievementsOpened);
        },
        creditsOpened: creditsOpened,
        openCredits: () => {
          setCreditsOpened(!creditsOpened);
        },
        decisionDetailOpened: decisionDetailOpened,
        openDecisionDetail: () => {
          setDecisionDetailOpened(!decisionDetailOpened);
        },
        email: email,
        setEmail: (email: IEmail[]) => {
          setEmail(email);
        },
        chat: chat,
        setChat: (chat: IActiveChat[]) => {
          setChat(chat);
        },
        isAuth: isAuth,
        setIsAuth: (isAuth: boolean) => {
          setIsAuth(isAuth);
        },
        gameStateRef: gameStateRef,
        gameState: gameState,
        setLocalGameState: (gameState: GameState) =>
          setAndSaveGameState(gameState),
        fetchGameState: fetchGameState,
        gameOver: gameOver,
        setGameOver: (gameOver: boolean) => setGameOver(gameOver),
        victory: victory,
        setVictory: (victory: boolean) => setVictory(victory),
        selectedIncident: selectedIncident,
        setSelectedIncident: (selectedIncident: ImminentIncident | undefined) =>
          setAndSaveSelectedIncident(selectedIncident),
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
