import React from "react";
import { Outlet } from "react-router-dom";
import { GreenHillsKarte } from "../../../../assets/Icons";
import styles from "./styles/GreenHills.module.css";

function GreenHillsStandorte() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <strong><h2>Standort</h2></strong>
        <p>Unsere Server stehen im Herzen von Irland, in Dublin. Dublin ist bekannt
        für seinen perfekten Standort für Rechenzentren. Durch die milden bis
        kalten Temperaturen muss der Server-Raum nicht lange gekühlt werden.
        Unsere Nachbarn sind Google, Facebook, Microsoft und viele weitere.</p>

        <img src={GreenHillsKarte}/>

        <p>Unser Strom kommt von einem Irischen Anbieter ums Eck. Aus diesem Grund
        müssen unsere Stromkabel keine weiten Wege zurücklegen. Wir haben uns
        für einen örtlichen Anbieter entschieden, damit die Umwelt nicht zu sehr
        leidet. Denn wir sind gegen kilometerlanges Aufreißen der Erde und
        Zerstören der Umwelt. Aus diesem Grund ist unser Strom grün. Er wird
        durch Windenergie, Biomasse und Sonnenenergie gewonnen.</p>
        <div>
          Sie tragen dazu bei, eine bessere Welt zu erschaffen, wenn Sie einen
          Server von uns mieten.
        </div>
      </div>
    </div>
  );
}

export default GreenHillsStandorte;
