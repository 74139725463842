import React from "react";
import styles from "../styles/ListEmails.module.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { GameState, getGameState } from "../../../api/api";
import { UserContext } from "../../../context/state";

/**
 *
 * @returns list of all emails
 */
function ListEmails() {
  let context = React.useContext(UserContext);

  //call api in external api file to get emails depending on game state
  useEffect(() => {
    if (context.isAuth) {
      (async () => {
        let gameState: GameState | undefined = await getGameState();
        if (!gameState) return;
        context.setEmail(gameState.emails);
      })();
    }
  }, []);

  return (
    <div className={styles.container}>
      {context.email.map((index, id) => (
        <Link
          key={id}
          to={`${index._id}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className={styles.email}>
            <div className={styles.left}>
              <span className={styles.sender}>Von: {index.Sender}</span>
              <p className={styles.date}>22.05.2022{index.Date}</p>
            </div>
            <strong className={styles.subject}>{index.Header}</strong>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default ListEmails;
