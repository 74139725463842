import React from "react";
import styles from "../../styles/Wiki.module.css";
import { Link } from "react-router-dom";

function HybridCloud() {
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        <p className={styles.heading}>Hybrid Cloud</p>
        <p>
          Der Cloud-Anbieter stellt ein Netzwerk zur Verfügung, das zum einen
          Teil eine Public Cloud und zum anderen Teil eine Private Cloud
          beinhaltet.
          </p><p>
          Das bedeutet: Das eine Netzwerk ist über das Internet erreichbar,
          während das andere nicht über das Internet erreichbar ist. Somit
          können je nach Sensitivität Daten ausgelagert werden.
        </p>
        <Link to="/browser/wiki" className={styles.link}>
          <p className={styles.subheader}>Zurück zum Wiki</p>
        </Link>
        Ähnliche Themen zum Weiterlesen:
        <ul>
          <Link to="/browser/wiki/publiccloud" className={styles.link}>
            <li>Public Cloud</li>
          </Link>
          <Link to="/browser/wiki/privatecloud" className={styles.link}>
            <li>Private Cloud</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default HybridCloud;
